import PropTypes from '@root/vendor/prop-types';
import RadioInputGroup from '@root/core/src/components/radio-input-group';
import RadioOption from '@root/core/src/components/radio-option';
import React from '@root/vendor/react';
import SnolStyles from '@root/claims.joinroot.com/src/assets/snol-styles.js';
import { InjuryTypes } from '@root/claims.joinroot.com/src/models/claims';

export default function InjuryQuestion(props) {
  const {
    currentInjuryType,
    isError,
    onFieldEvent,
  } = props;

  return (
    <fieldset>
      <h4 css={SnolStyles.injuryQuestion}>Are you planning to receive medical treatment&mdash;or have you already received medical treatment&mdash;for any injury associated with the accident?</h4>
      <p css={SnolStyles.injurySubtext}>This includes being treated by EMS, by paramedics, at the hospital, or at an urgent care.</p>
      <RadioInputGroup>
        <RadioOption
          id={'injury_type-INJURED'}
          isError={isError}
          isSelected={currentInjuryType === InjuryTypes.INJURED}
          label={'Yes'}
          name={'injury_type'}
          onClick={onFieldEvent}
          optionValue={InjuryTypes.INJURED}
        />
        <RadioOption
          id={'injury_type-UNINJURED'}
          isError={isError}
          isSelected={currentInjuryType === InjuryTypes.UNINJURED}
          label={'No'}
          name={'injury_type'}
          onClick={onFieldEvent}
          optionValue={InjuryTypes.UNINJURED}
        />
      </RadioInputGroup>
    </fieldset>
  );
}

InjuryQuestion.propTypes = {
  currentInjuryType: PropTypes.string,
  isError: PropTypes.bool.isRequired,
  onFieldEvent: PropTypes.func.isRequired,
};
