import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';

export default class SubmitButton extends React.Component {
  static propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    submitButtonRef: PropTypes.any,
    submitting: PropTypes.bool,
    submittingText: PropTypes.string,
    text: PropTypes.string,
  };

  static defaultProps = {
    text: 'Submit',
  }

  get _buttonText() {
    if (this.props.submitting && this.props.submittingText !== undefined) {
      return this.props.submittingText;
    } else {
      return this.props.text;
    }
  }

  render() {
    return (
      <button
        disabled={this.props.disabled || this.props.submitting}
        onClick={this.props.onClick}
        ref={this.props.submitButtonRef}
        type={'submit'}
      >
        {this._buttonText}
      </button>
    );
  }
}
