import React from '@root/vendor/react';

export default function Email() {
  return (
    <svg
      fill={'none'}
      height={'48'}
      viewBox={'0 0 48 48'}
      width={'48'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <path
        d={'M45 42.77H3C2.44772 42.77 2 42.3223 2 41.77V16.77H4V40.77H44V16.77H46V41.77C46 42.3223 45.5523 42.77 45 42.77Z'}
        fill={'#FF5715'}
      />
      <path
        clipRule={'evenodd'}
        d={'M23.5201 29.7C23.6675 29.7796 23.8326 29.8208 24.0001 29.82C24.1676 29.8208 24.3327 29.7796 24.4801 29.7L45.4801 18.25C45.8016 18.0741 46.0011 17.7365 46.0001 17.37V7.82001C46.0001 7.26772 45.5524 6.82001 45.0001 6.82001H3.00008C2.4478 6.82001 2.00008 7.26772 2.00008 7.82001V17.36C1.99539 17.73 2.19542 18.0724 2.52008 18.25L23.5201 29.7ZM24.0001 27.68L4.00008 16.82V8.77001H44.0001V16.77L24.0001 27.68Z'}
        fill={'#FF5715'}
        fillRule={'evenodd'}
      />
    </svg>
  );
}
