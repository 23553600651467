import DatePicker from 'react-datepicker';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';

RootDatepicker.propTypes = {
  className: PropTypes.string,
};

RootDatepicker.defaultProps = {
  className: '',
};

export default function RootDatepicker(datepickerProps) {
  let className = datepickerProps.className;
  if (datepickerProps.error) {
    className = ['error', className].join(' ');
  }

  return (
    <div
      className={'datepicker-input'}
      data-testid={'RootDatepicker#' + datepickerProps.name}
    >
      <label id={`datepicker-${datepickerProps.name}`}>{datepickerProps.label}</label>
      <DatePicker
        {...datepickerProps}
        ariaLabelledBy={`datepicker-${datepickerProps.name}`}
        className={className}
      />
    </div>
  );
}
