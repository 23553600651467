import { FnolDispatchContext, actionTypes } from '@root/claims.joinroot.com/src/contexts/fnol-context-provider';
import { useContext, useEffect } from '@root/vendor/react';
import { useHistory } from '@root/vendor/react-router-dom';

export default function PhoneFnolReset() {
  const dispatch = useContext(FnolDispatchContext);
  const history = useHistory();
  useEffect(() => {
    dispatch({
      type: actionTypes.RESET_PHONE_USER_FNOL,
    });
    history.push('/portal/fnol/caller');
  });
  return null;
}
