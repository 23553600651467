import AnalyticsService from '@root/core/src/services/analytics-service';
import Check from '@root/claims.joinroot.com/src/svg/check.js';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import SnolStyles from '@root/claims.joinroot.com/src/assets/snol-styles';

export default class SuccessfulSubmission extends React.Component {
  static propTypes = {
    firstName: PropTypes.string,
  };

  get _title() {
    return this.props.firstName ? `Thanks, ${this.props.firstName}.` : 'Thanks.';
  }

  get _message() {
    return 'We\'ve got what we need for now. Your claims expert will be in touch as this claim progresses.';
  }

  componentDidMount() {
    AnalyticsService.trackViewEvent('CLAIMS_SNOL_THANKYOU');
  }

  render() {
    return (
      <>
        <Check />
        <h1 css={SnolStyles.primaryHeader}>{this._title}</h1>
        <p css={SnolStyles.subtext}>{this._message}</p>
      </>
    );
  }
}
