import FnolStyles from '@root/claims.joinroot.com/src/assets/fnol-styles.js';
import PropTypes from '@root/vendor/prop-types';
import RadioInputGroup from '@root/core/src/components/radio-input-group';
import RadioOption from '@root/core/src/components/radio-option';
import React from '@root/vendor/react';
import { InjuryTypes } from '@root/claims.joinroot.com/src/models/claims';

export default function FatalityQuestion(props) {
  const {
    currentInjuryType,
    currentName,
    inputKey,
    isError,
    onFieldEvent,
  } = props;

  const identifier = currentName?.trim() || 'this person';

  const notDeceasedOptionValue = () => {
    return currentInjuryType === InjuryTypes.DECEASED ? '' : currentInjuryType || '';
  };

  return (
    <fieldset>
      <h4 css={FnolStyles.prompt}>To the best of your knowledge, did {identifier} pass away as a result of this accident?</h4>
      <RadioInputGroup >
        <RadioOption
          id={`${inputKey}-fatality-DECEASED`}
          isError={isError}
          isSelected={currentInjuryType === InjuryTypes.DECEASED}
          label={'Yes'}
          name={`${inputKey}-fatality`}
          onClick={onFieldEvent}
          optionValue={InjuryTypes.DECEASED}
        />
        <RadioOption
          id={`${inputKey}-fatality-NOT-DECEASED`}
          isError={isError}
          isSelected={currentInjuryType === notDeceasedOptionValue()}
          label={'No'}
          name={`${inputKey}-fatality`}
          onClick={onFieldEvent}
          optionValue={notDeceasedOptionValue()}
        />
      </RadioInputGroup>
    </fieldset>
  );
}

FatalityQuestion.propTypes = {
  currentInjuryType: PropTypes.string,
  currentName: PropTypes.string,
  inputKey: PropTypes.string.isRequired,
  isError: PropTypes.bool.isRequired,
  onFieldEvent: PropTypes.func.isRequired,
};
