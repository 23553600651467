import ClaimFailed from '@root/claims.joinroot.com/src/components/fnol/claim-failed';
import ClaimReceived from '@root/claims.joinroot.com/src/components/fnol/claim-received';
import DescribeClaimForm from '@root/claims.joinroot.com/src/components/fnol/describe-claim-form';
import FileInApp from '@root/claims.joinroot.com/src/components/fnol/file-in-app';
import FindPolicyForm from '@root/claims.joinroot.com/src/components/fnol/find-policy-form';
import InvolvedPartiesForm from '@root/claims.joinroot.com/src/components/fnol/involved-parties-form';
import PolicyQuestion from '@root/claims.joinroot.com/src/components/fnol/policy-question';
import VehicleQuestion from '@root/claims.joinroot.com/src/components/fnol/vehicle-question';

const CLAIMANT_WORKFLOW = [
  {
    path: '',
    component: <PolicyQuestion />,
  },
  {
    path: '/vehicle',
    component: <VehicleQuestion />,
    dependencies: ['root-policy'],
    fallback: '',
  },
  {
    path: '/app',
    component: <FileInApp />,
    dependencies: ['root-policy'],
    fallback: '',
  },
  {
    path: '/step1',
    component: <FindPolicyForm />,
    dependencies: ['vehicle'],
    fallback: '/vehicle',
  },
  {
    path: '/step2',
    component: <DescribeClaimForm />,
    dependencies: ['found-policy'],
    fallback: '/step1',
  },
  {
    path: '/step3',
    component: <InvolvedPartiesForm />,
    dependencies: ['describe-claim'],
    fallback: '/step2',
  },
  {
    path: '/received',
    component: <ClaimReceived />,
    dependencies: ['claim-received'],
    fallback: '',
  },
  {
    path: '/failed',
    component: <ClaimFailed />,
    dependencies: ['claim-failed'],
    fallback: '',
  },
];

export { CLAIMANT_WORKFLOW };
