import React from '@root/vendor/react';

export default class CloudUpload extends React.Component {
  render() {
    return (
      <svg
        fill={'none'}
        height={'44'}
        viewBox={'0 0 68 44'}
        width={'68'}
        xmlns={'http://www.w3.org/2000/svg'}
      >
        <path
          d={'M19 14.2188V14.2188C19 6.91824 24.9182 1 32.2188 1H34C42.2843 1 49 7.71573 49 16V20.5'}
          stroke={'#BBBBBB'}
          strokeWidth={'2'}
        />
        <path
          d={'M49 13H52C60.2843 13 67 19.7157 67 28V28C67 36.2843 60.2843 43 52 43H16C7.71573 43 1 36.2843 1 28V27.531C1 19.5057 7.50573 13 15.531 13V13C19.1713 13 22.6406 14.5451 25.0758 17.2509L28 20.5'}
          stroke={'#BBBBBB'}
          strokeWidth={'2'}
        />
        <path
          d={'M34 36L34 24'}
          stroke={'#BBBBBB'}
          strokeWidth={'2'}
        />
        <path
          d={'M40.0344 30.0344L33.9999 23.9999L27.9655 30.0344'}
          stroke={'#BBBBBB'}
          strokeWidth={'2'}
        />
      </svg>
    );
  }
}
