import AnalyticsService from '@root/core/src/services/analytics-service';
import Button, { BUTTON_SIZE_SMALL } from '@root/core/src/components/button';
import ClaimsPortalApi from '@root/claims.joinroot.com/src/api/claims-portal-api';
import DocumentUpload from '@root/claims.joinroot.com/src/components/core/document-upload';
import InjuryCategoriesQuestion from '@root/claims.joinroot.com/src/components/snol/questions/injury-categories-question';
import InjuryQuestion from '@root/claims.joinroot.com/src/components/snol/questions/injury-question';
import Input, { TextTypes } from '@root/core/src/components/input';
import LoaderButton from '@root/core/src/components/loader-button';
import Modal from '@root/core/src/components/modal';
import PhoneNumberInput from '@root/core/src/components/phone-number-input';
import PreExistingConditionsExplanationQuestion from '@root/claims.joinroot.com/src/components/snol/questions/pre-existing-conditions-explanation-question';
import PropTypes from '@root/vendor/prop-types';
import RadioInputGroup from '@root/core/src/components/radio-input-group';
import RadioOption from '@root/core/src/components/radio-option';
import React from '@root/vendor/react';
import Regex from '@root/claims.joinroot.com/src/utils/regex';
import Select from '@root/core/src/components/select';
import SnolStyles from '@root/claims.joinroot.com/src/assets/snol-styles.js';
import VINHelp from '@root/claims.joinroot.com/src/svg/vin-help';
import ViewportService from '@root/claims.joinroot.com/src/services/viewport-service';
import Vin from '@root/core/src/models/vin';
import isArray from '@root/vendor/lodash/isArray';
import isEmpty from '@root/vendor/lodash/isEmpty';
import isNumber from '@root/vendor/lodash/isNumber';
import isString from '@root/vendor/lodash/isString';
import negate from '@root/vendor/lodash/negate';
import values from '@root/vendor/lodash/values';
import { ACCEPTED_IMAGE_FILES } from '@root/claims.joinroot.com/src/models/accepted-files';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { EMAIL_REGEX, NON_EMPTY_STRING_REGEX } from '@root/core/src/utils/validators';
import { FirstPartyInjuryCategories, InjuryTypes } from '@root/claims.joinroot.com/src/models/claims';
import { NO_PHONE_NUMBER_MASK, VALID_PHONE_NUMBER_REGEX } from '@root/core/src/models/phone';
import { StatePickerOptions } from '@root/claims.joinroot.com/src/models/picker-options';

function isBlank(value) {
  return isString(value) ? !NON_EMPTY_STRING_REGEX.test(value) : isEmpty(value) && !isNumber(value);
}

const validatePresence = negate(isBlank);

function arrayWrap(thing) {
  if (isArray(thing)) {
    return thing;
  } else if (isBlank(thing)) {
    return [];
  } else {
    return [thing];
  }
}

function allowBlank(fn) {
  return (value, ...args) => {
    return isBlank(value) || fn(value, ...args);
  };
}

function atLeastOneOptionIsSelected(options) {
  return options ? Object.values(options).some((value) => value) : true;
}

function injuryTypeIsInjured(state) {
  return state.injuryType === InjuryTypes.INJURED;
}

function preExistingConditionIsSelected(state) {
  return state.injuryCategories?.pre_existing_injury || false;
}

function validatePresenceWhenInVehicle(value, state) {
  if (state.selectedVehicle !== 'not-in-vehicle') {
    return validatePresence(value);
  }
  return true;
}

function validateVinPresence(value, state, props) {
  if (props.involvementType === 'claimant') {
    return validatePresenceWhenInVehicle(value, state);
  }
  return true;
}

function validateVinFormat(value) {
  return Vin.validate(value).isValid;
}

function validateInjuryCategories(value, state) {
  if (injuryTypeIsInjured(state)) {
    return atLeastOneOptionIsSelected(value);
  }
  return true;
}

function validatePreExistingConditionsExplanation(value, state) {
  if (preExistingConditionIsSelected(state)) {
    return validatePresence(value);
  }
  return true;
}

function validatePhoneNumberLength(value) {
  return value.length === 10;
}

export default class InvolvedPartyForm extends React.Component {
  // Where the validator value is an array, the first validation to return false will be the returned message text
  static Validators = {
    addressState: {
      test: validatePresence,
      message: 'Please choose state',
    },
    addressZip: {
      test: allowBlank(Regex.validate(Regex.ZIP_CODE)),
      message: 'Invalid zip',
    },
    firstName: {
      test: validatePresence,
      message: 'Please add',
    },
    lastName: {
      test: validatePresence,
      message: 'Please add',
    },
    email: [
      {
        test: validatePresence,
        message: 'Please add',
      },
      {
        test: Regex.validate(EMAIL_REGEX),
        message: 'Invalid email',
      },
    ],
    phoneNumber: [
      {
        test: validatePresence,
        message: 'Please add',
      },
      {
        test: validatePhoneNumberLength,
        message: 'Please use the format (###) ###-####',
      },
      {
        test: Regex.validate(VALID_PHONE_NUMBER_REGEX),
        message: 'Invalid number',
      },
    ],
    make: {
      test: validatePresenceWhenInVehicle,
      message: 'Please add',
    },
    model: {
      test: validatePresenceWhenInVehicle,
      message: 'Please add',
    },
    vin: [
      {
        test: validateVinPresence,
        message: 'Please add',
      },
      {
        test: allowBlank(validateVinFormat),
        message: 'Invalid VIN',
      },
    ],
    year: [
      {
        test: validatePresenceWhenInVehicle,
        message: 'Please add',
      },
      {
        test: allowBlank(Regex.validate(Regex.VEHICLE_YEAR)),
        message: 'Invalid year',
      },
    ],
    injuryType: {
      test: validatePresence,
      message: 'Please choose one',
    },
    injuryCategories: {
      test: validateInjuryCategories,
      message: 'Please select at least one',
    },
    preExistingConditionsExplanation: {
      test: validatePreExistingConditionsExplanation,
      message: 'Please add',
    },
  };

  static propTypes = {
    addressCity: PropTypes.string,
    addressState: PropTypes.string,
    addressStreet1: PropTypes.string,
    addressStreet2: PropTypes.string,
    addressZip: PropTypes.string,
    documentUploadAuthorization: PropTypes.object.isRequired,
    documentUploadUrl: PropTypes.string.isRequired,
    email: PropTypes.string,
    firstName: PropTypes.string,
    firstSubmission: PropTypes.string,
    initDropzone: PropTypes.func,
    injuryCategories: PropTypes.objectOf(PropTypes.bool),
    injuryType: PropTypes.string,
    involvementType: PropTypes.string,
    lastName: PropTypes.string,
    phoneNumber: PropTypes.string,
    preExistingConditionsExplanation: PropTypes.string,
    submissionDoneHandler: PropTypes.func.isRequired,
    submissionFailedHandler: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    vehicles: PropTypes.arrayOf(PropTypes.shape({
      currentInvolvedPartyVehicle: PropTypes.bool,
      claimVehicleId: PropTypes.string,
      make: PropTypes.string,
      model: PropTypes.string,
      numberOfPhotos: PropTypes.number,
      vin: PropTypes.string,
      year: PropTypes.number,
    })).isRequired,
  };

  constructor(props) {
    super(props);
    this.submitButtonRef = React.createRef();
    this.dropzoneRef = React.createRef();
    const incomingPhoneNumber = props.phoneNumber?.replace(/\D/g, '');

    const initialState = {
      addressCity: props.addressCity || '',
      addressState: props.addressState || '',
      addressStreet1: props.addressStreet1 || '',
      addressStreet2: props.addressStreet2 || '',
      addressZip: props.addressZip || '',
      documents: [],
      email: props.email || '',
      expired: false,
      firstName: (props.firstName || '').trim(),
      injuryCategories: props.injuryCategories || this._emptyFirstPartyInjuryCategories(),
      injuryType: props.injuryType,
      lastName: (props.lastName || '').trim(),
      modalAlreadyViewed: false,
      phoneNumber: incomingPhoneNumber,
      preExistingConditionsExplanation: props.preExistingConditionsExplanation,
      showModal: false,
      showVINHelpModal: false,
      submitted: false,
      submitting: false,
      showVehicleSelectionError: false,
      documentsAreUploading: false,
    };

    const vehicle = props.vehicles.find((v) => v.currentInvolvedPartyVehicle);

    if (vehicle) {
      Object.assign(initialState, {
        make: vehicle.make,
        model: vehicle.model,
        selectedVehicle: vehicle,
        claimVehicleId: vehicle.claimVehicleId,
        vin: vehicle.vin,
        year: vehicle.year,
      });
    }

    initialState.formErrors = this._validateAll(initialState);
    this.state = initialState;
  }

  componentDidMount() {
    window.requestAnimationFrame(this._fireViewportAnalytics);
    window.addEventListener('scroll', this._fireViewportAnalytics);
  }

  _scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._fireViewportAnalytics);
  }

  get _formData() {
    return {
      firstName: this.state.firstName?.trim(),
      lastName: this.state.lastName?.trim(),
      phoneNumber: this.state.phoneNumber,
      email: this.state.email,
      addressStreet1: this.state.addressStreet1,
      addressStreet2: this.state.addressStreet2,
      addressCity: this.state.addressCity,
      addressState: this.state.addressState,
      addressZip: this.state.addressZip,
      claimVehicleId: this.state.claimVehicleId,
      vin: this.state.vin,
      year: this.state.year,
      make: this.state.make,
      model: this.state.model,
      injuryType: this.state.injuryType,
      reportedInjuries: this.state.injuryType === InjuryTypes.INJURED ? {
        ...this.state.injuryCategories,
        pre_existing_conditions_explanation: preExistingConditionIsSelected(this.state) ? this.state.preExistingConditionsExplanation : null,
      } : null,
      documents: this.state.documents,
      t: this.props.token,
    };
  }

  _fireViewportAnalytics = () => {
    const submitButton = this.submitButtonRef.current;
    const dropzone = this.dropzoneRef.current;

    if (submitButton && ViewportService.isInViewport(submitButton) && !this._submitButtonViewed) {
      this._submitButtonViewed = true;
      AnalyticsService.trackViewEvent('CLAIMS_SNOL_SUBMIT_BUTTON');
    }
    if (dropzone && ViewportService.isInViewport(dropzone) && !this._documentUploadViewed) {
      this._documentUploadViewed = true;
      AnalyticsService.trackViewEvent('CLAIMS_SNOL_PHOTO');
    }
  }

  _onSuccessfulSubmission = (response) => {
    const subsequentNoticeOfLossId = response.data.subsequent_notice_of_loss_id;
    this.props.submissionDoneHandler(this.state.firstName?.trim(), subsequentNoticeOfLossId);
  }

  _onFailedSubmission = () => {
    this.setState({
      submitting: false,
    }, this.props.submissionFailedHandler);
  }

  _handleModalAddPhotosClick = () => {
    AnalyticsService.trackClickEvent('CLAIMS_SNOL_PHOTO_REMINDER_MODAL_ADD_PHOTO');
    this._closeModal();
  }

  _handleModalClose = () => {
    AnalyticsService.trackClickEvent('CLAIMS_SNOL_PHOTO_REMINDER_MODAL_CLOSE');
    this._closeModal();
  }

  _closeModal = () => {
    this.setState({
      showModal: false,
      modalAlreadyViewed: true,
    });
  }

  _handleModalContinueClick = () => {
    AnalyticsService.trackClickEvent('CLAIMS_SNOL_PHOTO_REMINDER_MODAL_CONTINUE');
    this.setState({
      showModal: false,
      modalAlreadyViewed: true,
    }, this._doSubmission);
  }

  _handleVINHelpClicked = (e) => {
    e.preventDefault();
    AnalyticsService.trackClickEvent('CLAIMS_SNOL_VIN_HELP');
    this.setState({
      showVINHelpModal: true,
    });
  }

  _handleVINHelpClose = () => {
    this.setState({
      showVINHelpModal: false,
    });
  }

  get _shouldShowModal() {
    const MIN_REQUIRED_NUMBER_OF_PHOTOS = 3;

    if (!this.state.selectedVehicle || this.state.selectedVehicle === 'not-in-vehicle') { return false; }
    const totalNumberOfPhotos = this.state.selectedVehicle.numberOfPhotos + this.state.documents.length;
    if (totalNumberOfPhotos < MIN_REQUIRED_NUMBER_OF_PHOTOS && !this.state.modalAlreadyViewed) {
      return true;
    }

    return false;
  }

  _submitClicked = (event) => {
    if (event) { event.preventDefault(); }

    if (this.state.submitting) { return; }
    AnalyticsService.trackClickEvent('CLAIMS_SNOL_SUBMIT');

    if (!this.state.selectedVehicle) {
      this.setState({
        showVehicleSelectionError: true,
      });
      this._scrollToTop();
      return;
    }

    this._revalidate();

    if (this._anyErrors()) { return; }
    if (this._shouldShowModal) {
      this.setState({
        showModal: true,
      });

      return;
    }

    this._doSubmission();
  }

  _doSubmission = () => {
    this.setState({
      submitting: true,
    }, () => {
      ClaimsPortalApi.postToPortal('/web_api/claims/portal/snol', this._formData, this._onSuccessfulSubmission, this._onFailedSubmission);
    });
  }

  _handleFieldChange(key) {
    return (value) => {
      this.setState((currentState) => {
        const formErrors = currentState.formErrors;
        formErrors[key] = this._validate(key, value, currentState);
        return {
          ...currentState,
          formErrors,
          [key]: value,
        };
      });
    };
  }

  _setFieldsAndValidate(newFieldState) {
    const prospectiveState = {
      ...this.state,
      ...newFieldState,
    };
    const formErrors = this._validateAll(prospectiveState);
    this.setState({
      ...newFieldState,
      formErrors,
    });
  }

  _handleFieldBlur(key) {
    return () => {
      const fieldErrors = this._revalidate();
      const keyError = fieldErrors[key];
      if (!isBlank(keyError)) {
        AnalyticsService.trackEvent(`CLAIMS_SNOL_${key.toUpperCase()}_ERROR`);
      }
    };
  }

  _handleUploadStart = () => {
    AnalyticsService.trackEvent('CLAIMS_SNOL_PHOTO_ADDED');
    this.setState({
      documentsAreUploading: true,
    });
  }

  _handleAddressStateChange = (value) => {
    this._setFieldsAndValidate({
      addressState: value,
    });
  }

  _handleInjuryFieldChange = (key) => {
    return (value) => {
      value = value?.target?.value || value;
      this._setFieldsAndValidate({
        [key]: value,
      });
    };
  }

  _handleUploadComplete = () => {
    this.setState({
      documentsAreUploading: false,
    });
  }

  _isClaimant() {
    return this.props.involvementType === 'claimant';
  }

  _revalidate() {
    const newErrors = this._validateAll(this.state);

    this.setState({
      formErrors: newErrors,
    });

    return newErrors;
  }

  _validate(key, value, state) {
    const validators = arrayWrap(InvolvedPartyForm.Validators[key]);
    const maybeError = validators.find((v) => !v.test(value, state, this.props));

    return maybeError ? maybeError.message : null;
  }

  _validateAll(state) {
    return Object.keys(InvolvedPartyForm.Validators).reduce((currentFormErrors, key) => {
      currentFormErrors[key] = this._validate(key, state[key], state);
      return currentFormErrors;
    }, {});
  }

  _anyErrors() {
    return !values(this.state.formErrors).every(isBlank);
  }

  _emptyFirstPartyInjuryCategories() {
    return FirstPartyInjuryCategories.reduce((accumulator, category) => {
      accumulator[category.value] = false;
      return accumulator;
    }, {});
  }

  get _title() {
    if (this.props.firstSubmission === 'false') {
      return 'Welcome back!';
    } else {
      const firstName = this.state.firstName?.trim();
      return firstName ? `Hi ${firstName}.` : 'Hi there.';
    }
  }

  get _description() {
    return (
      <p
        css={SnolStyles.subtext}
        id={'subheading'}
      >
        You can use this form to send photos of your vehicle and verify your contact information. <br /> <br />
        Claims with photos and contact information can get processed up to <b> 60% faster. </b>
      </p>
    );
  }

  get _personalInformationFieldSet() {
    return (
      <fieldset>
        <h2 css={SnolStyles.secondaryHeader}>Personal information</h2>
        <Input
          autoFocus={true}
          errorLabel={this.state.formErrors.firstName}
          label={'First name'}
          maxLength={100}
          name={'first_name'}
          onChange={this._handleFieldChange('firstName')}
          value={this.state.firstName}
          wrapperStyle={this.state.formErrors.firstName ? styles.dropMargin : styles.inputMarginBottom}
        />
        <Input
          errorLabel={this.state.formErrors.lastName}
          label={'Last name'}
          maxLength={100}
          name={'last_name'}
          onChange={this._handleFieldChange('lastName')}
          value={this.state.lastName}
          wrapperStyle={this.state.formErrors.lastName ? styles.dropMargin : styles.inputMarginBottom}
        />
        <PhoneNumberInput
          errorLabel={this.state.formErrors.phoneNumber}
          inputMask={NO_PHONE_NUMBER_MASK}
          inputType={TextTypes.TEL}
          label={'Phone number'}
          name={'phone_number'}
          onBlur={this._handleFieldBlur('phoneNumber')}
          onChange={this._handleFieldChange('phoneNumber')}
          value={this.state.phoneNumber}
        />
        <Input
          errorLabel={this.state.formErrors.email}
          inputType={TextTypes.EMAIL}
          label={'Email address'}
          maxLength={254}
          name={'email'}
          onBlur={this._handleFieldBlur('email')}
          onChange={this._handleFieldChange('email')}
          value={this.state.email}
          wrapperStyle={this.state.formErrors.phoneNumber ? styles.twentyfivePxTopMargin : styles.inputMarginTop}
        />
      </fieldset>
    );
  }

  get _mailingAddressFieldSet() {
    return (
      <fieldset>
        <h2 css={SnolStyles.secondaryHeader}>Mailing address</h2>
        <Input
          errorLabel={this.state.formErrors.addressStreet1}
          label={'Street address'}
          maxLength={100}
          name={'address_street1'}
          onChange={this._handleFieldChange('addressStreet1')}
          value={this.state.addressStreet1}
          wrapperStyle={this.state.formErrors.addressStreet1 ? styles.dropMargin : styles.inputMarginBottom}
        />
        <Input
          errorLabel={this.state.formErrors.addressStreet2}
          label={'Street address 2 (optional)'}
          maxLength={100}
          name={'address_street2'}
          onChange={this._handleFieldChange('addressStreet2')}
          value={this.state.addressStreet2}
          wrapperStyle={this.state.formErrors.addressStreet2 ? styles.dropMargin : styles.inputMarginBottom}
        />
        <Input
          errorLabel={this.state.formErrors.addressCity}
          label={'City'}
          maxLength={100}
          name={'address_city'}
          onChange={this._handleFieldChange('addressCity')}
          value={this.state.addressCity}
          wrapperStyle={this.state.formErrors.addressCity ? styles.dropMargin : styles.inputMarginBottom}
        />
        <Select
          inputId={'address_state'}
          inputName={'incident_location_state'}
          onChange={this._handleAddressStateChange}
          options={StatePickerOptions}
          placeholder={'State'}
          selectedValue={this.state.addressState || ''}
        />
        <Input
          errorLabel={this.state.formErrors.addressZip}
          inputType={TextTypes.TEXT}
          label={'ZIP'}
          maxLength={10}
          name={'address_zip'}
          onBlur={this._handleFieldBlur('addressZip')}
          onChange={this._handleFieldChange('addressZip')}
          value={this.state.addressZip}
          wrapperStyle={styles.inputMarginTop}
        />
      </fieldset>
    );
  }

  get _vehicleInformationFieldSet() {
    return (
      <fieldset>
        <h2 css={SnolStyles.secondaryHeader}>Vehicle Details</h2>
        <Input
          errorLabel={this.state.formErrors.year}
          inputType={TextTypes.NUMBER}
          label={'Year'}
          maxLength={4}
          name={'year'}
          onBlur={this._handleFieldBlur('year')}
          onChange={this._handleFieldChange('year')}
          value={this.state.year}
          wrapperStyle={this.state.formErrors.year ? styles.dropMargin : styles.inputMarginBottom}
        />
        <Input
          errorLabel={this.state.formErrors.make}
          label={'Make'}
          maxLength={50}
          name={'make'}
          onBlur={this._handleFieldBlur('make')}
          onChange={this._handleFieldChange('make')}
          value={this.state.make}
          wrapperStyle={this.state.formErrors.make ? styles.dropMargin : styles.inputMarginBottom}
        />
        <Input
          errorLabel={this.state.formErrors.model}
          label={'Model'}
          maxLength={50}
          name={'model'}
          onBlur={this._handleFieldBlur('model')}
          onChange={this._handleFieldChange('model')}
          value={this.state.model}
          wrapperStyle={this.state.formErrors.model ? styles.dropMargin : styles.inputMarginBottom}
        />
        <Input
          errorLabel={this.state.formErrors.vin}
          label={'VIN'}
          name={'vin'}
          onBlur={this._handleFieldBlur('vin')}
          onChange={this._handleFieldChange('vin')}
          value={this.state.vin}
        />
        <a
          className={'input-help'}
          css={styles.block}
          href={'#'}
          onClick={this._handleVINHelpClicked}
        >How do I find my VIN?
        </a>
      </fieldset>
    );
  }

  get _injuryInformationFieldSet() {
    return (
      <fieldset>
        <h2 css={SnolStyles.secondaryHeader}>Injuries</h2>
        <InjuryQuestion
          currentInjuryType={this.state.injuryType}
          isError={false}
          onFieldEvent={this._handleInjuryFieldChange('injuryType')}
        />
        {injuryTypeIsInjured(this.state) &&
          <InjuryCategoriesQuestion
            currentName={'you'}
            currentSelectedInjuryCategories={this.state.injuryCategories}
            injuryCategories={FirstPartyInjuryCategories}
            inputKey={'snol-filer'}
            isError={false}
            onFieldEvent={this._handleInjuryFieldChange('injuryCategories')}
          />
        }
        {injuryTypeIsInjured(this.state) && preExistingConditionIsSelected(this.state) &&
          <PreExistingConditionsExplanationQuestion
            currentPreExistingConditionsExplanation={this.state.preExistingConditionsExplanation}
            errorLabel={this.state.formErrors.preExistingConditionsExplanation}
            onFieldEvent={this._handleInjuryFieldChange('preExistingConditionsExplanation')}
          />
        }
      </fieldset>
    );
  }

  get _vehicleList() {
    if (!this.props.vehicles) { return null; }

    return this.props.vehicles.map((vehicle) => {
      const vehicleName = this._vehicleName(vehicle);

      return (
        <RadioOption
          id={`vehicle-${vehicle.claimVehicleId}`}
          isSelected={this.state.selectedVehicle === vehicle}
          key={`${vehicle.claimVehicleId}-button`}
          label={vehicleName}
          name={vehicleName}
          onClick={this._selectVehicle(vehicle)}
          optionValue={vehicleName}
        />
      );
    });
  }

  get _otherVehicleOptions() {
    return (
      <>
        <RadioOption
          id={'not-in-vehicle-button-label'}
          isSelected={this.state.selectedVehicle === 'not-in-vehicle'}
          key={'not-in-vehicle-button'}
          label={'I wasn\'t in a vehicle'}
          name={'I wasn\'t in a vehicle'}
          onClick={this._selectVehicle('not-in-vehicle')}
          optionValue={'not-in-vehicle'}
        />
        <RadioOption
          id={'vehicle-not-listed-button-label'}
          isSelected={this.state.selectedVehicle === 'vehicle-not-listed'}
          key={'vehicle-not-listed-button'}
          label={'My vehicle isn\'t listed'}
          name={'My vehicle isn\'t listed'}
          onClick={this._selectVehicle('vehicle-not-listed')}
          optionValue={'vehicle-not-listed'}
        />
      </>
    );
  }

  get _vehicleOptions() {
    return (
      <>
        <h2 css={SnolStyles.secondaryHeader}>Vehicle information</h2>
        <p css={SnolStyles.subtext}>Which one of these vehicles were you in when the incident happened?</p>
        {this._vehicleSelectionError}
        <RadioInputGroup>
          {this._vehicleList}
          {this._otherVehicleOptions}
        </RadioInputGroup>
      </>
    );
  }

  get _vehicleSelectionError() {
    if (!this.state.showVehicleSelectionError) { return null; }
    if (this.state.selectedVehicle) { return null; }
    const smartQuote = '\u201d';
    const smartApostrophe = '\u2019';

    return (
      <div
        className={'error-label'}
        id={'vehicle-error-label'}
      >
        You must choose a vehicle or select {smartQuote}I wasn{smartApostrophe}t in a vehicle{smartQuote}
      </div>
    );
  }

  _vehicleName(vehicle) {
    return [
      vehicle.year,
      vehicle.make || '[Enter Make]',
      vehicle.model || '[Enter Model]',
    ].join(' ');
  }

  _selectVehicle = (vehicle) => {
    const undefinedToString = (val) => val === undefined ? '' : val;
    return () => {
      this._setFieldsAndValidate({
        year: undefinedToString(vehicle.year),
        make: undefinedToString(vehicle.make),
        model: undefinedToString(vehicle.model),
        vin: undefinedToString(vehicle.vin),
        claimVehicleId: undefinedToString(vehicle.claimVehicleId),
        selectedVehicle: vehicle,
      });
    };
  };

  get _photosFieldSet() {
    const numberOfPhotos = this.state.selectedVehicle === 'vehicle-not-listed' ? 0 : this.state.selectedVehicle.numberOfPhotos;

    const areOrIs = numberOfPhotos === 1 ? 'is' : 'are';
    const photosText = numberOfPhotos === 1 ? 'photo' : 'photos';

    return (
      <fieldset>
        <h3 css={SnolStyles.tertiaryHeader}>Photos</h3>
        <div css={SnolStyles.subtext}>
          <p>Use the box below to upload the following photos of the vehicle listed above.</p>
          <ul
            className={'dashed'}
            css={styles.listMargins}
          >
            <li>Photos showing your entire vehicle from each corner</li>
            <li>Photos of the damage from different angles</li>
            <li>VIN number</li>
            <li>Odometer/mileage</li>
          </ul>
          <p>These photos will help us resolve your claim up to 60% faster.</p>
          <br />
          <p>There {areOrIs} <b>{numberOfPhotos} {photosText}</b> on file for this vehicle already.</p>
        </div>
        <DocumentUpload
          acceptedFiles={ACCEPTED_IMAGE_FILES}
          documents={this.state.documents}
          documentUploadAuthorization={this.props.documentUploadAuthorization}
          documentUploadUrl={this.props.documentUploadUrl}
          dropzoneRef={this.dropzoneRef}
          initDropzone={this.props.initDropzone}
          onChange={this._handleFieldChange('documents')}
          onUploadComplete={this._handleUploadComplete}
          onUploadStart={this._handleUploadStart}
        />
      </fieldset>
    );
  }

  get _submitButton() {
    return (
      <LoaderButton
        disabled={this._anyErrors() || this.state.documentsAreUploading}
        isLoading={this.state.submitting}
        loaderButtonRef={this.submitButtonRef}
        loadingText={'Submitting'}
        onClick={this.onSubmit}
        styles={SnolStyles.continueButtonMargin}
      >
        {this.state.documentsAreUploading ? 'Uploading photos. Please wait.' : 'Submit'}
      </LoaderButton>
    );
  }

  get _modal() {
    if (!this.state.showModal) { return null; }
    if (this.state.selectedVehicle === 'not-in-vehicle') { return null; }

    const photoCount = this.state.selectedVehicle.numberOfPhotos + this.state.documents.length;
    let callToAction;
    if (photoCount > 0) {
      callToAction = 'Are you sure you want to continue without adding any more photos?';
    } else {
      callToAction = 'Are you sure you want to continue without adding any photos?';
    }
    return (
      <Modal
        isShowing={true}
        name={'submission-modal'}
        onCancel={this._handleModalClose}
      >
        <p>
          Claims with at least 3 photos can be processed up to <b>60% faster.</b> <br /> <br />
          {callToAction}
        </p>
        <Button
          onClick={this._handleModalAddPhotosClick}
          size={BUTTON_SIZE_SMALL}
        >
          Let me add photos
        </Button>

        <Button
          css={styles.continue}
          onClick={this._handleModalContinueClick}
          size={BUTTON_SIZE_SMALL}
        >
          Continue
        </Button>
      </Modal>
    );
  }

  get _VINHelpModal() {
    if (!this.state.showVINHelpModal) { return null; }

    return (
      <Modal
        isShowing={true}
        name={'vin-modal'}
        onCancel={this._handleVINHelpClose}
      >
        <h5 css={styles.vinHelpTitle}>How do I find my VIN?</h5>
        <VINHelp />
        <p> Your Vehicle Identification Number is typically on the doorjamb or the driver side dashboard (look through the windshield from outside the vehicle). </p>
      </Modal>
    );
  }

  get _vehicleDetailsAndPhotosSection() {
    if (!this.state.selectedVehicle || this.state.selectedVehicle === 'not-in-vehicle') { return null; }

    return (
      <>
        {this._vehicleInformationFieldSet}
        {this._photosFieldSet}
      </>
    );
  }

  _renderForm() {
    return (
      <>
        <h1 css={SnolStyles.primaryHeader}>{this._title}</h1>
        {this._description}
        <form
          noValidate
          data-testid={'involved-party-form'}
          onSubmit={this._submitClicked}
          ref={(e) => this.formRef = e}
        >
          {this._vehicleOptions}
          {this._vehicleDetailsAndPhotosSection}
          {this._personalInformationFieldSet}
          {this._mailingAddressFieldSet}
          {this._injuryInformationFieldSet}
          {this._modal}
          {this._VINHelpModal}
          {this._submitButton}
        </form>
      </>
    );
  }

  render() {
    return this._renderForm();
  }
}

const styles = StyleSheet.create({
  block: {
    display: 'block',
  },
  continue: {
    color: Colors.rootOrange(),
    backgroundColor: Colors.white(),
  },
  dropMargin: {
    margin: '0',
  },
  inputMarginBottom: {
    marginBottom: '12px',
  },
  inputMarginTop: {
    marginTop: '12px',
  },
  listMargins: {
    marginTop: '23px',
    marginBottom: '23px',
  },
  twentyfivePxTopMargin: {
    marginTop: '25px',
  },
  vinHelpTitle: {
    fontFamily: 'Root Sans',
    fontSize: '36px',
    lineHeight: '36px',
    letterSpacing: '-0.1px',
    fontWeight: 300,
    textAlign: 'center',
    marginBottom: 'inherit',
    '@media only screen and (max-width: 1242px) and (orientation: landscape)': {
      marginTop: '-10%',
      marginBottom: 'auto',
    },
  },
});
