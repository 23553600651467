import NetworkRequestConfiguration from '@root/core/src/networking/network-request-configuration';

export default function policySearchConfiguration(policySearchParams) {
  return new NetworkRequestConfiguration({
    endpoint: 'claims/portal/policies',
    bodyParameters: policySearchParams,
    method: NetworkRequestConfiguration.Methods.POST,
  });
}

