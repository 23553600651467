import NetworkRequestConfiguration from '@root/core/src/networking/network-request-configuration';

export default function submitFnolConfiguration(fnolParameters) {
  return new NetworkRequestConfiguration({
    endpoint: 'claims/portal/fnol',
    bodyParameters: {
      ...fnolParameters,
    },
    method: NetworkRequestConfiguration.Methods.POST,
  });
}

