import AnalyticsService from '@root/core/src/services/analytics-service';
import Button from '@root/core/src/components/button';
import FnolStyles from '@root/claims.joinroot.com/src/assets/fnol-styles.js';
import RadioInputGroup from '@root/core/src/components/radio-input-group';
import RadioOption from '@root/core/src/components/radio-option';
import React, { useContext, useEffect } from '@root/vendor/react';
import { FnolContext, FnolDispatchContext, actionTypes } from '@root/claims.joinroot.com/src/contexts/fnol-context-provider';
import { progressFnolForward, progressFnolSet } from '@root/claims.joinroot.com/src/utils/fnol/progress-helper';
import { useHistory } from '@root/vendor/react-router-dom';

export default function PolicyQuestion() {
  const progressName = 'root-policy';
  const history = useHistory();

  const fnolState = useContext(FnolContext);
  const fnolDispatch = useContext(FnolDispatchContext);

  useEffect(() => {
    progressFnolSet([], fnolDispatch);
    AnalyticsService.trackViewEvent('FNOL_WEB_POLICYQUESTION');
    fnolDispatch({
      type: actionTypes.CLEAR_AND_KEEP_PROGRESS_FOR_FNOL,
      undefined,
    });
  }, [fnolDispatch]);

  const _dispatchPolicyholder = (isPolicyholder) => {
    AnalyticsService.trackClickEvent(isPolicyholder ? 'FNOL_WEB_POLICYQUESTION_YESPOLICYHOLDER' : 'FNOL_WEB_POLICYQUESTION_NOPOLICYHOLDER');
    fnolDispatch({
      type: actionTypes.SET_POLICYHOLDER_FOR_FNOL,
      isPolicyholder,
    });
  };

  const _handlePolicyholderSubmission = () => {
    AnalyticsService.trackEvent('FNOL_WEB_POLICYQUESTION_GETSTARTED');
    progressFnolForward(progressName, fnolDispatch, fnolState);
    if (fnolState.isPolicyholder === false && fnolState.isPolicyholder !== undefined) {
      history.push('/portal/fnol/vehicle');
    } else if (fnolState.isPolicyholder === true) {
      history.push('/portal/fnol/app');
    }
  };

  return (
    <div>
      <h1 css={FnolStyles.primaryHeader}>File an Auto Claim</h1>
      <p css={FnolStyles.subtext}>To file a new claim with Root, start by telling us a little about yourself.</p>

      <fieldset>
        <h4 css={FnolStyles.prompt}>Do you have auto insurance through Root?</h4>
        <RadioInputGroup>
          <RadioOption
            id={'policyholder-true'}
            isSelected={fnolState.isPolicyholder === true}
            label={'Yes, I have a policy at Root'}
            name={'policyholder'}
            onClick={() => { _dispatchPolicyholder(true); }}
            optionValue={'true'}
          />
          <RadioOption
            id={'policyholder-false'}
            isSelected={fnolState.isPolicyholder === false}
            label={'No'}
            name={'policyholder'}
            onClick={() => { _dispatchPolicyholder(false); }}
            optionValue={'false'}
          />
        </RadioInputGroup>
      </fieldset>
      <Button
        css={FnolStyles.continueButtonMargin}
        disabled={fnolState.isPolicyholder === undefined}
        onClick={_handlePolicyholderSubmission}
      >
        Get Started
      </Button>
    </div>
  );
}
