import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import isRequiredIf from '@root/core/src/utils/is-required-if';
import styled from '@root/vendor/@emotion/styled';
import { Colors, Shadows, StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function RadioOption({
  circleColor,
  customStyle,
  customInnerStyle,
  disabled,
  id,
  isError,
  isSelected,
  label,
  icon,
  iconAltText,
  name,
  onChange,
  onClick = () => {},
  optionValue,
  secondaryText,
  secondaryTextPosition = 'top',
}) {
  const handleClick = (e) => {
    if (!disabled) {
      e.target.focus();
      onClick(e);
    }
  };

  const secondaryTextPositionTop = secondaryTextPosition === 'top';
  const styles = stylesGenerator(circleColor);
  return (
    <>
      <input
        css={styles.input}
        defaultChecked={isSelected}
        disabled={disabled}
        id={id}
        name={name}
        onChange={onChange}
        onClick={handleClick}
        type={'radio'}
        value={optionValue}
      />

      <OptionLabel
        customStyle={customStyle}
        htmlFor={id}
        isDisabled={disabled}
        isError={isError}
      >
        <div css={styles.optionContainer}>
          {icon &&
            <img
              alt={iconAltText}
              css={styles.icon}
              src={icon}
            />
          }
          <div>
            {secondaryText &&
              secondaryTextPositionTop &&
              <div css={styles.secondaryText}>
                {secondaryText}
              </div>
            }
            <InnerLabel
              customStyle={customInnerStyle ? customInnerStyle : customStyle}
              isDisabled={disabled}
              isError={isError}
            >
              {label}
            </InnerLabel>
            {secondaryText &&
              !secondaryTextPositionTop &&
              <div css={styles.secondaryText}>
                {secondaryText}
              </div>
            }
          </div>
        </div>
        <CircleWrapper
          data-testid={'circle-wrapper'}
          isDisabled={disabled}
          isError={isError}
        >
          <Circle
            circleColor={circleColor}
            isDisabled={disabled}
            isError={isError}
            isSelected={isSelected}
          />
        </CircleWrapper>
      </OptionLabel>
    </>
  );
}

const OptionLabel = styled.label({
  ...Theme.paragraph1(),
  height: 65,
  padding: 20,
  paddingRight: 12,
  margin: 0,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  transition: 'all 150ms ease-out',
}, ({
  customStyle,
  isDisabled,
  isError,
}) => {
  const getColor = () => {
    if (isError) { return Colors.error(); }
    if (isDisabled) { return Colors.gray30(); }
    return Colors.nearBlack();
  };

  return {
    color: getColor(),
    pointerEvents: isDisabled ? 'none' : 'auto',
    borderTop: `1px solid ${Colors.gray20()}`,
    ...customStyle?.radioOption,
    ...customStyle,
  };
});

const InnerLabel = styled.label({
  ...Theme.paragraph1(),
  paddingRight: 12,
  margin: 0,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  transition: 'all 150ms ease-out',
}, ({
  customStyle,
  isDisabled,
  isError,
}) => {
  const getColor = () => {
    if (isError) { return Colors.error(); }
    if (isDisabled) { return Colors.gray30(); }
    return Colors.nearBlack();
  };

  return {
    color: getColor(),
    pointerEvents: isDisabled ? 'none' : 'auto',
    ...customStyle?.radioOption,
    ...customStyle,
  };
}).withComponent('div');

const CircleWrapper = styled.div({
  minHeight: 24,
  minWidth: 24,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  transition: 'all 150ms ease-out',
}, ({
  isDisabled,
  isError,
}) => ({
  border: isDisabled ? 'none' : `1px solid ${isError ? Colors.error() : Colors.gray40()}`,
  background: isDisabled ? Colors.gray10() : undefined,
}));

const Circle = styled.div({
  height: 16,
  width: 16,
  borderRadius: 'inherit',
  transition: 'opacity 200ms ease-in-out',
}, ({
  circleColor,
  isDisabled,
  isError,
  isSelected,
}) => {
  const getBackground = () => {
    if (isError) { return Colors.error(); }
    if (isDisabled) { return Colors.gray50(); }
    return circleColor || Colors.rootOrange();
  };

  return {
    background: getBackground(),
    opacity: isSelected ? 1 : 0,
  };
});

RadioOption.propTypes = {
  circleColor: PropTypes.string,
  customInnerStyle: PropTypes.object,
  customStyle: PropTypes.object,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  iconAltText: isRequiredIf(PropTypes.string, ({ icon }) => icon),
  id: PropTypes.string.isRequired,
  isError: PropTypes.bool,
  isSelected: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: isRequiredIf(PropTypes.func, ({ onClick }) => !onClick),
  onClick: isRequiredIf(PropTypes.func, ({ onChange }) => !onChange),
  optionValue: PropTypes.string.isRequired,
  secondaryText: PropTypes.string,
  secondaryTextPosition: PropTypes.string,
};

const stylesGenerator = (shadowColor) => StyleSheet.create({
  icon: {
    marginRight: 15,
  },
  input: {
    zIndex: -1,
    opacity: 0,
    position: 'absolute',
    ':focus + label > div:last-child': {
      ...Shadows.focusShadow(shadowColor),
    },
  },
  optionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  secondaryText: {
    ...Theme.inputLabel(),
  },
});
