import FnolStyles from '@root/claims.joinroot.com/src/assets/fnol-styles';
import LinkButton from '@root/core/src/components/link-button';
import React, { useContext, useEffect } from '@root/vendor/react';
import Strings from '@root/claims.joinroot.com/src/utils/strings';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { FnolDispatchContext, actionTypes } from '@root/claims.joinroot.com/src/contexts/fnol-context-provider';
import { progressFnolSet } from '@root/claims.joinroot.com/src/utils/fnol/progress-helper';

export default function ClaimFailed() {
  const fnolDispatch = useContext(FnolDispatchContext);

  useEffect(() => {
    progressFnolSet(['claim-failed'], fnolDispatch);
    fnolDispatch({
      type: actionTypes.CLEAR_AND_KEEP_PROGRESS_FOR_FNOL,
      undefined,
    });
  }, [fnolDispatch]);

  return (
    <div>
      <h1 css={[FnolStyles.primaryHeader, styles.centered]}>{'We\'re sorry. There has been an error in filing your claim.'}</h1>
      <br />
      <h1 css={[FnolStyles.primaryHeader, styles.centered, styles.orange]}>
        Please call us at (866) 980-9431 to file a claim.
      </h1>
      <br />

      <LinkButton
        href={'tel:' + Strings.fnolPhoneNumber}
      >
        Call to file
      </LinkButton>
    </div>
  );
}

const styles = StyleSheet.create({
  centered: {
    textAlign: 'center',
  },
  orange: {
    color: Colors.rootOrange(),
  },
});
