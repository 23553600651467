import AnalyticsService from '@root/core/src/services/analytics-service';
import ClaimsPortalRouter from '@root/claims.joinroot.com/src/components/claims-portal-router';
import Footer from '@root/claims.joinroot.com/src/components/footer';
import Header from '@root/claims.joinroot.com/src/components/header';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import ReactDOM from '@root/vendor/react-dom';
import { BrowserRouter } from '@root/vendor/react-router-dom';
import '@root/claims.joinroot.com/src/assets/claims-portal.scss';

ClaimsPortal.propTypes = {
  routerComponent: PropTypes.func,
};

export default function ClaimsPortal({ routerComponent: Router }) {
  AnalyticsService.initMixpanel();

  if (Router) {
    return (
      <Router>
        <Header />
        <main>
          <ClaimsPortalRouter />
        </main>
        <Footer />
      </Router>
    );
  } else {
    return (
      <BrowserRouter>
        <Header />
        <main>
          <ClaimsPortalRouter />
        </main>
        <Footer />
      </BrowserRouter>
    );
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('react-entry-point');

  const unsupportedBrowsers = [
    'Edge/17',
    'Edge/18',
  ];

  const isUnsupportedBrowser = () => {
    unsupportedBrowsers.forEach((browser) => {
      if (window.navigator.userAgent && window.navigator.userAgent.indexOf(browser) >= 0) {
        return true;
      }
    });

    return false;
  };

  if (Object.entries && window.URLSearchParams && !isUnsupportedBrowser()) {
    ReactDOM.render(
      <ClaimsPortal
        {...node.dataset}
      />,
      node
    );
  }
});
