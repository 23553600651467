import AnalyticsService from '@root/core/src/services/analytics-service';
import AssociatedPartyTypeQuestion from '@root/claims.joinroot.com/src/components/fnol/questions/associated-party-type-question';
import Button from '@root/core/src/components/button';
import EmailQuestion from '@root/claims.joinroot.com/src/components/fnol/questions/email-question';
import FnolStyles from '@root/claims.joinroot.com/src/assets/fnol-styles';
import MailingAddressQuestion from '@root/claims.joinroot.com/src/components/fnol/questions/mailing-address-question';
import NameQuestion from '@root/claims.joinroot.com/src/components/fnol/questions/name-question';
import PhoneNumberQuestion from '@root/claims.joinroot.com/src/components/fnol/questions/phone-number-question';
import React, { useContext, useEffect, useState } from '@root/vendor/react';
import Validator from '@root/claims.joinroot.com/src/utils/fnol/caller-information-validator';
import { FnolContext, FnolDispatchContext, actionTypes } from '@root/claims.joinroot.com/src/contexts/fnol-context-provider';
import { StyleSheet } from '@root/core/src/utils/styles';
import { progressFnolForward } from '@root/claims.joinroot.com/src/utils/fnol/progress-helper';
import { useHistory } from '@root/vendor/react-router-dom';

CallerInformation.submitErrorMsg = 'Some of the information we need is missing or incomplete. Please check the highlighted fields and try again.';

export default function CallerInformation() {
  const progressName = 'caller-information';
  const dispatch = useContext(FnolDispatchContext);
  const history = useHistory();
  const state = useContext(FnolContext);
  const [showError, setShowError] = useState({
    fields: false,
    submit: false,
  });

  const refs = {
    submitError: React.createRef(),
  };

  useEffect(() => {
    AnalyticsService.trackViewEvent('FNOL_WEB_CALLERINFORMATION');
  }, []);

  const styles = StyleSheet.create({
    inputMarginBottom: {
      marginBottom: '10px',
    },
    inputMarginTop: {
      marginTop: '10px',
    },
  });

  const _submitForm = () => {
    AnalyticsService.trackClickEvent('FNOL_WEB_CALLERINFORMATION', 'GETSTARTED');

    if (!Validator.isValid(state)) {
      setShowError({
        fields: true,
        submit: true,
      });

      return;
    }

    progressFnolForward(progressName, dispatch, state);
    history.push('/portal/fnol/step2');
  };

  const _dispatchForm = (form) => {
    dispatch({
      type: actionTypes.SET_CALLER_FORM_FOR_FNOL,
      form,
    });
  };

  const _handleFieldEvent = (key) => {
    return (value) => {
      value = value?.target?.value || value;
      value = value === 'true' ? true : value;
      value = value === 'false' ? false : value;
      const validatedForm = Validator.validateNewState(key, value, state);

      _dispatchForm(validatedForm);
    };
  };

  const _errorLabel = (key, requiredMsg) => {
    if (!showError.fields) { return; }

    if (state.errors[key]) {
      return state.errors[key];
    }

    if (requiredMsg && !state[key]) {
      return requiredMsg;
    }
  };

  const _renderErrorMessages = () => {
    if (!showError.submit) { return ''; }

    return (
      <div
        className={'errors'}
        ref={refs.submitError}
      >
        <p>{CallerInformation.submitErrorMsg}</p>
      </div>
    );
  };

  return (
    <div>
      <h1 css={FnolStyles.primaryHeader}>File a Claim: Step 1</h1>
      <h4 css={FnolStyles.secondaryHeader}>Caller Information</h4>
      {_renderErrorMessages()}
      <AssociatedPartyTypeQuestion
        currentPartyType={state.filerPartyType}
        name={'filer'}
        onFieldEvent={_handleFieldEvent('filerPartyType')}
      />
      <NameQuestion
        currentFirstName={state.filerFirstName}
        currentLastName={state.filerLastName}
        firstNameErrorLabel={_errorLabel('filerFirstName', 'required')}
        headerStyle={FnolStyles.prompt}
        inputStyle={styles.inputMarginBottom}
        lastNameErrorLabel={_errorLabel('filerLastName', 'required')}
        name={'filer'}
        onFirstNameFieldEvent={_handleFieldEvent('filerFirstName')}
        onLastNameFieldEvent={_handleFieldEvent('filerLastName')}
      />
      <PhoneNumberQuestion
        currentPhoneNumber={state.filerPhoneNumber}
        errorLabel={_errorLabel('filerPhoneNumber', 'Please add your phone number')}
        name={'filer'}
        onFieldEvent={_handleFieldEvent('filerPhoneNumber')}
      />
      <EmailQuestion
        currentEmail={state.filerEmail}
        errorLabel={_errorLabel('filerEmail')}
        name={'filer'}
        onFieldEvent={_handleFieldEvent('filerEmail')}
      />
      <MailingAddressQuestion
        currentAddressCity={state.filerAddressCity}
        currentAddressState={state.filerAddressState}
        currentAddressStreet1={state.filerAddressStreet1}
        currentAddressStreet2={state.filerAddressStreet2}
        currentAddressZip={state.filerAddressZip}
        inputStyleCity={styles.inputMarginBottom}
        inputStyleStreet1={styles.inputMarginBottom}
        inputStyleStreet2={styles.inputMarginBottom}
        inputStyleZip={styles.inputMarginTop}
        name={'filer'}
        onCityFieldEvent={_handleFieldEvent('filerAddressCity')}
        onStateFieldEvent={_handleFieldEvent('filerAddressState')}
        onStreet1FieldEvent={_handleFieldEvent('filerAddressStreet1')}
        onStreet2FieldEvent={_handleFieldEvent('filerAddressStreet2')}
        onZipFieldEvent={_handleFieldEvent('filerAddressZip')}
        zipErrorLabel={_errorLabel('filerAddressZip')}
      />
      <Button
        css={FnolStyles.continueButtonMargin}
        onClick={_submitForm}
      >
        Get Started
      </Button>
    </div>
  );
}
