import NetworkRequestConfiguration from '@root/core/src/networking/network-request-configuration';

export default function getSnolConfiguration(snolToken) {
  return new NetworkRequestConfiguration({
    endpoint: 'claims/portal/snol',
    queryParameters: {
      t: snolToken,
    },
    method: NetworkRequestConfiguration.Methods.GET,
  });
}
