import AnalyticsService from '@root/core/src/services/analytics-service';
import Button, { BUTTON_SIZE_SMALL, BUTTON_VARIANT_SECONDARY } from '@root/core/src/components/button';
import CaptchaService from '@root/core/src/services/captcha-service';
import FnolStyles from '@root/claims.joinroot.com/src/assets/fnol-styles';
import InvolvedParty from '@root/claims.joinroot.com/src/components/fnol/involved-parties-form/involved-party';
import LoaderButton from '@root/core/src/components/loader-button';
import React, { useContext, useEffect, useState } from '@root/vendor/react';
import Validator from '@root/claims.joinroot.com/src/utils/fnol/involved-parties-form-validator';
import environment from '@root/core/src/utils/environment';
import moment from 'moment-timezone';
import submitFnolConfiguration from '@root/claims.joinroot.com/src/api/fnol-requests/submit-fnol-configuration';
import useSafeImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import { AssociatedPartyTypes, CallerTypes, DriverTypes, IncidentRole, InjuryTypes, InvolvementTypes, LinkedAssociatedPartyTypes, PointOfImpactTypes, SubjectInvolvedPartyTypes } from '@root/claims.joinroot.com/src/models/claims';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { FnolContext, FnolDispatchContext, actionTypes } from '@root/claims.joinroot.com/src/contexts/fnol-context-provider';
import { progressFnolForward } from '@root/claims.joinroot.com/src/utils/fnol/progress-helper';
import { useHistory } from '@root/vendor/react-router-dom';

export default function InvolvedPartiesForm() {
  const [showError, setShowError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const state = useContext(FnolContext);
  const dispatch = useContext(FnolDispatchContext);
  const history = useHistory();
  const [submitFnol] = useSafeImperativeNetworkRequest(submitFnolConfiguration);

  useEffect(() => {
    AnalyticsService.trackViewEvent('FNOL_WEB_INVOLVEDPARTIESFORM');
  }, []);

  const notOnPolicy = () => {
    if (state.caller?.subjectInvolvedPartyType) {
      return state.caller.subjectInvolvedPartyType !== SubjectInvolvedPartyTypes.INSURED;
    } else {
      return true;
    }
  };

  const _nullifyEmptyStrings = (object) => {
    const returnObject = object;
    const keys = Object.keys(returnObject);
    for (const key of keys) {
      if (returnObject[key] === '') {
        returnObject[key] = null;
      }
    }
    return returnObject;
  };

  const subject = () => {
    const not_on_policy = notOnPolicy();
    const thirdPartyCaller = state.caller?.callerType === CallerTypes.THIRD_PARTY;
    const party = _nullifyEmptyStrings({
      filer: !thirdPartyCaller,
      not_on_policy,
      first_name: state.subjectFirstName,
      last_name: state.subjectLastName,
      primary_phone: state.subjectPhoneNumber,
      email: state.subjectEmail,
      address1: state.subjectAddressStreet1,
      address2: state.subjectAddressStreet2,
      // country_code: , THIS NEEDS TO HAPPEN IN ANOTHER PR
      city: state.subjectAddressCity,
      state: state.subjectAddressState,
      zip: state.subjectAddressZip,
      injury_type: InjuryTypes[state.subjectInjuryType],
      incident_role: state.driverType === DriverTypes.SUBJECT ? IncidentRole.DRIVER : IncidentRole.UNKNOWN,
      involvement_type: not_on_policy ? InvolvementTypes.CLAIMANT : InvolvementTypes.INSURED,
      universal_driver_id: state.subjectUniversalDriverId,
      reported_injuries: state.subjectInjuryType === 'INJURED' ? {
        ...state.subjectInjuryCategories,
        pre_existing_conditions_explanation: state.subjectInjuryCategories?.pre_existing_injury ? state.subjectPreExistingConditionsExplanation : null,
      } : null,
    });
    if (thirdPartyCaller && state.filerPartyType === AssociatedPartyTypes.ATTORNEY) {
      party['attorney'] = filer();
    }
    return party;
  };

  const driver = () => {
    return {
      filer: false,
      not_on_policy: state.driverType !== DriverTypes.POLICY_DRIVER,
      first_name: state.driverFirstName,
      last_name: state.driverLastName,
      primary_phone: state.driverPhoneNumber,
      injury_type: state.driverInjuryType,
      incident_role: IncidentRole.DRIVER,
      universal_driver_id: state.driverUniversalDriverId === '' || state.driverType !== DriverTypes.POLICY_DRIVER ? null : state.driverUniversalDriverId,
      reported_injuries: state.driverInjuryType === InjuryTypes.INJURED ? {
        ...state.driverInjuryCategories,
      } : null,
    };
  };

  const formatFilerPhoneNumber = (phone) => {
    if (phone) {
      const justDigits = phone.replace(/[^\d]/g, '');
      if (justDigits.length !== 10) {
        return '';
      }
      return `${justDigits.substring(0, 3)}-${justDigits.substring(3, 6)}-${justDigits.substring(6, 10)}`;
    } else {
      return '';
    }
  };

  const filer = () => {
    return {
      filer: true,
      first_name: state.filerFirstName,
      last_name: state.filerLastName,
      email: state.filerEmail,
      phone: formatFilerPhoneNumber(state.filerPhoneNumber),
      street1: state.filerAddressStreet1,
      street2: state.filerAddressStreet2,
      city: state.filerAddressCity,
      state: state.filerAddressState,
      zip: state.filerAddressZip,
      party_type: state.filerPartyType,
    };
  };

  const occupants = () => {
    return state.driverType === DriverTypes.SOMEONE_ELSE || state.driverType === DriverTypes.POLICY_DRIVER ? [subject(), driver()] : [subject()];
  };

  const nonOccupants = () => {
    const involvedParties = [];

    state.nonOccupants.forEach((ip) => {
      involvedParties.push({
        filer: false,
        not_on_policy: true,
        first_name: ip.firstName,
        last_name: ip.lastName,
        primary_phone: ip.phoneNumber?.replace(/[^\d]/g, ''),
        injury_type: ip.injuryType,
        reported_injuries: ip.injuryType === InjuryTypes.INJURED ? ip.injuryCategories : null,
      });
    });

    if (!state.subjectOwnsVehicle) {
      involvedParties.push({
        filer: false,
        not_on_policy: true,
        first_name: state.ownerFirstName,
        last_name: state.ownerLastName,
        primary_phone: state.ownerPhoneNumber,
      });
    }

    return involvedParties;
  };

  const associatedParties = () => {
    if (state.caller?.callerType === CallerTypes.THIRD_PARTY && !LinkedAssociatedPartyTypes.includes(state.filerPartyType)) {
      return [filer()];
    } else {
      return [];
    }
  };

  const incidentDatetime = () => {
    if (state.incidentDate && state.incidentTime) {
      const dateTimeString = `${state.incidentDate.toISOString().split('T')[0]}T${state.incidentTime.toISOString().split('T')[1]}`;
      return new Date(dateTimeString);
    }
  };

  const _dispatchAddNonOccupant = () => {
    AnalyticsService.trackClickEvent('FNOL_WEB_INVOLVEDPARTIESFORM_ADDPERSON');
    dispatch({
      type: actionTypes.ADD_NON_OCCUPANT,
    });
  };

  const _dispatchRemoveNonOccupant = (key) => {
    AnalyticsService.trackClickEvent('FNOL_WEB_INVOLVEDPARTIESFORM_REMOVEPERSON');
    dispatch({
      type: actionTypes.REMOVE_NON_OCCUPANT,
      key,
    });
  };

  const _dispatchUpdateInvolvedParty = (key, field, value) => {
    dispatch({
      type: actionTypes.UPDATE_NON_OCCUPANT,
      key,
      field,
      value,
    });
  };

  const _submissionFailure = (data) => {
    setSubmitting(false);
    const body = data.data;

    AnalyticsService.trackViewEvent('FNOL_WEB_CLAIMFAILED', {
      data: body,
    });
    progressFnolForward('claim-failed', dispatch, state);
    history.push('/portal/fnol/failed');
  };

  const _submissionSuccess = (data) => {
    if (!data?.data?.success) {
      return _submissionFailure(data);
    }

    progressFnolForward('claim-received', dispatch, state);
    history.push('/portal/fnol/received');
  };

  const _handleClaimSubmission = async () => {
    AnalyticsService.trackClickEvent('FNOL_WEB_INVOLVEDPARTIESFORM_SUBMITCLAIM');
    if (!Validator.isValid(state)) {
      return setShowError(true);
    }

    const formData = {
      policy_number: state.policyForm.policyNumber,
      phone_number: state.policyForm.phoneNumber,
      last_name: state.policyForm.lastName,

      incident_type: state.incidentType,
      incident_datetime: incidentDatetime(),
      incident_reported_timezone: moment.tz.guess(),
      incident_location_city: state.incidentLocationCity,
      incident_location_state: state.incidentLocationState,
      additional_incident_info: state.additionalIncidentInfo,
      photos: state.documents,
      vehicles: [
        {
          not_on_policy: state.vehicleSelection === 'different_vehicle' || state.vehicleSelection === undefined,
          year: state.vehicleYear,
          make: state.vehicleMake,
          model: state.vehicleModel,
          vin: state.vehicleVin,
          mileage_range: state.vehicleMileageRange,
          airbags_deployed: state.vehicleAirbagsDeployed,
          fluids_leaking: state.vehicleLeakingFluids,
          point_of_impact: PointOfImpactTypes[state.vehiclePointOfImpact],
          safe_to_drive: state.vehicleIsSafelyDriveable,
          occupants: occupants(),
        },
      ],
      non_occupants: nonOccupants(),
      associated_parties: associatedParties(),
    };

    if (state.vehicleIsSafelyDriveable === false) {
      formData.vehicles[0].permission_to_move_granted = state.rootHasPermissionToMove;
      formData.vehicles[0].location_address1 = state.vehicleAddressStreet1;
      formData.vehicles[0].location_address2 = state.vehicleAddressStreet2;
      formData.vehicles[0].location_city = state.vehicleAddressCity;
      formData.vehicles[0].location_state = state.vehicleAddressState;
      formData.vehicles[0].location_zip = state.vehicleAddressZip;
    }

    if (state.token?.length !== 0) {
      formData.t = state.token;
    }

    setSubmitting(true);

    let captchaToken;
    if (environment.claimsWebGoogleRecaptchaEnabled) {
      captchaToken = await CaptchaService.challenge('First_Notice_Of_Loss');
    }

    const result = await submitFnol({
      ...formData,
      fnol: {
        captchaToken,
      },
    });
    if (result.isSuccess()) {
      _submissionSuccess(result);
    } else {
      _submissionFailure(result);
    }
  };

  const _updateInvolvedParty = (key) => {
    return (field, value) => {
      const newState = {
        ...state,
        [field]: value,
      };

      if (showError && Validator.isValid(newState)) {
        setShowError(false);
      }

      _dispatchUpdateInvolvedParty(key, field, value);
    };
  };

  const _otherDriver = () => {
    if (state.driverType === DriverTypes.SOMEONE_ELSE) {
      if (state.policy?.number) {
        return <div css={styles.listItem}>Driver: {state.driverFirstName} {state.driverLastName}</div>;
      } else {
        return <div css={styles.listItem}>{state.driverFirstName} {state.driverLastName}</div>;
      }
    }
  };

  const _otherOwner = () => {
    if (state.subjectOwnsVehicle === false) {
      if (state.policy?.number) {
        return <div css={styles.listItem}>Vehicle owner: {state.ownerFirstName} {state.ownerLastName}</div>;
      } else {
        return <div css={styles.listItem}>{state.ownerFirstName} {state.ownerLastName}</div>;
      }
    }
  };

  const _generateInvolvedParties = () => {
    if (state.policy?.number) {
      if (state.caller.callerType === CallerTypes.THIRD_PARTY) {
        return (
          <div css={styles.list}>
            <div css={styles.listItem}>Caller: {state.filerFirstName} {state.filerLastName}</div>
            <div css={styles.listItem}>On behalf of: {state.subjectFirstName} {state.subjectLastName}</div>
            <div css={styles.listItem}>The Root Policyholder: {state.policyForm.firstName} {state.policyForm.lastName}</div>
            {_otherDriver()}
            {_otherOwner()}
          </div>
        );
      } else {
        return (
          <div css={styles.list}>
            <div css={styles.listItem}>Caller: {state.subjectFirstName} {state.subjectLastName}</div>
            <div css={styles.listItem}>The Root Policyholder: {state.policyForm.firstName} {state.policyForm.lastName}</div>
            {_otherDriver()}
            {_otherOwner()}
          </div>
        );
      }
    } else {
      return (
        <div css={styles.list}>
          <div css={styles.listItem}>You</div>
          <div css={styles.listItem}>The Root Policyholder</div>
          {_otherDriver()}
          {_otherOwner()}
        </div>
      );
    }
  };

  return (
    <div>
      <h1 css={FnolStyles.primaryHeader}>File a Claim: Step 3</h1>
      <p css={FnolStyles.subtext}>If there are additional people involved in this accident please list them below.</p>

      <h5 css={FnolStyles.promptAfterHeader}>{'Here\'s who we have so far:'}</h5>

      {_generateInvolvedParties()}

      {
        state.nonOccupants.sort((a, b) => a.order - b.order).map(
          (ip) => (
            <InvolvedParty
              inputKey={ip.key}
              key={ip.key}
              party={ip}
              removeParty={_dispatchRemoveNonOccupant}
              showError={showError}
              updateInvolvedParty={_updateInvolvedParty(ip.key)}
            />
          ),
        )
      }

      <Button
        onClick={_dispatchAddNonOccupant}
        size={BUTTON_SIZE_SMALL}
        variant={BUTTON_VARIANT_SECONDARY}
      >
        Add another person
      </Button>

      <p css={styles.bottomText}>
        Any person who, with intent to defraud or knowing that he/she is facilitating a fraud against an insurer, submits an application or files a claim containing false or deceptive statement is guilty of insurance fraud.
      </p>

      <LoaderButton
        isLoading={submitting}
        loadingText={'Submitting'}
        onClick={_handleClaimSubmission}
      >
        Submit Claim
      </LoaderButton>
    </div>
  );
}

const styles = StyleSheet.create({
  list: {
    marginTop: 20,
  },
  listItem: {
    color: '#000',
    fontSize: 18,
    lineHeight: '22px',
  },
  bottomText: {
    color: '#000',
    marginBottom: 40,
    marginTop: 20,
  },
  button: {
    ...Theme.button(),
    backgroundColor: Colors.white(),
    border: `1px solid ${Colors.gray30()}`,
    height: 44,
    minHeight: 44,
    ...Theme.roundedCorners(),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
});
