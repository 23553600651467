import AnalyticsService from '@root/core/src/services/analytics-service';
import ClaimsPortalApi from '@root/claims.joinroot.com/src/api/claims-portal-api';
import LoaderButton from '@root/core/src/components/loader-button';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import SnolStyles from '@root/claims.joinroot.com/src/assets/snol-styles.js';
import StarRating from '@root/claims.joinroot.com/src/components/snol/star-rating';
import TextArea from '@root/core/src/components/text-area.js';

const maxLength = 280;

export default class FeedbackForm extends React.Component {
  static propTypes = {
    appliesToId: PropTypes.string.isRequired,
    appliesToType: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    submissionDoneHandler: PropTypes.func.isRequired,
    submissionFailedHandler: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
  };

  state = {
    rating: 0,
    feedback: '',
    submitting: false,
  };

  componentDidMount() {
    AnalyticsService.trackViewEvent('CLAIMS_SNOL_FEEDBACK');
    window.scrollTo({
      top: 0,
    });
  }

  _onRatingChange = (rating) => {
    this.setState({
      rating,
    });
  }

  get _formData() {
    return {
      appliesToId: this.props.appliesToId,
      appliesToType: this.props.appliesToType,
      message: this.state.feedback,
      rating: this.state.rating,
      t: this.props.token,
    };
  }

  get _title() {
    return this.props.firstName ? `Thanks, ${this.props.firstName}.` : 'Thanks.';
  }

  get _message() {
    const smartApostrophe = '\u2019';
    return `If you${smartApostrophe}ve got a moment, let us know what you thought of this process and if there${smartApostrophe}s anything we could do better.`;
  }

  _submissionSuccess = () => {
    AnalyticsService.trackEvent('CLAIMS_SNOL_FEEDBACK_SUBMIT_SUCCESS');
    this.props.submissionDoneHandler();
  }

  _submissionFailed = () => {
    AnalyticsService.trackEvent('CLAIMS_SNOL_FEEDBACK_SUBMIT_ERROR');
    this.props.submissionFailedHandler();
  }

  _submitFeedback = (event) => {
    event.preventDefault();
    if (this.state.submitting) { return; }
    AnalyticsService.trackClickEvent('CLAIMS_SNOL_FEEDBACK_SUBMIT');
    this.setState({
      submitting: true,
    }, () => ClaimsPortalApi.postToPortal('/web_api/claims/portal/feedback', this._formData, this._submissionSuccess, this._submissionFailed));
  }

  _feedbackChange = (value) => {
    this.setState({
      feedback: value.slice(0, maxLength),
    });
  }

  render() {
    return (
      <>
        <h1 css={SnolStyles.primaryHeader}>{this._title}</h1>
        <p css={SnolStyles.subtext}>{this._message}</p>
        <form
          noValidate
          className={'feedback-form'}
          data-testid={'feedback-form'}
          onSubmit={this._submitFeedback}
        >

          <StarRating
            onRatingChange={this._onRatingChange}
          />
          <TextArea
            maxLength={maxLength}
            onChange={this._feedbackChange}
            placeholder={'Any extra feedback? Tell us how we did.'}
            value={this.state.feedback}
          />
          <LoaderButton
            disabled={this.state.rating === 0}
            isLoading={this.state.submitting}
            loadingText={'Thanks!'}
            onClick={this.onSubmit}
            styles={SnolStyles.continueButtonMargin}
          >
            Send Feedback
          </LoaderButton>
        </form>
      </>
    );
  }
}
