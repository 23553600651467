import Input from '@root/core/src/components/input';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';

export default function NameQuestion(props) {
  const {
    currentFirstName,
    currentLastName,
    headerStyle,
    firstNameErrorLabel,
    inputStyle,
    lastNameErrorLabel,
    name,
    onFirstNameFieldEvent,
    onLastNameFieldEvent,
  } = props;

  return (
    <fieldset>
      <h4 css={headerStyle}>What is your name?</h4>
      <Input
        errorLabel={firstNameErrorLabel}
        id={`${name}FirstName-input`}
        label={'First name'}
        name={`${name}_first_name`}
        onBlur={onFirstNameFieldEvent}
        onChange={onFirstNameFieldEvent}
        value={currentFirstName || ''}
      />
      <Input
        errorLabel={lastNameErrorLabel}
        id={`${name}LastName-input`}
        label={'Last name'}
        name={`${name}_last_name`}
        onBlur={onLastNameFieldEvent}
        onChange={onLastNameFieldEvent}
        value={currentLastName || ''}
        wrapperStyle={inputStyle}
      />
    </fieldset>
  );
}

NameQuestion.propTypes = {
  currentFirstName: PropTypes.string,
  currentLastName: PropTypes.string,
  firstNameErrorLabel: PropTypes.string,
  headerStyle: PropTypes.object,
  inputStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  lastNameErrorLabel: PropTypes.string,
  name: PropTypes.string.isRequired,
  onFirstNameFieldEvent: PropTypes.func.isRequired,
  onLastNameFieldEvent: PropTypes.func.isRequired,
};
