import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default StyleSheet.create({
  primaryHeader: {
    color: Colors.black(),
    fontSize: '42px',
    fontWeight: '600',
    lineHeight: '100%',
    marginBottom: '20px',
  },
  secondaryHeader: {
    color: Colors.black(),
    fontSize: '28px',
    fontWeight: '600',
    lineHeight: '110%',
    marginTop: '40px',
    marginBottom: '12px',
  },
  tertiaryHeader: {
    color: Colors.black(),
    fontSize: '25px',
    fontWeight: '600',
    lineHeight: '110%',
    marginTop: '40px',
    marginBottom: '12px',
  },
  subtext: {
    color: Colors.gray60(),
    fontSize: '18px',
    lineHeight: '130%',
    marginBottom: '12px',
  },
  injuryQuestion: {
    color: Colors.nearBlack(),
    fontSize: '22px',
    fontWeight: '400',
    lineHeight: '130%',
    marginBottom: '12px',
  },
  injurySubtext: {
    color: Colors.gray60(),
    fontSize: '16px',
    lineHeight: '120%',
    marginBottom: '12px',
  },
  continueButtonMargin: {
    marginTop: '40px',
  },
});
