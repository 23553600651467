import PropTypes from '@root/vendor/prop-types';
import SnolPortal from '@root/claims.joinroot.com/src/components/snol/snol-portal';
import getSnolConfiguration from '@root/claims.joinroot.com/src/api/snol-requests/get-snol-configuration';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import { useEffect, useState } from '@root/vendor/react';

ConfiguredSnolPortal.propTypes = {
  token: PropTypes.string.isRequired,
};

export default function ConfiguredSnolPortal(props) {
  const [ready, setReady] = useState(false);
  const [config, setConfig] = useState({});
  const [getSnol] = useImperativeNetworkRequest(getSnolConfiguration);

  useEffect(() => {
    async function fetchSnol() {
      const result = await getSnol(props.token);
      setConfig(result.data);
      setReady(true);
    }
    fetchSnol();
  }, [getSnol, props.token]);

  if (ready) {
    return (
      <SnolPortal
        token={props.token}
        {...config}
      />
    );
  }
  return null;
}
