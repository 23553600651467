import AnalyticsService from '@root/core/src/services/analytics-service';
import FnolStyles from '@root/claims.joinroot.com/src/assets/fnol-styles.js';
import Input from '@root/core/src/components/input';
import LoaderButton from '@root/core/src/components/loader-button';
import PhoneNumberInput from '@root/core/src/components/phone-number-input';
import React, { useContext, useEffect, useState } from '@root/vendor/react';
import Validator from '@root/claims.joinroot.com/src/utils/fnol/find-policy-validator';
import policySearchConfiguration from '@root/claims.joinroot.com/src/api/fnol-requests/policy-search-configuration';
import useSafeImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import { ALTERNATE_PHONE_NUMBER_MASK } from '@root/core/src/models/phone';
import { FnolContext, FnolDispatchContext, actionTypes } from '@root/claims.joinroot.com/src/contexts/fnol-context-provider';
import { progressFnolForward } from '@root/claims.joinroot.com/src/utils/fnol/progress-helper';
import { useHistory } from '@root/vendor/react-router-dom';

export default function FindPolicyForm() {
  const progressName = 'found-policy';
  const fnolState = useContext(FnolContext);
  const fnolDispatch = useContext(FnolDispatchContext);
  const history = useHistory();
  const [viewedErrors, setViewedErrors] = useState(false);
  const [policySearch] = useSafeImperativeNetworkRequest(policySearchConfiguration);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    AnalyticsService.trackViewEvent('FNOL_WEB_FINDPOLICYFORM');
  }, []);

  const _dispatchPolicyForm = (policyForm) => {
    fnolDispatch({
      type: actionTypes.SET_POLICY_FORM_FOR_FNOL,
      policyForm,
    });
  };

  const _errorLabel = (key) => {
    if (!showError) { return; }

    if (fnolState.policyForm.errors[key]) {
      return fnolState.policyForm.errors[key];
    }
  };

  const _handleFieldEvent = (key, trackingEvent) => {
    return (value) => {
      if (trackingEvent) {
        AnalyticsService.trackEvent(trackingEvent);
      }
      _dispatchPolicyForm(Validator.validateNewState(key, value, fnolState.policyForm));
    };
  };

  const _formData = () => {
    return {
      policy_number: fnolState.policyForm.policyNumber,
      phone_number: fnolState.policyForm.phoneNumber.replace(/\D/g, ''),
      last_name: fnolState.policyForm.lastName,
    };
  };

  const _renderErrorMessages = () => {
    if (fnolState.policyForm.errors.notFound === undefined) { return; }

    return (
      <div className={'errors'}>
        <p>We couldn’t find a policy related to this information. Try again or give us a call.</p>
      </div>
    );
  };

  const _submitErrorHandler = () => {
    AnalyticsService.trackViewEvent('FNOL_WEB_FINDPOLICYFORM_ERRORSTATE');
    setViewedErrors(true);
    _dispatchPolicyForm({
      ...fnolState.policyForm,
      submitting: false,
      errors: {
        ...fnolState.policyForm.errors,
        notFound: ' ',
      },
    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const _submitPolicyQuery = async () => {
    AnalyticsService.trackClickEvent('FNOL_WEB_FINDPOLICYFORM_NEXTSTEP');
    if (viewedErrors) {
      AnalyticsService.trackEvent('FNOL_WEB_FINDPOLICYFORM_ERRORSTATEVIEW', 'SUBMIT');
    }

    if (Validator.anyErrors(fnolState.policyForm)) {
      setShowError(true);

      _submitErrorHandler();
    } else {
      _dispatchPolicyForm({
        ...fnolState.policyForm,
        submitting: true,
      });
      const result = await policySearch(_formData());
      if (result.isSuccess()) {
        _submitSuccessHandler(result);
      } else {
        _submitErrorHandler(result);
      }
    }
  };

  const _submitSuccessHandler = (response) => {
    if (response.data.success) {
      _dispatchPolicyForm({
        ...fnolState.policyForm,
        submitting: false,
      });
      progressFnolForward(progressName, fnolDispatch, fnolState);
      history.push('/portal/fnol/step2');
    } else {
      _submitErrorHandler();
    }
  };

  return (
    <div>
      <h1 css={FnolStyles.primaryHeader}>File a Claim: Step 1</h1>
      <h2 css={FnolStyles.secondaryHeaderText}>Root Policyholder</h2>
      <p css={FnolStyles.subtext}>Please give us any information about the Root insured involved in this accident.</p>

      {_renderErrorMessages()}

      <fieldset>
        <h4 css={FnolStyles.prompt}>If you have their Root policy number please fill it in below.</h4>
        <Input
          autoFocus={true}
          label={'Root policy number'}
          name={'policy_number'}
          onBlur={_handleFieldEvent('policyNumber')}
          onChange={_handleFieldEvent('policyNumber', 'FNOL_WEB_FINDPOLICYFORM_ROOTPOLICYNUMBER_EDIT')}
          value={fnolState.policyForm.policyNumber}
        />
      </fieldset>
      <fieldset>
        <h4 css={FnolStyles.prompt}>If you have their phone number, please enter it below.</h4>
        <PhoneNumberInput
          autoFocus={false}
          errorLabel={_errorLabel('phoneNumber')}
          inputMask={ALTERNATE_PHONE_NUMBER_MASK}
          label={'999-999-9999'}
          onChange={_handleFieldEvent('phoneNumber', 'FNOL_WEB_FINDPOLICYFORM_PHONENUMBER_EDIT')}
          value={fnolState.policyForm.phoneNumber}
        />
      </fieldset>
      <fieldset>
        <h4 css={FnolStyles.prompt}>What is the name of the other driver involved?</h4>
        <Input
          label={'First name'}
          name={'first_name'}
          onBlur={_handleFieldEvent('firstName')}
          onChange={_handleFieldEvent('firstName', 'FNOL_WEB_FINDPOLICYFORM_FIRSTNAME_EDIT')}
          value={fnolState.policyForm.firstName}
          wrapperStyle={FnolStyles.inputMargin}
        />
        <Input
          label={'Last name'}
          name={'last_name'}
          onBlur={_handleFieldEvent('lastName')}
          onChange={_handleFieldEvent('lastName', 'FNOL_WEB_FINDPOLICYFORM_LASTNAME_EDIT')}
          value={fnolState.policyForm.lastName}
        />
      </fieldset>
      <LoaderButton
        isLoading={fnolState.policyForm.submitting}
        loadingText={'Submitting'}
        onClick={_submitPolicyQuery}
        styles={FnolStyles.continueButtonMargin}
      >
        Next Step
      </LoaderButton>
    </div>
  );
}
