import Datepicker from '@root/core/src/components/datepicker';
import FnolStyles from '@root/claims.joinroot.com/src/assets/fnol-styles.js';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import RootDatepicker from '@root/claims.joinroot.com/src/components/core/root-datepicker';

export default function IncidentDatetimeQuestion(props) {
  const {
    currentIncidentDate,
    currentIncidentTime,
    onDateFieldEvent,
    onTimeFieldEvent,
    showErrorFields,
  } = props;

  return (
    <fieldset>
      <h4 css={FnolStyles.prompt}>When did the incident happen?</h4>
      <Datepicker
        dateLimit={new Date()}
        disablePastDays={false}
        inputId={'incident_date'}
        isError={showErrorFields && !currentIncidentDate}
        label={'Date'}
        onDateChange={onDateFieldEvent}
        selectedDay={currentIncidentDate || null}
      />

      <RootDatepicker
        showTimeSelect
        showTimeSelectOnly
        dateFormat={'h:mm aa'}
        error={showErrorFields && !currentIncidentTime}
        label={'Time'}
        name={'incident_time'}
        onChange={onTimeFieldEvent}
        placeholderText={'Select a time'}
        selected={currentIncidentTime || ''}
        timeCaption={'Time'}
        timeIntervals={5}
      />
    </fieldset>
  );
}

IncidentDatetimeQuestion.propTypes = {
  currentIncidentDate: PropTypes.instanceOf(Date),
  currentIncidentTime: PropTypes.instanceOf(Date),
  onDateFieldEvent: PropTypes.func.isRequired,
  onTimeFieldEvent: PropTypes.func.isRequired,
  showErrorFields: PropTypes.bool.isRequired,
};
