import DriverQuestion from '@root/claims.joinroot.com/src/components/fnol/questions/driver-question';
import FatalityQuestion from '@root/claims.joinroot.com/src/components/fnol/questions/fatality-question';
import FnolStyles from '@root/claims.joinroot.com/src/assets/fnol-styles.js';
import InjuryCategoriesQuestion from '@root/claims.joinroot.com/src/components/fnol/questions/injury-categories-question';
import InjuryQuestion from '@root/claims.joinroot.com/src/components/fnol/questions/injury-question';
import NameAndPhoneNumberQuestion from '@root/claims.joinroot.com/src/components/fnol/questions/name-and-phone-number-question';
import PropTypes from '@root/vendor/prop-types';
import React, { useContext } from '@root/vendor/react';
import Validator from '@root/claims.joinroot.com/src/utils/fnol/describe-claim-validator';
import { DriverTypes, InjuryTypes, ThirdPartyInjuryCategories } from '@root/claims.joinroot.com/src/models/claims';
import { FnolContext, FnolDispatchContext, actionTypes } from '@root/claims.joinroot.com/src/contexts/fnol-context-provider';

export default function DriverInformation({ showError }) {
  const state = useContext(FnolContext);
  const dispatch = useContext(FnolDispatchContext);

  const _determine_field_value = (key, value) => {
    const injuryTypeIsNotDeceased = key === 'driverInjuryType' && value?.target?.value === '';

    if (injuryTypeIsNotDeceased) {
      return value?.target?.value;
    } else {
      return value?.target?.value || value;
    }
  };

  const _handleFieldEvent = (key) => {
    return (value) => {
      value = _determine_field_value(key, value);
      const validatedForm = Validator.validateNewState(key, value, state);

      _dispatchForm(validatedForm);
    };
  };

  const _handlePolicyDriverEvent = (driver) => {
    return () => {
      const newState = {};
      newState.driverType = DriverTypes.POLICY_DRIVER;
      newState.driverFirstName = driver.firstName;
      newState.driverLastName = driver.lastName;
      newState.driverPhoneNumber = driver.phoneNumber;
      newState.driverUniversalDriverId = driver.universalDriverId;

      _dispatchForm(newState);
    };
  };

  const _dispatchForm = (form) => {
    dispatch({
      type: actionTypes.SET_CLAIM_FORM_FOR_FNOL,
      form,
    });
  };

  const _errorLabel = (key, requiredMsg) => {
    if (!showError.fields) { return; }

    if (state.errors[key]) {
      return state.errors[key];
    }

    if (requiredMsg && !state[key]) {
      return requiredMsg;
    }
  };

  const _atLeastOneOptionIsSelected = (options) => {
    return Object.values(options).some((value) => value);
  };

  const _driverInjuryTypeIsInjured = state.driverInjuryType === InjuryTypes.INJURED;

  const _driverFullName = [state.driverFirstName, state.driverLastName].filter((n) => n).join(' ');

  const _driverNotDeceased = state.driverInjuryType === '' || state.driverInjuryType && state.driverInjuryType !== InjuryTypes.DECEASED;

  const _renderOtherDriver = () => {
    if (state.driverType === DriverTypes.SOMEONE_ELSE || state.driverType === DriverTypes.POLICY_DRIVER) {
      return (
        <div>
          <NameAndPhoneNumberQuestion
            currentFirstName={state.driverFirstName}
            currentLastName={state.driverLastName}
            currentPhoneNumber={state.driverPhoneNumber}
            errorLabelFirstName={_errorLabel('driverFirstName', 'required')}
            errorLabelLastName={_errorLabel('driverLastName', 'required')}
            errorLabelPhoneNumber={_driverNotDeceased ? _errorLabel('driverPhoneNumber', 'Please add the driver\'s phone number') : null}
            inputKey={'driver'}
            inputStyle={FnolStyles.inputMargin}
            labelPhoneNumber={'999-999-9999'}
            onFirstNameFieldEvent={_handleFieldEvent('driverFirstName')}
            onLastNameFieldEvent={_handleFieldEvent('driverLastName')}
            onPhoneNumberFieldEvent={_handleFieldEvent('driverPhoneNumber')}
            questionText={'Please enter the driver\'s name and phone number.'}
          />
          <FatalityQuestion
            currentInjuryType={state.driverInjuryType}
            currentName={_driverFullName}
            inputKey={'driver'}
            isError={showError.fields && state.driverInjuryType !== '' && !state.driverInjuryType}
            onFieldEvent={_handleFieldEvent('driverInjuryType')}
          />
          {_driverNotDeceased &&
            <InjuryQuestion
              currentInjuryType={state.driverInjuryType}
              currentName={_driverFullName}
              inputKey={'driver'}
              isError={showError.fields && !state.driverInjuryType}
              onFieldEvent={_handleFieldEvent('driverInjuryType')}
            />
          }
          {_driverNotDeceased && _driverInjuryTypeIsInjured &&
            <InjuryCategoriesQuestion
              currentName={_driverFullName}
              currentSelectedInjuryCategories={state.driverInjuryCategories}
              injuryCategories={ThirdPartyInjuryCategories}
              inputKey={'driver'}
              isError={showError.fields && !_atLeastOneOptionIsSelected(state.driverInjuryCategories)}
              onFieldEvent={_handleFieldEvent('driverInjuryCategories')}
            />
          }
        </div>
      );
    }
  };

  return (
    <>
      <div>
        <h4 css={FnolStyles.secondaryHeader}>Driver Information</h4>
        <DriverQuestion
          callerUniversalDriverId={state.caller?.driver?.universalDriverId}
          currentDriverType={state.driverType}
          currentDriverUniversalDriverId={state.driverUniversalDriverId}
          isError={showError.fields && !state.driverType}
          onDriverTypeFieldEvent={_handleFieldEvent('driverType')}
          onPolicyDriverFieldEvent={_handlePolicyDriverEvent}
          policyDrivers={state.policy?.drivers}
        />
        {_renderOtherDriver()}
      </div>
    </>
  );
}

DriverInformation.propTypes = {
  showError: PropTypes.shape({
    fields: PropTypes.bool,
  }),
};
