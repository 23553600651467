import Input from '@root/core/src/components/input';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';

export default function InputField({
  defaultValue,
  handleChange,
  label,
  name,
}) {
  return (
    <Input
      defaultValue={defaultValue}
      id={`${name}-inputField`}
      label={label}
      name={name}
      onChange={handleChange}
    />
  );
}

InputField.propTypes = {
  defaultValue: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

