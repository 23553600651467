import Adjuster from '@root/claims.joinroot.com/src/svg/adjuster';
import AnalyticsService from '@root/core/src/services/analytics-service';
import Email from '@root/claims.joinroot.com/src/svg/email';
import FnolStyles from '@root/claims.joinroot.com/src/assets/fnol-styles';
import React, { useContext, useEffect } from '@root/vendor/react';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { FnolDispatchContext, actionTypes } from '@root/claims.joinroot.com/src/contexts/fnol-context-provider';
import { progressFnolSet } from '@root/claims.joinroot.com/src/utils/fnol/progress-helper';

export default function ClaimReceived() {
  const fnolDispatch = useContext(FnolDispatchContext);

  useEffect(() => {
    progressFnolSet(['claim-received'], fnolDispatch);
    fnolDispatch({
      type: actionTypes.CLEAR_AND_KEEP_PROGRESS_FOR_FNOL,
      undefined,
    });
    AnalyticsService.trackViewEvent('FNOL_WEB_CLAIMRECEIVED');
  }, [fnolDispatch]);

  return (
    <div>
      <h1 css={FnolStyles.primaryHeader}>{'We\'ve received your claim.'}</h1>
      <div css={[styles.subTitle, styles.borderBottom]}>{'Here\'s what to expect next:'}</div>

      <div css={[styles.displayGroup, styles.borderBottom]}>
        <div>
          <Email />
        </div>

        <div css={styles.displayGroupText}>
          <h6>{'You\'ll receive an email.'}</h6>
          <div css={styles.displayGroupBody}>
            The email includes a confirmation of your claim submission, your claim number, and your claims expert’s contact information.
          </div>
        </div>
      </div>

      <div css={[styles.displayGroup, styles.borderBottom]}>
        <div>
          <Adjuster />
        </div>

        <div css={styles.displayGroupText}>
          <h6>Your claims expert will be in touch.</h6>
          <div css={styles.displayGroupBody}>
            Your claims expert will reach out to you directly to update you on your claim.
          </div>
        </div>
      </div>

      <div css={styles.bottomText}>
        Please note your claim expert works during the business hours listed below. You can contact your claims expert by replying to the confirmation email.
      </div>

      <h6 css={styles.bottomText}>Claims business hours:</h6>
      <div css={styles.bottomText}>
        Monday - Friday
        <br />
        9 a.m. - 4 p.m. ET
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  borderBottom: {
    borderWidth: 0,
    borderBottom: 1,
    borderColor: Colors.gray50(),
    borderStyle: 'solid',
    marginBottom: 30,
    paddingBottom: 30,
  },
  subTitle: {
    fontSize: 24,
  },
  displayGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
  displayGroupText: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  displayGroupBody: {
    fontSize: 20,
    lineHeight: '24px',
  },
  bottomText: {
    color: Colors.gray60(),
    fontSize: 20,
    lineHeight: '24px',
  },
});
