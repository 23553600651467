import Link from '@root/core/src/components/link';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function LinkButton({ text }) {
  return (
    <div css={styles.link}>
      <Link onClick={() => {}}>
        {text}
      </Link>
    </div>
  );
}

LinkButton.propTypes = {
  text: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  link: {
    marginTop: '38px',
    marginBottom: '18px',
    width: '100%',
    textAlign: 'center',
  },
});

