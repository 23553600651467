import CloudUpload from '@root/claims.joinroot.com/src/svg/cloud-upload';
import Dropzone from 'dropzone';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import addCircle from '@root/claims.joinroot.com/src/svg/add-circle.svg';
import replace from '@root/vendor/lodash/replace';
import { isMobileBrowser } from '@root/core/src/utils/detect-mobile-browser';

export default class DocumentUpload extends React.Component {
  static propTypes = {
    acceptedFiles: PropTypes.string,
    documents: PropTypes.array.isRequired,
    documentUploadAuthorization: PropTypes.object.isRequired,
    documentUploadUrl: PropTypes.string.isRequired,
    dropzoneRef: PropTypes.any,
    initDropzone: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onUploadComplete: PropTypes.func.isRequired,
    onUploadStart: PropTypes.func.isRequired,
  };

  static defaultProps = {
    initDropzone: (node, options) => new Dropzone(node, options),
  };

  constructor(props) {
    super(props);
    this.dropzoneRef = this.props.dropzoneRef || React.createRef();
    this.dropzoneHintNode = React.createRef();
  }

  componentDidMount() {
    this.dropzone = this.props.initDropzone(this.dropzoneRef.current, {
      url: this.props.documentUploadUrl,
      params: this.props.documentUploadAuthorization,
      hiddenInputContainer: this.dropzoneRef.current,
      clickable: [this.dropzoneHintNode.current, this.dropzoneRef.current],
      renameFile: this._renameFile,
    });
    this.dropzone.on('success', (_, response) => {
      const location = new DOMParser().parseFromString(response, 'application/xml').getElementsByTagName('Location')[0].firstChild.data.trim().replace(/%2F/g, '/');
      this.props.onChange([...this.props.documents, location]);
    });
    this.dropzone.on('addedfile', () => {
      this.props.onUploadStart();
    });
    this.dropzone.on('queuecomplete', () => {
      this.props.onUploadComplete();
    });
    if (this._doPhotosExist()) { this._addPhotosButton(); }
  }

  componentDidUpdate() {
    if (this._doPhotosExist()) { this._addPhotosButton(); }
  }

  _doPhotosExist() {
    return document.querySelector('.dz-preview');
  }

  _addPhotosButton() {
    const dz = document.querySelector('.document-upload-dropzone');
    let addPhotosBlock = document.querySelector('.add-photos-block');
    if (!addPhotosBlock) {
      addPhotosBlock = document.createElement('div');
      addPhotosBlock.setAttribute('class', 'add-photos-block dz-image-preview dz-preview dz-clickable');
      addPhotosBlock.setAttribute('data-testid', 'AddPhotosBlock#visible');

      const dzImageDiv = document.createElement('div');
      dzImageDiv.setAttribute('class', 'add-photos-box dz-image');
      addPhotosBlock.appendChild(dzImageDiv);

      const icon = document.createElement('img');
      icon.setAttribute('src', addCircle);
      dzImageDiv.appendChild(icon);

      const addPhotoText = document.createElement('div');
      addPhotoText.innerText = 'Add photos';
      dzImageDiv.appendChild(addPhotoText);
      dz.appendChild(addPhotosBlock);
    } else {
      dz.removeChild(addPhotosBlock);
      dz.appendChild(addPhotosBlock);
    }
  }

  _renameFile = (file) => {
    let fileName = replace(file.name, /[^\w.-]+/g, '_');
    const lastIndexOfPeriod = fileName.lastIndexOf('.');
    const fileNameNoExtension = fileName.substring(0, lastIndexOfPeriod) || fileName;
    const extension = lastIndexOfPeriod >= 0 ? fileName.substring(lastIndexOfPeriod) : '';
    const regex = new RegExp(`${fileNameNoExtension}(-[0-9]+)?${extension}$`);
    const exists = (documentFile) => documentFile.match(regex);

    if (this.props.documents && this.props.documents.some(exists)) {
      const similarNameFiles = this.props.documents.filter((documentFile) => {
        return documentFile.match(regex);
      });
      fileName = fileNameNoExtension + '-' + similarNameFiles.length + extension;
    }

    return fileName;
  }

  _photoPrompt() {
    if (isMobileBrowser()) {
      return 'Tap here to upload photos';
    } else {
      return 'Click or drag photos here to upload';
    }
  }

  render() {
    return (
      <div
        className={'document-upload-dropzone'}
        data-testid={'DocumentUpload#dropzone'}
        ref={this.dropzoneRef}
      >
        <div
          className={'empty-dropzone'}
          data-testid={'DocumentUpload#empty'}
          ref={this.dropzoneHintNode}
        >
          <CloudUpload />
          {this._photoPrompt()}
        </div>
      </div>
    );
  }
}
