import FnolStyles from '@root/claims.joinroot.com/src/assets/fnol-styles.js';
import Input, { TextTypes } from '@root/core/src/components/input';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';

export default function EmailQuestion(props) {
  const {
    currentEmail,
    errorLabel,
    onFieldEvent,
    name,
  } = props;

  return (
    <fieldset>
      <h4 css={FnolStyles.prompt}>What is your email address?</h4>
      <Input
        errorLabel={errorLabel}
        inputType={TextTypes.EMAIL}
        label={'Email address'}
        name={`${name}_email`}
        onBlur={onFieldEvent}
        onChange={onFieldEvent}
        value={currentEmail || ''}
      />
    </fieldset>
  );
}

EmailQuestion.propTypes = {
  currentEmail: PropTypes.string,
  errorLabel: PropTypes.string,
  name: PropTypes.string.isRequired,
  onFieldEvent: PropTypes.func.isRequired,
};
