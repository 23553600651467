import Button from '@root/core/src/components/button';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { BUTTON_VARIANT_SECONDARY } from '@root/core/src/components/button/styles';

export default function AltButton({ text }) {
  return (
    <Button variant={BUTTON_VARIANT_SECONDARY}>
      {text}
    </Button>
  );
}

AltButton.propTypes = {
  text: PropTypes.string.isRequired,
};

