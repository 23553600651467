import PropTypes from '@root/vendor/prop-types';
import React, { useReducer } from '@root/vendor/react';
import uuidv4 from '@root/vendor/uuid/v4';
import { FirstPartyInjuryCategories, ThirdPartyInjuryCategories } from '@root/claims.joinroot.com/src/models/claims';

const _emptyFirstPartyInjuryCategories = FirstPartyInjuryCategories.reduce((accumulator, category) => {
  accumulator[category.value] = false;
  return accumulator;
}, {});

const _emptyThirdPartyInjuryCategories = ThirdPartyInjuryCategories.reduce((accumulator, category) => {
  accumulator[category.value] = false;
  return accumulator;
}, {});

const emptyState = {
  progress: [],
  caller: {
    callerType: '',
    subjectInvolvedPartyType: '',
    driver: {},
  },
  documents: [],
  nonOccupants: [],
  policyForm: {
    policyNumber: '',
    phoneNumber: '',
    firstName: '',
    lastName: '',
    notEnoughInputCollected: true,
    submitting: false,
    errors: {},
  },
  subjectInjuryCategories: _emptyFirstPartyInjuryCategories,
  driverInjuryCategories: _emptyThirdPartyInjuryCategories,
  occupants: [],
  vehicles: [],
  photos: [],
  submitting: false,
  policy: {},
  errors: {},
};

export const FnolContext = React.createContext();
export const FnolDispatchContext = React.createContext();

export const actionTypes = {
  ADD_NON_OCCUPANT: 'ADD_NON_OCCUPANT',
  CLEAR_AND_KEEP_PROGRESS_FOR_FNOL: 'CLEAR_AND_KEEP_PROGRESS_FOR_FNOL',
  REMOVE_NON_OCCUPANT: 'REMOVE_NON_OCCUPANT',
  RESET_PHONE_USER_FNOL: 'RESET_PHONE_USER_FNOL',
  SET_CALLER_FOR_FNOL: 'SET_CALLER_FOR_FNOL',
  SET_CLAIM_FORM_FOR_FNOL: 'SET_CLAIM_FORM_FOR_FNOL',
  SET_POLICY_FORM_FOR_FNOL: 'SET_POLICY_FORM_FOR_FNOL',
  SET_POLICYHOLDER_FOR_FNOL: 'SET_POLICYHOLDER_FOR_FNOL',
  SET_PROGRESS_FOR_FNOL: 'SET_PROGRESS_FOR_FNOL',
  SET_REPRESENTED_PARTY_FOR_FNOL: 'SET_REPRESENTED_PARTY_FOR_FNOL',
  SET_ROOT_VEHICLE_FOR_FNOL: 'SET_ROOT_VEHICLE_FOR_FNOL',
  UPDATE_NON_OCCUPANT: 'UPDATE_NON_OCCUPANT',
};

function parseLocalStorage() {
  let cache;

  try {
    cache = JSON.parse(window.localStorage.getItem('fnol')) || {};
  } catch (err) {
    window.localStorage.removeItem('fnol');
    cache = {};
  }

  return {
    ...cache,
    incidentDate: cache.incidentDate === undefined ? undefined : new Date(cache.incidentDate),
    incidentTime: cache.incidentTime === undefined ? undefined : new Date(cache.incidentTime),
  };
}

function saveToLocalStorage(state) {
  window.localStorage.setItem('fnol', JSON.stringify(state));
  return state;
}

const _addValueIfExists = (object, key, value) => {
  value ? object[key] = value : delete object[key];
};

const updateSubjectWithDriver = (newState, driver) => {
  if (driver) {
    _addValueIfExists(newState, 'subjectFirstName', driver.firstName);
    _addValueIfExists(newState, 'subjectLastName', driver.lastName);
    _addValueIfExists(newState, 'subjectPhoneNumber', driver.phoneNumber);
    _addValueIfExists(newState, 'subjectUniversalDriverId', driver.universalDriverId);
    _addValueIfExists(newState, 'subjectEmail', driver.email);
    _addValueIfExists(newState, 'subjectAddressStreet1', driver.address1);
    _addValueIfExists(newState, 'subjectAddressStreet2', driver.address2);
    _addValueIfExists(newState, 'subjectAddressCity', driver.city);
    _addValueIfExists(newState, 'subjectAddressZip', driver.postalCode);
    _addValueIfExists(newState, 'subjectAddressState', driver.state);
  }
};

export function fnolReducer(state, action) {
  let newState = state;

  switch (action.type) {
  case actionTypes.CLEAR_AND_KEEP_PROGRESS_FOR_FNOL: {
    newState = {
      ...emptyState,
      documentUploadAuthorization: state.documentUploadAuthorization,
      documentUploadUrl: state.documentUploadUrl,
      progress: state.progress,
    };
    break;
  }
  case actionTypes.RESET_PHONE_USER_FNOL: {
    newState = {
      ...emptyState,
      documentUploadAuthorization: state.documentUploadAuthorization,
      documentUploadUrl: state.documentUploadUrl,
      policy: state.policy,
      policyForm: state.policyForm,
      progress: state.progress,
      token: state.token,
    };
    break;
  }
  case actionTypes.SET_CALLER_FOR_FNOL: {
    const caller = {
      callerType: action.callerType,
      subjectInvolvedPartyType: action.subjectInvolvedPartyType,
      driver: action.driver,
    };
    updateSubjectWithDriver(newState, caller.driver || {});
    newState = {
      ...state,
      caller,
    };
    break;
  }
  case actionTypes.SET_REPRESENTED_PARTY_FOR_FNOL: {
    const caller = {
      callerType: state.caller.callerType,
      subjectInvolvedPartyType: action.subjectInvolvedPartyType,
      driver: action.driver,
    };
    updateSubjectWithDriver(newState, caller.driver || {});
    newState = {
      ...state,
      caller,
    };
    break;
  }
  case actionTypes.SET_CALLER_FORM_FOR_FNOL: {
    newState = {
      ...state,
      ...action.form,
    };
    break;
  }
  case actionTypes.SET_CLAIM_FORM_FOR_FNOL: {
    newState = {
      ...state,
      ...action.form,
    };
    break;
  }
  case actionTypes.SET_POLICY_FORM_FOR_FNOL: {
    newState = {
      ...state,
      policyForm: action.policyForm,
    };
    break;
  }
  case actionTypes.SET_POLICYHOLDER_FOR_FNOL: {
    newState = {
      ...state,
      isPolicyholder: action.isPolicyholder,
    };
    break;
  }
  case actionTypes.SET_PROGRESS_FOR_FNOL: {
    return {
      ...state,
      progress: action.progress,
    };
  }
  case actionTypes.SET_ROOT_VEHICLE_FOR_FNOL: {
    newState = {
      ...state,
      isRootVehicle: action.isRootVehicle,
    };
    break;
  }
  case actionTypes.ADD_NON_OCCUPANT: {
    const orders = state.nonOccupants.map((no) => no.order);

    newState = {
      ...state,
      nonOccupants: [
        ...state.nonOccupants,
        {
          key: uuidv4(),
          order: Math.max(0, ...orders) + 1,
          injuryCategories: _emptyThirdPartyInjuryCategories,
        },
      ],
    };
    break;
  }
  case actionTypes.REMOVE_NON_OCCUPANT: {
    newState = {
      ...state,
      nonOccupants: state.nonOccupants.filter((no) => no.key !== action.key),
    };
    break;
  }
  case actionTypes.UPDATE_NON_OCCUPANT: {
    const party = state.nonOccupants.find((no) => no.key === action.key);
    const otherParties = state.nonOccupants.filter((no) => no.key !== action.key);

    newState = {
      ...state,
      nonOccupants: [
        ...otherParties,
        {
          ...party,
          [action.field]: action.value,
        },
      ],
    };
    break;
  }
  }
  return saveToLocalStorage(newState);
}

export default function FnolContextProvider({ children, initialFnolState }) {
  const localStorageCache = parseLocalStorage();

  const initialState = {
    ...emptyState,
    ...localStorageCache,
    ...initialFnolState,
  };
  if (!initialFnolState.token && localStorageCache.token) {
    initialState.token = localStorageCache.token;
  }
  const [fnol, dispatch] = useReducer(fnolReducer, initialState);

  saveToLocalStorage(fnol);

  return (
    <FnolContext.Provider value={fnol}>
      <FnolDispatchContext.Provider value={dispatch}>
        {children}
      </FnolDispatchContext.Provider>
    </FnolContext.Provider>
  );
}

FnolContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialFnolState: PropTypes.object,
};

FnolContextProvider.defaultProps = {
  initialFnolState: {},
};
