import AltButton from '@root/claims.joinroot.com/src/components/conversations/prompt-pieces/alt-button';
import Divider from '@root/claims.joinroot.com/src/components/conversations/prompt-pieces/divider';
import InputField from '@root/claims.joinroot.com/src/components/conversations/prompt-pieces/input-field';
import LinkButton from '@root/claims.joinroot.com/src/components/conversations/prompt-pieces/link-button';
import PageTitle from '@root/claims.joinroot.com/src/components/conversations/prompt-pieces/page-title';
import Paragraph from '@root/claims.joinroot.com/src/components/conversations/prompt-pieces/paragraph';
import PrimaryButton from '@root/claims.joinroot.com/src/components/conversations/prompt-pieces/primary-button';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import SectionTitle from '@root/claims.joinroot.com/src/components/conversations/prompt-pieces/page-title';
import Statement from '@root/claims.joinroot.com/src/components/conversations/prompt-pieces/statement';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { ConversationsContext } from '@root/claims.joinroot.com/src/contexts/conversations-context-provider';
import { useContext, useState } from '@root/vendor/react';

export default function PromptScene({ currentPrompt, handleContinue }) {
  const context = useContext(ConversationsContext);
  const [currentResponses, updateCurrentResponses] = useState(_defaultResponses);

  function _defaultResponses() {
    const allItems = currentPrompt.mainPieces.concat(currentPrompt.asidePieces).concat(currentPrompt.footerPieces);
    const inputItems = allItems.filter((item) => {
      return item.inputKey;
    });

    const defaultResponses = inputItems.reduce((defaultState, item) => {
      return {
        ...defaultState,
        [item.inputKey]: _checkForDefault(item.inputKey) || item.default,
      };
    }, {});

    return defaultResponses;
  }

  function _checkForDefault(inputKey) {
    const existingResponse = context.currentResponses.find((currentResponse) => currentResponse.fullKey === currentPrompt.fullKey);

    return existingResponse?.values?.[inputKey];
  }

  const _handleChange = (inputKey) => {
    return (value) => {
      updateCurrentResponses({
        baseKey: currentPrompt.baseKey,
        fullKey: currentPrompt.fullKey,
        values: {
          ...currentResponses,
          [inputKey]: value,
        },
      });
    };
  };

  function _createPageTitle(itemProps, key) {
    return (
      <PageTitle
        key={key}
        subtext={itemProps.subtext}
        text={itemProps.text}
      />
    );
  }

  function _createSectionTitle(itemProps, key) {
    return (
      <SectionTitle
        key={key}
        subtext={itemProps.subtext}
        text={itemProps.text}
      />
    );
  }

  function _createStatement(itemProps, key) {
    return (
      <Statement
        key={key}
        text={itemProps.text}
      />
    );
  }

  function _createParagraph(itemProps, key) {
    return (
      <Paragraph
        key={key}
        text={itemProps.text}
      />
    );
  }

  function _createDivider(key) {
    return (
      <Divider key={key} />
    );
  }

  function _createInputField(itemProps, key) {
    return (
      <InputField
        defaultValue={_checkForDefault(itemProps.inputKey) || itemProps.default}
        handleChange={_handleChange(itemProps.inputKey)}
        key={key}
        label={itemProps.label}
        name={itemProps.inputKey}
      />
    );
  }

  function _createPrimaryButton(itemProps, key) {
    return (
      <PrimaryButton
        key={key}
        onClick={() => handleContinue(currentResponses)}
        text={itemProps.text}
      />
    );
  }

  function _createAltButton(itemProps, key) {
    return (
      <AltButton
        key={key}
        text={itemProps.text}
      />
    );
  }

  function _createLink(itemProps, key) {
    return (
      <LinkButton
        key={key}
        text={itemProps.text}
      />
    );
  }

  function _renderItem(item, key) {
    const { type, ...itemProps } = item;
    switch (type) {
    case 'page_title':
      return _createPageTitle(itemProps, key);
    case 'section_title':
      return _createSectionTitle(itemProps, key);
    case 'statement':
      return _createStatement(itemProps, key);
    case 'paragraph':
      return _createParagraph(itemProps, key);
    case 'divider':
      return _createDivider(key);
    case 'input_field':
      return _createInputField(itemProps, key);
    case 'primary_button':
      return _createPrimaryButton(itemProps, key);
    case 'alt_button':
      return _createAltButton(itemProps, key);
    case 'link_button':
      return _createLink(itemProps, key);
    default:
      return '';
    }
  }
  let asideArea;

  if (currentPrompt.asidePieces.length > 0) {
    asideArea = (
      <div
        css={styles.aside}
        data-testid={'aside-area'}
      >
        {
          currentPrompt.asidePieces.map(_renderItem)
        }
      </div>
    );
  }

  return (
    <>
      <div>
        {
          currentPrompt.mainPieces.map(_renderItem)
        }
      </div>
      {asideArea}
      <div css={styles.footer}>
        {
          currentPrompt.footerPieces.map(_renderItem)
        }
      </div>
    </>
  );
}

PromptScene.propTypes = {
  currentPrompt: PropTypes.object.isRequired,
  handleContinue: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  aside: {
    border: `1px solid ${Colors.gray30()}`,
    borderRadius: '8px',
    padding: '24px',
    margin: '24px 0',
    position: 'initial',
    '@media screen and (min-width: 1280px)': {
      position: 'absolute',
      top: '50%',
      right: '-480px',
      transform: 'translate(0%, -50%)',
    },
  },
  footer: {
    position: 'absolute',
    margin: '0 auto',
    padding: '0 20px',
    width: '100%',
    bottom: '64px',
    left: '0px',
  },
});
