import FnolStyles from '@root/claims.joinroot.com/src/assets/fnol-styles.js';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Select from '@root/core/src/components/select';
import { incidentTypeOptions as defaultIncidentTypeOptions } from '@root/claims.joinroot.com/src/models/picker-options';

export default function IncidentTypeQuestion(props) {
  const {
    currentIncidentType,
    incidentTypeOptions,
    isClaimant,
    onFieldEvent,
  } = props;

  return (
    <fieldset>
      <h4 css={FnolStyles.prompt}>Tell us what happened.</h4>
      <Select
        inputId={'incident_type_options'}
        inputName={'incident_type_options'}
        onChange={onFieldEvent}
        options={incidentTypeOptions(isClaimant)}
        placeholder={'Accident Type'}
        selectedValue={currentIncidentType || ''}
      />
    </fieldset>
  );
}

IncidentTypeQuestion.propTypes = {
  currentIncidentType: PropTypes.string,
  incidentTypeOptions: PropTypes.func.isRequired,
  isClaimant: PropTypes.bool.isRequired,
  onFieldEvent: PropTypes.func.isRequired,
};

IncidentTypeQuestion.defaultProps = {
  incidentTypeOptions: defaultIncidentTypeOptions,
};
