import Regex from '@root/claims.joinroot.com/src/utils/regex';
import Vin from '@root/core/src/models/vin';
import isDate from '@root/vendor/lodash/isDate';
import isEmpty from '@root/vendor/lodash/isEmpty';
import isString from '@root/vendor/lodash/isString';
import { CustomIncidentType, DriverTypes, InjuryTypes } from '@root/claims.joinroot.com/src/models/claims';
import { EMAIL_REGEX, NON_EMPTY_STRING_REGEX } from '@root/core/src/utils/validators';

const requiredDateFields = ['incidentDate', 'incidentTime'];

const requiredFields = (state) => {
  const _requiredFields = [
    'incidentType', 'incidentLocationCity', 'incidentLocationState', 'subjectInjuryType',
    'subjectFirstName', 'subjectLastName', 'subjectPhoneNumber',
    'driverType',
    'subjectOwnsVehicle', 'vehicleYear', 'vehicleMake', 'vehicleModel',
    'vehicleIsSafelyDriveable',
  ];

  if (state.subjectOwnsVehicle === false) {
    _requiredFields.push('ownerFirstName', 'ownerLastName', 'ownerPhoneNumber');
  }

  if (state.driverType === DriverTypes.SOMEONE_ELSE || state.driverType === DriverTypes.POLICY_DRIVER) {
    _requiredFields.push('driverFirstName', 'driverLastName', 'driverInjuryType');

    if (state.driverInjuryType !== InjuryTypes.DECEASED) {
      _requiredFields.push('driverPhoneNumber');
    }
  }

  if (state.incidentType === CustomIncidentType) {
    _requiredFields.push('additionalIncidentInfo');
  }

  if (state.vehicleIsSafelyDriveable === false) {
    _requiredFields.push('vehicleAddressStreet1', 'vehicleAddressCity', 'vehicleAddressState', 'vehicleAddressZip', 'rootHasPermissionToMove');
  }

  if (state.subjectInjuryType === 'INJURED') {
    _requiredFields.push('subjectInjuryCategories');
  }

  if (state.subjectInjuryCategories?.pre_existing_injury) {
    _requiredFields.push('subjectPreExistingConditionsExplanation');
  }

  if (state.driverInjuryType === InjuryTypes.INJURED) {
    _requiredFields.push('driverInjuryCategories');
  }

  return _requiredFields;
};

const validators = {
  subjectAddressZip: {
    test: Regex.validate(Regex.ZIP_CODE),
    message: 'Invalid postal code',
  },
  subjectPhoneNumber: {
    test: Regex.validate(Regex.PHONE_NUMBER),
    message: 'Invalid phone number',
  },
  subjectEmail: {
    test: Regex.validate(EMAIL_REGEX),
    message: 'Invalid email address',
  },
  ownerPhoneNumber: {
    test: Regex.validate(Regex.PHONE_NUMBER),
    message: 'Invalid phone number',
  },
  vehicleVin: {
    test: (vin) => Vin.validate(vin).isValid,
    message: 'Invalid VIN',
  },
};

const isValid = (state) => {
  const isNotEmpty = (field) => isString(state[field]) ? NON_EMPTY_STRING_REGEX.test(state[field]) : ![null, undefined, ''].includes(state[field]);
  const isValidDate = (field) => isDate(state[field]);
  const hasAnOptionSelected = (options) => Object.values(options).some((value) => value);
  const subjectInjuryCategorySelected = state.subjectInjuryType === 'INJURED' ? hasAnOptionSelected(state.subjectInjuryCategories) : true;
  const driverInjuryCategorySelected = state.driverInjuryType === InjuryTypes.INJURED ? hasAnOptionSelected(state.driverInjuryCategories) : true;

  return isEmpty(state.errors) && requiredFields(state).every(isNotEmpty) && requiredDateFields.every(isValidDate) && subjectInjuryCategorySelected && driverInjuryCategorySelected;
};

const validateNewState = (key, value, state) => {
  const validator = validators[key];
  if (!validator) {
    return {
      ...state,
      [key]: value,
    };
  }

  const currentErrors = {
    ...state.errors,
  };
  delete currentErrors[key];

  if (value && !validator.test(value)) {
    currentErrors[key] = validator.message;
  }

  return {
    ...state,
    [key]: value,
    errors: currentErrors,
  };
};

export default {
  isValid,
  validateNewState,
};
