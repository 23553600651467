import Button from '@root/core/src/components/button';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';

export default function PrimaryButton({ text, onClick }) {
  return (
    <Button onClick={onClick}>
      {text}
    </Button>
  );
}

PrimaryButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

