import AnalyticsService from '@root/core/src/services/analytics-service';
import Button from '@root/core/src/components/button';
import FnolStyles from '@root/claims.joinroot.com/src/assets/fnol-styles';
import PropTypes from '@root/vendor/prop-types';
import RadioInputGroup from '@root/core/src/components/radio-input-group';
import RadioOption from '@root/core/src/components/radio-option';
import React, { useContext, useEffect } from '@root/vendor/react';
import { FnolContext, FnolDispatchContext, actionTypes } from '@root/claims.joinroot.com/src/contexts/fnol-context-provider';
import { SubjectInvolvedPartyTypes } from '@root/claims.joinroot.com/src/models/claims';
import { progressFnolForward } from '@root/claims.joinroot.com/src/utils/fnol/progress-helper';
import { useHistory } from '@root/vendor/react-router-dom';

RepresentedPartySelect.propTypes = {
  thirdPartyEnabled: PropTypes.bool,
};

export default function RepresentedPartySelect() {
  const context = useContext(FnolContext);
  const dispatch = useContext(FnolDispatchContext);
  const history = useHistory();

  useEffect(() => {
    AnalyticsService.trackViewEvent('FNOL_WEB_REPRESENTEDPARTYSELECT');
  }, []);

  function _dispatchRepresentedParty(subjectInvolvedPartyType, driver) {
    AnalyticsService.trackClickEvent('FNOL_WEB_REPRESENTEDPARTYSELECT', subjectInvolvedPartyType);
    dispatch({
      type: actionTypes.SET_REPRESENTED_PARTY_FOR_FNOL,
      subjectInvolvedPartyType,
      driver,
    });
  }

  function _handleRepresentedPartySubmission() {
    AnalyticsService.trackClickEvent('FNOL_WEB_REPRESENTEDPARTYSELECT', 'GETSTARTED');
    progressFnolForward('represented-party-select', dispatch, context);
    history.push('/portal/fnol/caller-information');
  }

  const unlistedOption = (
    <RadioOption
      id={'unlisted-represented-party-option'}
      isSelected={context.caller.subjectInvolvedPartyType === SubjectInvolvedPartyTypes.UNLISTED}
      key={'unlisted-party-option'}
      label={'Unlisted driver/passenger'}
      name={SubjectInvolvedPartyTypes.UNLISTED}
      onClick={() => { _dispatchRepresentedParty(SubjectInvolvedPartyTypes.UNLISTED); }}
      optionValue={SubjectInvolvedPartyTypes.UNLISTED}
    />
  );

  const claimantOption = (
    <RadioOption
      id={'claimant-represented-party-option'}
      isSelected={context.caller.subjectInvolvedPartyType === SubjectInvolvedPartyTypes.CLAIMANT}
      key={'claimant-party-option'}
      label={'Claimant'}
      name={SubjectInvolvedPartyTypes.CLAIMANT}
      onClick={() => { _dispatchRepresentedParty(SubjectInvolvedPartyTypes.CLAIMANT); }}
      optionValue={SubjectInvolvedPartyTypes.CLAIMANT}
    />
  );

  function radioOptions() {
    const insuredDrivers = context.policy.drivers?.map((driver) => {
      const id = `${SubjectInvolvedPartyTypes.INSURED}-${driver.firstName}-${driver.lastName}-${driver.phoneNumber}`;
      return (
        <RadioOption
          id={`${id}-option`}
          isSelected={context.caller?.driver?.universalDriverId === driver.universalDriverId}
          key={id}
          label={`${driver.firstName} ${driver.lastName}`}
          name={id}
          onClick={() => { _dispatchRepresentedParty(SubjectInvolvedPartyTypes.INSURED, driver); }}
          optionValue={id}
        />
      );
    }) || [];

    return [
      ...insuredDrivers,
      unlistedOption,
      claimantOption,
    ];
  }

  return (
    <div>
      <h1 css={FnolStyles.primaryHeader}>File a Claim: Step 1</h1>
      <fieldset>
        <h4 css={FnolStyles.promptAfterHeader}>Who are they calling on behalf of?</h4>
        <RadioInputGroup>
          {radioOptions()}
        </RadioInputGroup>
      </fieldset>
      <Button
        css={FnolStyles.continueButtonMargin}
        disabled={context.caller.subjectInvolvedPartyType === ''}
        onClick={_handleRepresentedPartySubmission}
      >
        Get Started
      </Button>
    </div>
  );
}

