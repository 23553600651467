import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import checkboxChecked from '@root/core/src/assets/checked.svg';
import checkboxUnchecked from '@root/core/src/assets/unchecked.svg';
import isRequiredIf from '@root/core/src/utils/is-required-if';
import { Colors, Shadows, StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function CheckboxOption({
  disabled, id, isError, isSelected, label, name, onChange, onClick = () => {}, optionValue,
}) {
  const optionStyles = [
    styles.checkboxOption,
    isError && styles.checkboxOptionError,
    disabled && styles.disabled,
  ];

  const handleChange = () => onChange(!isSelected);

  const handleClick = (e) => {
    if (!disabled) {
      e.target.focus();
      onClick(e);
    }
  };

  return (
    <>
      <input
        css={styles.input}
        defaultChecked={isSelected}
        disabled={disabled}
        id={id}
        name={name}
        onChange={handleChange}
        onClick={handleClick}
        type={'checkbox'}
        value={optionValue}
      />
      <label
        css={optionStyles}
        htmlFor={id}
      >
        {label}
        <img src={isSelected ? checkboxChecked : checkboxUnchecked} />
      </label>
    </>
  );
}

CheckboxOption.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  isError: PropTypes.bool,
  isSelected: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: isRequiredIf(PropTypes.func, ({ onClick }) => !onClick),
  onClick: isRequiredIf(PropTypes.func, ({ onChange }) => !onChange),
  optionValue: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  checkboxOption: {
    ...Theme.paragraph1(),
    color: Colors.nearBlack(),
    border: `1px solid ${Colors.gray40()}`,
    ...Theme.roundedCorners(),
    height: 65,
    padding: 20,
    paddingRight: 12,
    margin: 0,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    transition: 'all 150ms ease-out',
    ...Shadows.onHoverStateShadow(),
  },
  checkboxOptionError: {
    borderColor: Colors.error(),
  },
  disabled: {
    pointerEvents: 'none',
    background: Colors.gray10(),
    borderColor: Colors.gray10(),
  },
  input: {
    zIndex: -1,
    opacity: 0,
    position: 'absolute',
    ':focus + label > div': {
      ...Shadows.focusShadow(),
    },
  },
});
