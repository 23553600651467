import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function Statement({ text }) {
  return (
    <p css={styles.statement}>
      {text}
    </p>
  );
}

Statement.propTypes = {
  text: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  statement: {
    color: '#000000',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '1.3',
    marginBottom: '0',
  },
});
