import FnolStyles from '@root/claims.joinroot.com/src/assets/fnol-styles';
import PropTypes from '@root/vendor/prop-types';
import RadioInputGroup from '@root/core/src/components/radio-input-group';
import RadioOption from '@root/core/src/components/radio-option';
import React from '@root/vendor/react';
import { DriverTypes } from '@root/claims.joinroot.com/src/models/claims';

export default function DriverQuestion(props) {
  const {
    callerUniversalDriverId,
    currentDriverType,
    currentDriverUniversalDriverId,
    isError,
    onDriverTypeFieldEvent,
    onPolicyDriverFieldEvent,
    policyDrivers,
  } = props;

  const renderPolicyDrivers = () => {
    return (
      policyDrivers
        ?.filter((driver) => driver.universalDriverId !== callerUniversalDriverId)
        .map((driver) => {
          return (
            <RadioOption
              id={driver.universalDriverId}
              isError={isError}
              isSelected={currentDriverUniversalDriverId === driver.universalDriverId}
              key={driver.universalDriverId}
              label={`${driver.firstName} ${driver.lastName}`}
              name={`driverType-policy-${driver.firstName}_${driver.lastName}`}
              onClick={onPolicyDriverFieldEvent(driver)}
              optionValue={driver.universalDriverId}
            />
          );
        })
    );
  };

  return (
    <fieldset>
      <h4 css={FnolStyles.promptAfterHeader}>Who was driving the vehicle?</h4>
      <RadioInputGroup>
        <RadioOption
          id={'driverType-SUBJECT'}
          isError={isError}
          isSelected={currentDriverType === DriverTypes.SUBJECT}
          label={'I was driving'}
          name={'driver_subject'}
          onClick={onDriverTypeFieldEvent}
          optionValue={DriverTypes.SUBJECT}
        />
        {renderPolicyDrivers()}
        <RadioOption
          id={'driverType-SOMEONE_ELSE'}
          isError={isError}
          isSelected={currentDriverType === DriverTypes.SOMEONE_ELSE}
          label={'Someone else was driving'}
          name={'driver_someone_else'}
          onClick={onDriverTypeFieldEvent}
          optionValue={DriverTypes.SOMEONE_ELSE}
        />
        <RadioOption
          id={'driverType-NO_ONE'}
          isError={isError}
          isSelected={currentDriverType === DriverTypes.NO_ONE}
          label={'No one was driving'}
          name={'driver_no_one'}
          onClick={onDriverTypeFieldEvent}
          optionValue={DriverTypes.NO_ONE}
        />
      </RadioInputGroup>
    </fieldset>
  );
}

DriverQuestion.propTypes = {
  callerUniversalDriverId: PropTypes.string,
  currentDriverType: PropTypes.string,
  currentDriverUniversalDriverId: PropTypes.string,
  isError: PropTypes.bool.isRequired,
  onDriverTypeFieldEvent: PropTypes.func.isRequired,
  onPolicyDriverFieldEvent: PropTypes.func.isRequired,
  policyDrivers: PropTypes.arrayOf(PropTypes.shape({
    universalDriverId: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  })),
};
