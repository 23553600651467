import { actionTypes } from '@root/claims.joinroot.com/src/contexts/fnol-context-provider';

export function progressFnolForward(progressName, fnolDispatch, fnolContext) {
  if (fnolContext.progress.includes(progressName)) {
    return;
  }

  fnolDispatch({
    type: actionTypes.SET_PROGRESS_FOR_FNOL,
    progress: fnolContext.progress.concat([progressName]),
  });
}

export function progressFnolSet(newProgress, fnolDispatch) {
  fnolDispatch({
    type: actionTypes.SET_PROGRESS_FOR_FNOL,
    progress: newProgress,
  });
}

export default {
  progressFnolForward,
  progressFnolSet,
};
