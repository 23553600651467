import PropTypes from '@root/vendor/prop-types';
import { FnolContext } from '@root/claims.joinroot.com/src/contexts/fnol-context-provider';
import { Redirect, Route, Switch, useLocation } from '@root/vendor/react-router-dom';
import { useContext } from '@root/vendor/react';

export default function FnolSwitch({ workflow }) {
  const context = useContext(FnolContext);
  const location = useLocation();

  function workflowRoute(step) {
    const {
      path, component, dependencies, fallback,
    } = step;
    let compToDisplay = component;

    const includedInProgress = (progressPath) => context.progress.includes(progressPath);
    if (dependencies && !dependencies.some(includedInProgress)) {
      compToDisplay = <Redirect to={`/portal/fnol/${fallback}`} />;
    }

    return (
      <Route
        exact
        key={path}
        path={`/portal/fnol${path}`}
      >
        {compToDisplay}
      </Route>
    );
  }

  if (context.tokenExpired) {
    return <div data-testid={'token-expired'} />;
  }

  return (
    <Switch location={location}>
      {workflow.map((step) => workflowRoute(step))}
    </Switch>
  );
}

FnolSwitch.propTypes = {
  workflow: PropTypes.array.isRequired,
};
