import FnolStyles from '@root/claims.joinroot.com/src/assets/fnol-styles.js';
import PropTypes from '@root/vendor/prop-types';
import RadioInputGroup from '@root/core/src/components/radio-input-group';
import RadioOption from '@root/core/src/components/radio-option';
import React, { useContext } from '@root/vendor/react';
import Select from '@root/core/src/components/select';
import Validator from '@root/claims.joinroot.com/src/utils/fnol/describe-claim-validator';
import { FnolContext, FnolDispatchContext, actionTypes } from '@root/claims.joinroot.com/src/contexts/fnol-context-provider';
import { PointOfImpactTypes } from '@root/claims.joinroot.com/src/models/claims';

export default function VehicleDamages({ showError }) {
  const state = useContext(FnolContext);
  const dispatch = useContext(FnolDispatchContext);
  const showAirbagsAndFluidsQuestions = true; //need to set to !theft or non-accident once available

  const pointOfImpactOptions = [];
  Object.keys(PointOfImpactTypes).forEach((key) => {
    pointOfImpactOptions.push({
      label: PointOfImpactTypes[key],
      value: key,
    });
  });

  const _handleFieldEvent = (key) => {
    return (value) => {
      value = value?.target?.value || value;
      const validatedForm = Validator.validateNewState(key, value, state);

      _dispatchForm(validatedForm);
    };
  };

  const _dispatchForm = (form) => {
    dispatch({
      type: actionTypes.SET_CLAIM_FORM_FOR_FNOL,
      form,
    });
  };

  const _renderAirbagsAndFluidsQuestions = () => {
    if (showAirbagsAndFluidsQuestions) {
      return (
        <div>
          <fieldset>
            <h4 css={FnolStyles.promptAfterHeader}>Did any of the airbags deploy?</h4>
            <RadioInputGroup>
              <RadioOption
                id={'vehicle_airbags_deployed-true'}
                isError={showError.fields && !state.vehicleAirbagsDeployed}
                isSelected={state.vehicleAirbagsDeployed === 'true'}
                label={'Yes'}
                name={'vehicle_airbags_deployed'}
                onClick={_handleFieldEvent('vehicleAirbagsDeployed')}
                optionValue={'true'}
              />
              <RadioOption
                id={'vehicle_airbags_deployed-false'}
                isError={showError.fields && !state.vehicleAirbagsDeployed}
                isSelected={state.vehicleAirbagsDeployed === 'false'}
                label={'No'}
                name={'vehicle_airbags_deployed'}
                onClick={_handleFieldEvent('vehicleAirbagsDeployed')}
                optionValue={'false'}
              />
            </RadioInputGroup>
          </fieldset>
          <fieldset>
            <h4 css={FnolStyles.prompt}>Are there any fluids leaking from the vehicle?</h4>
            <RadioInputGroup>
              <RadioOption
                id={'vehicle_leaking_fluids-true'}
                isError={showError.fields && !state.vehicleLeakingFluids}
                isSelected={state.vehicleLeakingFluids === 'true'}
                label={'Yes'}
                name={'vehicle_leaking_fluids'}
                onClick={_handleFieldEvent('vehicleLeakingFluids')}
                optionValue={'true'}
              />
              <RadioOption
                id={'vehicle_leaking_fluids-false'}
                isError={showError.fields && !state.vehicleLeakingFluids}
                isSelected={state.vehicleLeakingFluids === 'false'}
                label={'No'}
                name={'vehicle_leaking_fluids'}
                onClick={_handleFieldEvent('vehicleLeakingFluids')}
                optionValue={'false'}
              />
            </RadioInputGroup>
          </fieldset>
        </div>
      );
    }
  };

  return (
    <>
      <div data-testid={'vehicle_damages'}>
        <h2 css={FnolStyles.secondaryHeader}>Vehicle Damages</h2>
        {_renderAirbagsAndFluidsQuestions()}
        <fieldset>
          <h4 css={FnolStyles.prompt}>What was the point of impact?</h4>
          <Select
            inputId={'vehicle_point_of_impact'}
            inputName={'vehicle_point_of_impact'}
            onChange={_handleFieldEvent('vehiclePointOfImpact')}
            options={pointOfImpactOptions}
            placeholder={'Point of Impact'}
            selectedValue={state.vehiclePointOfImpact || ''}
          />
        </fieldset>
      </div>
    </>
  );
}

VehicleDamages.propTypes = {
  showError: PropTypes.shape({
    fields: PropTypes.bool,
  }),
};
