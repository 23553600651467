import ClaimsPortalApi from '@root/claims.joinroot.com/src/api/claims-portal-api';
import FlashBanner from '@root/claims.joinroot.com/src/components/notification_preferences/flash-banner';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import SubmitButton from '@root/claims.joinroot.com/src/components/core/submit-button';
export default class NotificationPreferencesPortal extends React.Component {
  static propTypes = {
    authenticityToken: PropTypes.string.isRequired,
    claimNotificationPreferences: PropTypes.string.isRequired,
  };

  state = {
    parsedProps: JSON.parse(this.props.claimNotificationPreferences),
    receiveEmail: false,
    submitting: false,
    notice: null,
    error: null,
  };

  _beforeSubmit(event) {
    if (event) { event.preventDefault(); }
    if (this.state.submitting) { return; }
    this._submit();
  }

  get _formData() {
    return {
      authenticityToken: this.props.authenticityToken,
      claimNotificationPreferences: {
        receiveEmail: this.state.receiveEmail,
      },
    };
  }

  _onSubmitSuccess() {
    this.setState(
      {
        submitting: false,
        notice: 'Your preferences have been saved.',
      },
    );
  }

  _onSubmitFailure() {
    this.setState(
      {
        submitting: false,
        error: 'An error occurred, please try again later or call to update your preference.',
      },
    );
  }

  _submit() {
    this.setState({
      submitting: true,
      notice: null,
      error: null,
    }, () => {
      ClaimsPortalApi.postToPortal(`/web_api/claims/portal/notification_preferences/${this.state.parsedProps.id}`, this._formData, this._onSubmitSuccess.bind(this), this._onSubmitFailure.bind(this));
    });
  }

  componentDidMount() {
    this.setState({
      receiveEmail: this.state.parsedProps['receive_email'],
    });
  }

  render() {
    return (
      <>
        <FlashBanner
          error={this.state.error}
          notice={this.state.notice}
        />
        <form>
          <fieldset>
            <legend>
              Notification Preferences
            </legend>
            <p>
              From here you can manage your preferences for notifications and reminders about this claim.
            </p>
            <div className={'form-check'}>
              <label>
                <input
                  checked={this.state.receiveEmail}
                  className={'form-check-input'}
                  data-testid={'receive-email-check-box'}
                  name={'receive-email'}
                  onChange={() => this.setState({
                    receiveEmail: !this.state.receiveEmail,
                  })}
                  type={'checkbox'}
                  value={this.state.receiveEmail}
                />
                Yes, I would like to receive automated updates on this claim via email
              </label>
            </div>
            <p>
              These preferences do not change how your claims expert will get in touch with you. You will always receive important email updates,
              such as when a claim is closed, and any updates or notifications about new claims filed.
            </p>
          </fieldset>
          <SubmitButton
            data-testid={'notification-preferences-submit-button'}
            disabled={false}
            onClick={(event) => this._beforeSubmit(event)}
            submitting={this.state.submitting}
            submittingText={'Submitting'}
            text={'Update my preferences'}
          />
        </form>
      </>);
  }
}
