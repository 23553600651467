import React from '@root/vendor/react';

export default class Check extends React.Component {
  render() {
    return (
      <svg
        fill={'none'}
        height={'48'}
        viewBox={'0 0 48 48'}
        width={'48'}
        xmlns={'http://www.w3.org/2000/svg'}
      >
        <title>Checkmark Icon</title>
        <circle
          cx={'24'}
          cy={'24'}
          r={'21'}
          stroke={'#00A778'}
          strokeWidth={'2'}
        />
        <path
          d={'M14 23L21 30L35 16'}
          stroke={'#00A778'}
          strokeWidth={'2'}
        />
      </svg>
    );
  }
}
