import NetworkRequestConfiguration from '@root/core/src/networking/network-request-configuration';

export default function getCurrentPromptsConfiguration({ token, desiredKey }) {
  const params = {
    t: token,
  };
  if (desiredKey) {
    params.desiredKey = desiredKey;
  }
  return new NetworkRequestConfiguration({
    endpoint: 'claims/conversations/current_prompts',
    errorCodes: [401],
    queryParameters: params,
    method: NetworkRequestConfiguration.Methods.GET,
  });
}
