import FnolStyles from '@root/claims.joinroot.com/src/assets/fnol-styles';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import TextArea from '@root/core/src/components/text-area';

export default function PreExistingConditionsExplanationQuestion(props) {
  const {
    currentPreExistingConditionsExplanation,
    errorLabel,
    onFieldEvent,
  } = props;

  return (
    <fieldset>
      <h4 css={FnolStyles.prompt}>What injuries or illnesses did you already have before the accident? How did the accident affect them?</h4>
      <TextArea
        ariaLabel={'Pre Existing Conditions Explanation'}
        errorLabel={errorLabel}
        name={'pre_existing_conditions_explanation'}
        onBlur={onFieldEvent}
        onChange={onFieldEvent}
        placeholder={'Description of pre-existing injuries or illnesses'}
        value={currentPreExistingConditionsExplanation || ''}
      />
    </fieldset>
  );
}

PreExistingConditionsExplanationQuestion.propTypes = {
  currentPreExistingConditionsExplanation: PropTypes.string,
  errorLabel: PropTypes.string,
  onFieldEvent: PropTypes.func.isRequired,
};
