import FnolStyles from '@root/claims.joinroot.com/src/assets/fnol-styles';
import Input from '@root/core/src/components/input';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Select from '@root/core/src/components/select';
import { StatePickerOptions } from '@root/claims.joinroot.com/src/models/picker-options';

export default function IncidentLocationQuestion(props) {
  const {
    errorLabel,
    incidentLocationCity,
    incidentLocationState,
    inputStyle,
    onCityFieldEvent,
    onStateFieldEvent,
  } = props;

  return (
    <fieldset>
      <h4 css={FnolStyles.prompt}>Where did the incident occur?</h4>
      <Input
        errorLabel={errorLabel}
        id={'incidentLocationCity-input'}
        label={'City'}
        name={'incident_location_city'}
        onBlur={onCityFieldEvent}
        onChange={onCityFieldEvent}
        value={incidentLocationCity || ''}
      />
      <Select
        inputId={'incident_location_state'}
        inputName={'incident_location_state'}
        onChange={onStateFieldEvent}
        options={StatePickerOptions}
        placeholder={'State'}
        selectedValue={incidentLocationState || ''}
        wrapperStyles={inputStyle}
      />
    </fieldset>
  );
}

IncidentLocationQuestion.propTypes = {
  errorLabel: PropTypes.string,
  incidentLocationCity: PropTypes.string,
  incidentLocationState: PropTypes.string,
  inputStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onCityFieldEvent: PropTypes.func.isRequired,
  onStateFieldEvent: PropTypes.func.isRequired,
};
