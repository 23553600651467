import Clock from '@root/claims.joinroot.com/src/svg/clock.js';
import React from '@root/vendor/react';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function ExpiredToken() {
  return (
    <>
      <Clock />
      <h1 css={styles.header}>
        Your link has expired.
      </h1>
      <p css={styles.subtext}>
        You can still add or update information by calling or emailing your claims expert.
      </p>
    </>
  );
}

const styles = StyleSheet.create({
  header: {
    color: Colors.black(),
    fontSize: '42px',
    fontWeight: '600',
    lineHeight: '100%',
    marginBottom: '20px',
  },
  subtext: {
    color: Colors.gray60(),
    fontSize: '18px',
    lineHeight: '130%',
    marginBottom: '12px',
  },
});
