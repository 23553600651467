import NetworkRequestConfiguration from '@root/core/src/networking/network-request-configuration';

export default function getFnolConfiguration({
  token,
  policyNumber,
} = {}) {
  const queryParameters = {};
  if (token) {
    queryParameters.t = token;
  }
  if (policyNumber) {
    queryParameters.policy_number = policyNumber;
  }

  return new NetworkRequestConfiguration({
    endpoint: 'claims/portal/fnol',
    queryParameters,
    method: NetworkRequestConfiguration.Methods.GET,
  });
}
