import { AssociatedPartyTypes, CustomIncidentType, IncidentTypes } from '@root/claims.joinroot.com/src/models/claims';

export const StatePickerOptions = [
  {
    value: 'AL',
    label: 'Alabama',
  },
  {
    value: 'AK',
    label: 'Alaska',
  },
  {
    value: 'AZ',
    label: 'Arizona',
  },
  {
    value: 'AR',
    label: 'Arkansas',
  },
  {
    value: 'CA',
    label: 'California',
  },
  {
    value: 'CO',
    label: 'Colorado',
  },
  {
    value: 'CT',
    label: 'Connecticut',
  },
  {
    value: 'DE',
    label: 'Delaware',
  },
  {
    value: 'DC',
    label: 'Washington, DC',
  },
  {
    value: 'FL',
    label: 'Florida',
  },
  {
    value: 'GA',
    label: 'Georgia',
  },
  {
    value: 'HI',
    label: 'Hawaii',
  },
  {
    value: 'ID',
    label: 'Idaho',
  },
  {
    value: 'IL',
    label: 'Illinois',
  },
  {
    value: 'IN',
    label: 'Indiana',
  },
  {
    value: 'IA',
    label: 'Iowa',
  },
  {
    value: 'KS',
    label: 'Kansas',
  },
  {
    value: 'KY',
    label: 'Kentucky',
  },
  {
    value: 'LA',
    label: 'Louisiana',
  },
  {
    value: 'ME',
    label: 'Maine',
  },
  {
    value: 'MD',
    label: 'Maryland',
  },
  {
    value: 'MA',
    label: 'Massachusetts',
  },
  {
    value: 'MI',
    label: 'Michigan',
  },
  {
    value: 'MN',
    label: 'Minnesota',
  },
  {
    value: 'MS',
    label: 'Mississippi',
  },
  {
    value: 'MO',
    label: 'Missouri',
  },
  {
    value: 'MT',
    label: 'Montana',
  },
  {
    value: 'NE',
    label: 'Nebraska',
  },
  {
    value: 'NV',
    label: 'Nevada',
  },
  {
    value: 'NH',
    label: 'New Hampshire',
  },
  {
    value: 'NJ',
    label: 'New Jersey',
  },
  {
    value: 'NM',
    label: 'New Mexico',
  },
  {
    value: 'NY',
    label: 'New York',
  },
  {
    value: 'NC',
    label: 'North Carolina',
  },
  {
    value: 'ND',
    label: 'North Dakota',
  },
  {
    value: 'OH',
    label: 'Ohio',
  },
  {
    value: 'OK',
    label: 'Oklahoma',
  },
  {
    value: 'OR',
    label: 'Oregon',
  },
  {
    value: 'PA',
    label: 'Pennsylvania',
  },
  {
    value: 'RI',
    label: 'Rhode Island',
  },
  {
    value: 'SC',
    label: 'South Carolina',
  },
  {
    value: 'SD',
    label: 'South Dakota',
  },
  {
    value: 'TN',
    label: 'Tennessee',
  },
  {
    value: 'TX',
    label: 'Texas',
  },
  {
    value: 'UT',
    label: 'Utah',
  },
  {
    value: 'VT',
    label: 'Vermont',
  },
  {
    value: 'VA',
    label: 'Virginia',
  },
  {
    value: 'WA',
    label: 'Washington',
  },
  {
    value: 'WV',
    label: 'West Virginia',
  },
  {
    value: 'WI',
    label: 'Wisconsin',
  },
  {
    value: 'WY',
    label: 'Wyoming',
  },
];

const claimantOptionsGroup = {
  CHAIN_REACTION: IncidentTypes.CHAIN_REACTION,
  IV_REAR_ENDED_CV: IncidentTypes.I_WAS_REAR_ENDED,
  PARKED_CAR_WAS_HIT: IncidentTypes.PARKED_CAR_WAS_HIT,
  DRIVING_CAR_WAS_HIT: IncidentTypes.DRIVING_CAR_WAS_HIT,
};

const phoneUserIWasHitGroup = {
  CHAIN_REACTION: IncidentTypes.CHAIN_REACTION,
  IV_REAR_ENDED_CV: IncidentTypes.IV_REAR_ENDED_CV,
  PARKED_CAR_WAS_HIT: IncidentTypes.PARKED_CAR_WAS_HIT,
  DRIVING_CAR_WAS_HIT: IncidentTypes.DRIVING_CAR_WAS_HIT,
  INTERSECTION: IncidentTypes.INTERSECTION,
};

const phoneUserIHitSomethingGroup = {
  HIT_A_MOVING_CAR: IncidentTypes.HIT_A_MOVING_CAR,
  CV_REAR_ENDED_IV: IncidentTypes.CV_REAR_ENDED_IV,
  HIT_A_STATIONARY_CAR: IncidentTypes.HIT_A_STATIONARY_CAR,
  BACKED_INTO_ANOTHER_CAR: IncidentTypes.BACKED_INTO_ANOTHER_CAR,
};

const nonAccidentIncidentTypeGroup = {
  FIRE: IncidentTypes.FIRE,
  THEFT: IncidentTypes.THEFT,
  FLOOD: IncidentTypes.FLOOD,
  VANDALISM: IncidentTypes.VANDALISM,
  HAIL: IncidentTypes.HAIL,
  WIND_OR_DEBRIS: IncidentTypes.WIND_OR_DEBRIS,
};

const hitSomethingElseGroup = {
  HIT_AN_ANIMAL: IncidentTypes.HIT_AN_ANIMAL,
  BACKED_INTO_SOMETHING: IncidentTypes.BACKED_INTO_SOMETHING,
  HIT_A_PEDESTRIAN: IncidentTypes.HIT_A_PEDESTRIAN,
  HIT_A_STATIONARY_OBJECT: IncidentTypes.HIT_A_STATIONARY_OBJECT,
};

const customIncidentTypeGroup = {
  [CustomIncidentType]: IncidentTypes.CUSTOM,
};

const incidentTypeGroups = (isClaimant) => {
  if (isClaimant) {
    return [
      claimantOptionsGroup,
      customIncidentTypeGroup,
    ];
  } else {
    return [
      phoneUserIWasHitGroup,
      phoneUserIHitSomethingGroup,
      hitSomethingElseGroup,
      nonAccidentIncidentTypeGroup,
      customIncidentTypeGroup,
    ];
  }
};

export function incidentTypeOptions(isClaimant) {
  return incidentTypeGroups(isClaimant).flatMap((typeGroup) => {
    const options = [];
    for (const value in typeGroup) {
      options.push({
        value,
        label: typeGroup[value],
      });
    }

    options.push({
      label: '',
      spacer: true,
      value: '',
    });
    return options;
  });
}

function _partyTypeOptions() {
  const options = [];
  for (const key in AssociatedPartyTypes) {
    options.push({
      value: AssociatedPartyTypes[key],
      label: AssociatedPartyTypes[key],
    });
  }
  return options;
}

export const AssociatedPartyTypeOptions = _partyTypeOptions();
