import PropTypes from '@root/vendor/prop-types';
import getConversationsConfiguration from '@root/claims.joinroot.com/src/api/conversations-requests/get-current-prompts-configuration';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import { ConversationsDispatchContext, actionTypes } from '@root/claims.joinroot.com/src/contexts/conversations-context-provider';
import { useContext, useEffect } from '@root/vendor/react';
import { useHistory } from '@root/vendor/react-router-dom';

export default function FetchPrompts({ desiredKey, token }) {
  const [getConversations] = useImperativeNetworkRequest(getConversationsConfiguration);
  const dispatch = useContext(ConversationsDispatchContext);
  const history = useHistory({});

  useEffect(() => {
    async function fetchConversations() {
      const result = await getConversations({
        token,
        desiredKey,
      });
      dispatch({
        ...result.data,
        type: actionTypes.STORE_CURRENT_PROMPTS,
      });
      const path = result.data.tokenExpired === 'true' ? 'token_expired' : result.data.currentPrompts[0].fullKey;
      history.push(`/portal/conversations/${path}?t=${token}`);
    }
    fetchConversations();
  }, [getConversations, token, desiredKey, dispatch, history]);

  return null;
}

FetchPrompts.propTypes = {
  desiredKey: PropTypes.string,
  token: PropTypes.string.isRequired,
};
