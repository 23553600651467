export const CallerTypes = {
  FIRST_PARTY: 'FIRST_PARTY',
  THIRD_PARTY: 'THIRD_PARTY',
};

export const SubjectInvolvedPartyTypes = {
  INSURED: 'INSURED',
  UNLISTED: 'UNLISTED',
  CLAIMANT: 'CLAIMANT',
};

export const AssociatedPartyTypes = {
  ADVERSE_CARRIER: 'Adverse Carrier',
  ATTORNEY: 'Attorney',
  BODY_SHOP: 'Body Shop',
  GUARDIAN: 'Guardian',
  LIENHOLDER: 'Lienholder',
  MEDICAL_PROVIDER: 'Medical Provider',
  PARENT: 'Parent',
  WITNESS: 'Witness',
  OTHER: 'Other',
};

export const LinkedAssociatedPartyTypes = [
  AssociatedPartyTypes.ATTORNEY,
];

export const CustomIncidentType = 'CUSTOM';

export const DriverTypes = {
  SUBJECT: 'subject',
  SOMEONE_ELSE: 'someone_else',
  NO_ONE: 'no_one',
  POLICY_DRIVER: 'policy_driver',
};

// When making changes to these categories you will also have to update the categories in server/systems/claims/loss_reports/app/models/reported_injury_category.rb
export const FirstPartyInjuryCategories = [
  {
    label: 'Whiplash or muscle soreness',
    value: 'whiplash_or_muscle_soreness',
  },
  {
    label: 'Roadrash',
    value: 'roadrash',
  },
  {
    label: 'Major bruising or contusion',
    value: 'major_bruising_or_contusion',
  },
  {
    label: 'Small cuts or lacerations',
    value: 'small_cuts_or_lacerations',
  },
  {
    label: 'Large lacerations with stitches/staples',
    value: 'large_lacerations_with_stitches_or_staples',
  },
  {
    label: 'Broken bone or fracture',
    value: 'broken_bone_or_fracture',
  },
  {
    label: 'Internal injuries',
    value: 'internal_injuries',
  },
  {
    label: 'Concussion or head injury',
    value: 'concussion_or_head_injury',
  },
  {
    label: 'Burns',
    value: 'burns',
  },
  {
    label: 'Amputation or loss of a limb',
    value: 'amputation_or_loss_of_limb',
  },
  {
    label: 'A pre-existing injury/illness has been aggravated by this accident',
    value: 'pre_existing_injury',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const IncidentRole = {
  DRIVER: 'driver',
  PASSENGER: 'passenger',
  PEDESTRIAN: 'pedestrian',
  EXTERNAL_PARTY: 'external_party',
  OWNER_NOT_PRESENT: 'owner_not_present',
  UNKNOWN: 'unknown',
};

export const IncidentTypes = {
  BACKED_INTO_ANOTHER_CAR: 'My vehicle backed into another vehicle',
  BACKED_INTO_SOMETHING: 'My vehicle backed into something',
  CHAIN_REACTION: 'Multiple vehicles were struck in a chain reaction',
  CV_REAR_ENDED_IV: 'CV rear ended IV',
  [CustomIncidentType]: 'Something Else',
  DRIVING_CAR_WAS_HIT: 'Another vehicle hit me while I was driving',
  FIRE: 'Fire',
  FLOOD: 'Flood',
  HAIL: 'Hail',
  HIT_A_MOVING_CAR: 'My vehicle hit a moving vehicle',
  HIT_A_PEDESTRIAN: 'My vehicle hit a pedestrian',
  HIT_A_STATIONARY_CAR: 'My vehicle hit a stationary vehicle',
  HIT_A_STATIONARY_OBJECT: 'My vehicle hit a stationary object',
  HIT_AN_ANIMAL: 'My vehicle struck an animal',
  INTERSECTION: 'The accident happened at an intersection',
  I_REAR_ENDED_SOMEONE: 'My vehicle rear-ended a vehicle',
  IV_REAR_ENDED_CV: 'IV rear ended CV',
  I_WAS_REAR_ENDED: 'Another vehicle rear-ended my vehicle',
  PARKED_CAR_WAS_HIT: 'My parked vehicle was hit',
  THEFT: 'Theft',
  VANDALISM: 'Vandalism',
  WIND_OR_DEBRIS: 'Wind or Debris',
};

export const InjuryTypes = {
  DECEASED: 'deceased',
  INJURED: 'injured',
  UNKNOWN: 'unknown',
  UNINJURED: 'uninjured',
};

export const InvolvementTypes = {
  INSURED: 'insured',
  CLAIMANT: 'claimant',
};

export const PointOfImpactTypes = {
  UNKNOWN: 'Unknown',
  ALL_OVER: 'All Over',
  ENGINE_BURN: 'Engine Burn',
  FRESH_WATER: 'Fresh Water',
  FRONT: 'Front',
  FRONT_AND_REAR: 'Front & Rear',
  GLASS: 'Glass',
  HAIL: 'Hail',
  INTERIOR_BURN: 'Interior Burn',
  LEFT_AND_RIGHT_SIDE: 'Left & Right Side',
  LEFT_FRONT: 'Left Front',
  LEFT_FRONT_PILLAR: 'Left Front Pillar',
  LEFT_QUARTER_POST: 'Left Quarter Post',
  LEFT_REAR: 'Left Rear',
  LEFT_TBONE: 'Left T-Bone',
  NON_COLLISION: 'Non-Collision',
  REAR: 'Rear',
  RIGHT_FRONT: 'Right Front',
  RIGHT_FRONT_PILLAR: 'Right Front Pillar',
  RIGHT_QUARTER_POST: 'Right Quarter Post',
  RIGHT_REAR: 'Right Rear',
  RIGHT_TBONE: 'Right T-Bone',
  ROLLOVER: 'Rollover',
  SALT_WATER: 'Salt Water',
  STRIP: 'Strip',
  TOTAL_BURN: 'Total Burn',
  TOTAL_LOSS: 'Total Loss',
  UNDERCARRIAGE: 'Undercarriage',
  VANDALIZED: 'Vandalized',
};

// When making changes to these categories you will also have to update the categories in server/systems/claims/loss_reports/app/models/reported_injury_category.rb
export const ThirdPartyInjuryCategories = [
  {
    label: 'I\'m not sure',
    value: 'not_sure',
  },
  {
    label: 'Whiplash or muscle soreness',
    value: 'whiplash_or_muscle_soreness',
  },
  {
    label: 'Roadrash',
    value: 'roadrash',
  },
  {
    label: 'Major bruising or contusion',
    value: 'major_bruising_or_contusion',
  },
  {
    label: 'Small cuts or lacerations',
    value: 'small_cuts_or_lacerations',
  },
  {
    label: 'Large lacerations with stitches/staples',
    value: 'large_lacerations_with_stitches_or_staples',
  },
  {
    label: 'Broken bone or fracture',
    value: 'broken_bone_or_fracture',
  },
  {
    label: 'Internal injuries',
    value: 'internal_injuries',
  },
  {
    label: 'Concussion or head injury',
    value: 'concussion_or_head_injury',
  },
  {
    label: 'Burns',
    value: 'burns',
  },
  {
    label: 'Amputation or loss of a limb',
    value: 'amputation_or_loss_of_limb',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const VehicleMileageRangeTypes = [
  {
    label: '0-50,000 miles',
    value: '0-50000',
  },
  {
    label: '50,001-100,000 miles',
    value: '50001-100000',
  },
  {
    label: '100,001-150,000 miles',
    value: '100000-150000',
  },
  {
    label: '150,001+ miles',
    value: '150001+',
  },
];
