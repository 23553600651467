import React from '@root/vendor/react';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function Divider() {
  return (
    <p
      css={styles.divider}
      role={'divider'}
    />
  );
}

const styles = StyleSheet.create({
  divider: {
    borderBottom: '1px solid #DADADA',
    width: '100%',
    marginTop: '24px',
    marginBottom: '24px',
  },
});
