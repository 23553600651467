import Button, { BUTTON_SIZE_SMALL, BUTTON_VARIANT_SECONDARY } from '@root/core/src/components/button';
import FatalityQuestion from '@root/claims.joinroot.com/src/components/fnol/questions/fatality-question';
import FnolStyles from '@root/claims.joinroot.com/src/assets/fnol-styles.js';
import InjuryCategoriesQuestion from '@root/claims.joinroot.com/src/components/fnol/questions/injury-categories-question';
import InjuryQuestion from '@root/claims.joinroot.com/src/components/fnol/questions/injury-question';
import NameAndPhoneNumberQuestion from '@root/claims.joinroot.com/src/components/fnol/questions/name-and-phone-number-question';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { InjuryTypes, ThirdPartyInjuryCategories } from '@root/claims.joinroot.com/src/models/claims';

export default function InvolvedParty(props) {
  const {
    inputKey,
    party,
    updateInvolvedParty,
    removeParty,
    showError,
  } = props;

  const _atLeastOneOptionIsSelected = (options) => {
    return Object.values(options).some((value) => value);
  };

  const _update = (field) => {
    return (value) => updateInvolvedParty(field, value);
  };

  const _updateInjured = (value) => {
    updateInvolvedParty('injuryType', value?.target?.value);
  };

  const _involvedPartyInjuryTypeIsInjured = party.injuryType === InjuryTypes.INJURED;

  const _involvedPartyFullName = [party.firstName, party.lastName].filter((n) => n).join(' ');

  const _involvedPartyNotDeceased = party.injuryType === '' || party.injuryType && party.injuryType !== InjuryTypes.DECEASED;

  return (
    <div
      data-testid={'InvolvedParty'}
    >
      <NameAndPhoneNumberQuestion
        currentFirstName={party.firstName}
        currentLastName={party.lastName}
        currentPhoneNumber={party.phoneNumber}
        errorLabelFirstName={showError && !party.firstName ? 'required' : null}
        errorLabelLastName={showError && !party.lastName ? 'required' : null}
        errorLabelPhoneNumber={null}
        inputKey={`involved-party-${inputKey}`}
        inputStyle={FnolStyles.inputMargin}
        labelPhoneNumber={'Phone number'}
        onFirstNameFieldEvent={_update('firstName')}
        onLastNameFieldEvent={_update('lastName')}
        onPhoneNumberFieldEvent={_update('phoneNumber')}
        questionText={'What is the name & phone number of the other person involved?'}
      />
      <FatalityQuestion
        currentInjuryType={party.injuryType}
        currentName={_involvedPartyFullName}
        inputKey={`involved-party-${inputKey}`}
        isError={showError && party.injuryType !== '' && !party.injuryType}
        onFieldEvent={_updateInjured}
      />
      {_involvedPartyNotDeceased &&
        <InjuryQuestion
          currentInjuryType={party.injuryType}
          currentName={_involvedPartyFullName}
          inputKey={`involved-party-${inputKey}`}
          isError={showError && !party.injuryType}
          onFieldEvent={_updateInjured}
        />
      }
      {_involvedPartyNotDeceased && _involvedPartyInjuryTypeIsInjured &&
        <InjuryCategoriesQuestion
          currentName={_involvedPartyFullName}
          currentSelectedInjuryCategories={party.injuryCategories}
          injuryCategories={ThirdPartyInjuryCategories}
          inputKey={`involved-party-${inputKey}`}
          isError={showError && !_atLeastOneOptionIsSelected(party.injuryCategories)}
          onFieldEvent={_update('injuryCategories')}
        />
      }
      <Button
        onClick={() => removeParty(party.key)}
        size={BUTTON_SIZE_SMALL}
        variant={BUTTON_VARIANT_SECONDARY}
      >
        Remove this person
      </Button>

    </div>
  );
}

InvolvedParty.propTypes = {
  inputKey: PropTypes.string.isRequired,
  party: PropTypes.shape({
    firstName: PropTypes.string,
    key: PropTypes.string.isRequired,
    lastName: PropTypes.string,
    phoneNumber: PropTypes.string,
    injuryType: PropTypes.string,
    injuryCategories: PropTypes.objectOf(PropTypes.bool),
  }).isRequired,
  removeParty: PropTypes.func.isRequired,
  showError: PropTypes.bool.isRequired,
  updateInvolvedParty: PropTypes.func.isRequired,
};

