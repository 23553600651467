import Clock from '@root/claims.joinroot.com/src/svg/clock.js';
import React from '@root/vendor/react';
import SnolStyles from '@root/claims.joinroot.com/src/assets/snol-styles';

export default class ExpiredToken extends React.Component {
  render() {
    return (
      <>
        <Clock />
        <h1 css={SnolStyles.primaryHeader}>
          Your link has expired.
        </h1>
        <p css={SnolStyles.subtext}>
          You can still add or update information by calling or emailing your claims expert.
        </p>
      </>
    );
  }
}
