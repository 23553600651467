import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default StyleSheet.create({
  primaryHeader: {
    color: Colors.black(),
    fontSize: '32px',
    fontWeight: '600',
    lineHeight: '110%',
    marginBottom: '20px',
  },
  secondaryHeader: {
    color: Colors.black(),
    fontSize: '28px',
    fontWeight: '600',
    lineHeight: '110%',
    marginTop: '40px',
    marginBottom: '0px',
  },
  prompt: {
    color: Colors.nearBlack(),
    fontSize: '22px',
    fontWeight: '400',
    lineHeight: '130%',
    marginTop: '40px',
    marginBottom: '12px',
  },
  promptAfterHeader: {
    color: Colors.nearBlack(),
    fontSize: '22px',
    fontWeight: '400',
    lineHeight: '130%',
    marginTop: '12px',
    marginBottom: '12px',
  },
  subtext: {
    color: Colors.gray60(),
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '130%',
  },
  subtextAfterPrompt: {
    color: Colors.gray60(),
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '130%',
    marginTop: '4px',
    marginBottom: '16px',
  },
  continueButtonMargin: {
    marginTop: '40px',
  },
  inputMargin: {
    marginBottom: '12px',
  },
});
