import Regex from '@root/claims.joinroot.com/src/utils/regex';

const validators = {
  phoneNumber: {
    test: Regex.validate(Regex.PHONE_NUMBER),
    message: 'Invalid phone number',
  },
  policyNumber: {
    test: Regex.validate(Regex.TEXT),
  },
  firstName: {
    test: Regex.validate(Regex.TEXT),
  },
  lastName: {
    test: Regex.validate(Regex.TEXT),
  },
};

const anyErrors = (policyFormState) => {
  return Object.values(policyFormState.errors).reduce((atLeastOneError, currentErrorMessage) => {
    return atLeastOneError || !!currentErrorMessage;
  }, false);
};

const _notEnoughInputCollected = (policyFormState) => {
  const importantInputs = [
    policyFormState.policyNumber,
    policyFormState.phoneNumber,
    policyFormState.lastName,
  ];

  const filtered = importantInputs.filter((s) => { return s !== ''; });
  return filtered.length < 2;
};

const phoneNumberErrors = (policyFormState) => {
  return (
    policyFormState.errors.phoneNumber
  );
};

const validateNewState = (key, value, policyFormState) => {
  const validator = validators[key];
  if (!validator) {
    return {
      ...policyFormState,
      [key]: value,
    };
  }

  const currentFormErrors = policyFormState.errors;

  delete currentFormErrors[key];
  delete currentFormErrors['notFound'];

  if (value && !validator.test(value)) {
    currentFormErrors[key] = validator.message;
  }

  return {
    ...policyFormState,
    [key]: value,
    notEnoughInputCollected: _notEnoughInputCollected({
      ...policyFormState,
      [key]: value,
    }),
    errors: currentFormErrors,
  };
};

export default {
  anyErrors,
  phoneNumberErrors,
  validateNewState,
};
