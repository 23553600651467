export default class ViewportService {
  static isInViewport(elem) {
    const boundingRect = elem.getBoundingClientRect();
    return (
      boundingRect.top >= 0 &&
      boundingRect.left >= 0 &&
      boundingRect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      boundingRect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
}
