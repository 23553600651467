import FnolStyles from '@root/claims.joinroot.com/src/assets/fnol-styles';
import PhoneNumberInput from '@root/core/src/components/phone-number-input';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { ALTERNATE_PHONE_NUMBER_MASK } from '@root/core/src/models/phone';
import { TextTypes } from '@root/core/src/components/input';

export default function PhoneNumberQuestion(props) {
  const {
    currentPhoneNumber,
    errorLabel,
    name,
    onFieldEvent,
  } = props;

  return (
    <fieldset>
      <h4 css={FnolStyles.prompt}>What is your phone number?</h4>
      <PhoneNumberInput
        autoFocus={false}
        errorLabel={errorLabel}
        inputId={`${name}PhoneNumber-input`}
        inputMask={ALTERNATE_PHONE_NUMBER_MASK}
        inputType={TextTypes.TEL}
        label={'999-999-9999'}
        name={`${name}_phone_number`}
        onBlur={onFieldEvent}
        onChange={onFieldEvent}
        value={currentPhoneNumber || ''}
      />
    </fieldset>
  );
}

PhoneNumberQuestion.propTypes = {
  currentPhoneNumber: PropTypes.string,
  errorLabel: PropTypes.string,
  name: PropTypes.string.isRequired,
  onFieldEvent: PropTypes.func.isRequired,
};
