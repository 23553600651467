import CallerInformation from '@root/claims.joinroot.com/src/components/fnol/caller-information';
import CallerSelect from '@root/claims.joinroot.com/src/components/fnol/caller-select';
import ClaimFailed from '@root/claims.joinroot.com/src/components/fnol/claim-failed';
import ClaimReceived from '@root/claims.joinroot.com/src/components/fnol/claim-received';
import DescribeClaimForm from '@root/claims.joinroot.com/src/components/fnol/describe-claim-form';
import InvolvedPartiesForm from '@root/claims.joinroot.com/src/components/fnol/involved-parties-form';
import PhoneFnolReset from '@root/claims.joinroot.com/src/components/fnol/phone-fnol-reset';
import RepresentedPartySelect from '@root/claims.joinroot.com/src/components/fnol/represented-party-select';

const PHONE_USER_WORKFLOW = [
  {
    path: '',
    component: <PhoneFnolReset />,
  },
  {
    path: '/caller',
    component: <CallerSelect />,
  },
  {
    path: '/represented-party',
    component: <RepresentedPartySelect />,
    dependencies: ['third-party-select'],
    fallback: '/caller',
  },
  {
    path: '/caller-information',
    component: <CallerInformation />,
    dependencies: ['represented-party-select'],
    fallback: '/caller',
  },
  {
    path: '/step2',
    component: <DescribeClaimForm />,
    dependencies: ['caller-select', 'caller-information'],
    fallback: '/caller',
  },
  {
    path: '/step3',
    component: <InvolvedPartiesForm />,
    dependencies: ['describe-claim'],
    fallback: '/step2',
  },
  {
    path: '/received',
    component: <ClaimReceived />,
    dependencies: ['claim-received'],
    fallback: '/step2',
  },
  {
    path: '/falied',
    component: <ClaimFailed />,
    dependencies: ['claim-failed'],
    fallback: '/step2',
  },
];

export { PHONE_USER_WORKFLOW };
