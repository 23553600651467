import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Theme from '@root/brand/src/utils/theme';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function CheckboxInputGroup({ label, children }) {
  return (
    <fieldset css={styles.inputGroupWrapper}>
      {label && <legend css={styles.label}>{label}</legend>}
      {children}
    </fieldset>
  );
}

CheckboxInputGroup.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
};

const styles = StyleSheet.create({
  inputGroupWrapper: {
    display: 'flex',
    flexDirection: 'column',
    '> div:not(:last-child)': {
      marginBottom: 12,
    },
  },
  label: {
    ...Theme.heading1(),
    marginBottom: 24,
  },
});
