import FnolStyles from '@root/claims.joinroot.com/src/assets/fnol-styles';
import PropTypes from '@root/vendor/prop-types';
import RadioInputGroup from '@root/core/src/components/radio-input-group';
import RadioOption from '@root/core/src/components/radio-option';
import React from '@root/vendor/react';

export const INJURED = 'INJURED';
export const UNINJURED = 'UNINJURED';

export default function SubjectInjuryQuestion(props) {
  const {
    currentInjuryType,
    isError,
    onFieldEvent,
  } = props;

  return (
    <fieldset>
      <h4 css={FnolStyles.prompt}>Are you planning to receive medical treatment&mdash;or have you already received medical treatment&mdash;for any injury associated with the accident?</h4>
      <p css={FnolStyles.subtextAfterPrompt}>This includes being treated by EMS, by paramedics, at the hospital, or at an urgent care.</p>
      <RadioInputGroup>
        <RadioOption
          id={'subject_injury_type-INJURED'}
          isError={isError}
          isSelected={currentInjuryType === INJURED}
          label={'Yes'}
          name={'subject_injury_type'}
          onClick={onFieldEvent}
          optionValue={INJURED}
        />
        <RadioOption
          id={'subject_injury_type-UNINJURED'}
          isError={isError}
          isSelected={currentInjuryType === UNINJURED}
          label={'No'}
          name={'subject_injury_type'}
          onClick={onFieldEvent}
          optionValue={UNINJURED}
        />
      </RadioInputGroup>
    </fieldset>
  );
}

SubjectInjuryQuestion.propTypes = {
  currentInjuryType: PropTypes.string,
  isError: PropTypes.bool.isRequired,
  onFieldEvent: PropTypes.func.isRequired,
};
