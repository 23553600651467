import FnolStyles from '@root/claims.joinroot.com/src/assets/fnol-styles.js';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import TextArea from '@root/core/src/components/text-area';
import { StyleSheet } from '@root/core/src/utils/styles';

const MAX_LENGTH = 280;

export default function AdditionalIncidentInfoQuestion(props) {
  const {
    currentAdditionalIncidentInfo,
    errorLabel,
    onFieldEvent,
  } = props;

  const textLength = currentAdditionalIncidentInfo?.length || 0;

  return (
    <fieldset>
      <h4 css={FnolStyles.prompt}>Please briefly describe the incident.</h4>
      <TextArea
        ariaLabel={'Additional Incident Info'}
        errorLabel={errorLabel}
        maxLength={MAX_LENGTH}
        name={'additional_incident_info'}
        onBlur={onFieldEvent}
        onChange={onFieldEvent}
        placeholder={'Describe what happened, and any additional details you’d like us to know.'}
        value={currentAdditionalIncidentInfo || ''}
      />
      <p css={styles.rightJustified}>{textLength}/{MAX_LENGTH}</p>
    </fieldset>
  );
}

AdditionalIncidentInfoQuestion.propTypes = {
  currentAdditionalIncidentInfo: PropTypes.string,
  errorLabel: PropTypes.string,
  onFieldEvent: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  rightJustified: {
    textAlign: 'right',
  },
});
