import AnalyticsService from '@root/core/src/services/analytics-service';
import ExpiredToken from '@root/claims.joinroot.com/src/components/snol/expired-token';
import FeedbackForm from '@root/claims.joinroot.com/src/components/snol/feedback-form';
import InvolvedPartyForm from '@root/claims.joinroot.com/src/components/snol/involved-party-form';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import SuccessfulSubmission from '@root/claims.joinroot.com/src/components/snol/successful-submission';

export default class SnolPortal extends React.Component {
  static propTypes = {
    addressCity: PropTypes.string,
    addressState: PropTypes.string,
    addressStreet1: PropTypes.string,
    addressStreet2: PropTypes.string,
    addressZip: PropTypes.string,
    documentUploadAuthorization: PropTypes.string,
    documentUploadUrl: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    firstSubmission: PropTypes.string,
    involvedPartyId: PropTypes.string,
    involvementType: PropTypes.string,
    lastName: PropTypes.string,
    mixpanelToken: PropTypes.string,
    phoneNumber: PropTypes.string,
    token: PropTypes.string.isRequired,
    tokenExpired: PropTypes.oneOf(['true', 'false']).isRequired,
    vehicles: PropTypes.string,
  };

  static defaultProps = {
    involvementType: 'unknown',
  }

  state = {
    feedbackSubmitted: false,
    firstName: this.props.firstName,
    snolSubmitted: false,
    tokenExpired: this.props.tokenExpired,
  };

  _snolSubmissionDone = (firstName, subsequentNoticeOfLossId) => {
    this.setState({
      firstName,
      snolSubmitted: true,
      subsequentNoticeOfLossId,
    });
  };

  _submissionFailure = () => {
    this.setState({
      tokenExpired: 'true',
    });
  }

  _feedbackSubmissionDone = () => {
    this.setState({
      feedbackSubmitted: true,
    });
  }

  componentDidMount() {
    if (this.props.tokenExpired === 'false') {
      AnalyticsService.initMixpanel(this.props.involvedPartyId);
      AnalyticsService.register({
        involvedPartyId: this.props.involvedPartyId,
        involvementType: this.props.involvementType,
      });
      AnalyticsService.trackViewEvent('CLAIMS_SNOL');
      AnalyticsService.trackLinks('#footer-phone-link', 'CLAIMS_SNOL_FOOTER_PHONE_LINK_CLICK');
      AnalyticsService.trackLinks('#footer-email-link', 'CLAIMS_SNOL_FOOTER_EMAIL_LINK_CLICK');
    }
  }

  render() {
    if (this.state.tokenExpired === 'true') {
      return <ExpiredToken />;
    }

    if (!this.state.snolSubmitted) {
      return (
        <InvolvedPartyForm
          addressCity={this.props.addressCity}
          addressState={this.props.addressState}
          addressStreet1={this.props.addressStreet1}
          addressStreet2={this.props.addressStreet2}
          addressZip={this.props.addressZip}
          documentUploadAuthorization={this.props.documentUploadAuthorization === undefined ? {} : JSON.parse(this.props.documentUploadAuthorization)}
          documentUploadUrl={this.props.documentUploadUrl}
          email={this.props.email}
          firstName={this.props.firstName}
          firstSubmission={this.props.firstSubmission}
          involvementType={this.props.involvementType}
          lastName={this.props.lastName}
          phoneNumber={this.props.phoneNumber}
          submissionDoneHandler={this._snolSubmissionDone}
          submissionFailedHandler={this._submissionFailure}
          token={this.props.token}
          vehicles={this.props.vehicles === undefined ? [] : JSON.parse(this.props.vehicles)}
        />
      );
    } else if (!this.state.feedbackSubmitted) {
      return (
        <FeedbackForm
          appliesToId={this.state.subsequentNoticeOfLossId}
          appliesToType={'SubsequentNoticeOfLoss'}
          firstName={this.state.firstName}
          submissionDoneHandler={this._feedbackSubmissionDone}
          submissionFailedHandler={this._submissionFailure}
          token={this.props.token}
        />
      );
    } else {
      return <SuccessfulSubmission firstName={this.state.firstName} />;
    }
  }
}
