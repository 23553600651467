import FnolStyles from '@root/claims.joinroot.com/src/assets/fnol-styles';
import Input, { TextTypes } from '@root/core/src/components/input';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Select from '@root/core/src/components/select';
import { StatePickerOptions } from '@root/claims.joinroot.com/src/models/picker-options';

export default function MailingAddressQuestion(props) {
  const {
    currentAddressCity,
    currentAddressState,
    currentAddressStreet1,
    currentAddressStreet2,
    currentAddressZip,
    inputStyleCity,
    inputStyleStreet1,
    inputStyleStreet2,
    inputStyleZip,
    onCityFieldEvent,
    onStateFieldEvent,
    onStreet1FieldEvent,
    onStreet2FieldEvent,
    onZipFieldEvent,
    zipErrorLabel,
    name,
  } = props;

  return (
    <fieldset>
      <h4 css={FnolStyles.prompt}>What is your mailing address?</h4>
      <Input
        label={'Street address 1'}
        name={`${name}_address_street1`}
        onChange={onStreet1FieldEvent}
        value={currentAddressStreet1 || ''}
        wrapperStyle={inputStyleStreet1}
      />
      <Input
        label={'Street address 2'}
        name={`${name}_address_street2`}
        onChange={onStreet2FieldEvent}
        value={currentAddressStreet2 || ''}
        wrapperStyle={inputStyleStreet2}
      />
      <Input
        id={`${name}AddressCity-input`}
        label={'City'}
        name={`${name}_address_city`}
        onChange={onCityFieldEvent}
        value={currentAddressCity || ''}
        wrapperStyle={inputStyleCity}
      />
      <Select
        inputId={`${name}_address_state`}
        inputName={`${name}_address_state`}
        onChange={onStateFieldEvent}
        options={StatePickerOptions}
        placeholder={'State'}
        selectedValue={currentAddressState || ''}
      />
      <Input
        errorLabel={zipErrorLabel}
        inputType={TextTypes.TEXT}
        label={'Postal code'}
        name={`${name}_address_zip`}
        onBlur={onZipFieldEvent}
        onChange={onZipFieldEvent}
        value={currentAddressZip || ''}
        wrapperStyle={inputStyleZip}
      />
    </fieldset>
  );
}

MailingAddressQuestion.propTypes = {
  currentAddressCity: PropTypes.string,
  currentAddressState: PropTypes.string,
  currentAddressStreet1: PropTypes.string,
  currentAddressStreet2: PropTypes.string,
  currentAddressZip: PropTypes.string,
  inputStyleCity: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  inputStyleStreet1: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  inputStyleStreet2: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  inputStyleZip: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  name: PropTypes.string.isRequired,
  onCityFieldEvent: PropTypes.func.isRequired,
  onStateFieldEvent: PropTypes.func.isRequired,
  onStreet1FieldEvent: PropTypes.func.isRequired,
  onStreet2FieldEvent: PropTypes.func.isRequired,
  onZipFieldEvent: PropTypes.func.isRequired,
  zipErrorLabel: PropTypes.string,
};
