import AnalyticsService from '@root/core/src/services/analytics-service';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import map from '@root/vendor/lodash/map';
import range from '@root/vendor/lodash/range';

export default class StarRating extends React.Component {
  static propTypes = {
    feedback: PropTypes.string,
    numberOfStars: PropTypes.number,
    onRatingChange: PropTypes.func.isRequired,
    rating: PropTypes.number,
  }

  static defaultProps = {
    feedback: '',
    numberOfStars: 5,
    rating: 0,
    ratingHovered: null,
  }

  state = {
    feedback: this.props.feedback,
    rating: this.props.rating,
    touchStarted: false,
    resyncRatingHovered: null,
  };

  _handleStarClick = (rating) => {
    return () => {
      AnalyticsService.trackEvent('CLAIMS_SNOL_FEEDBACK_CLICK_STAR');
      if (this.state.resyncRatingHovered) {
        this.setState({
          ratingHovered: rating,
          resyncRatingHovered: false,
        });
      }
      this._setRating(rating);
    };
  }

  _setRating = (rating) => {
    this.setState({
      rating,
    }, () => this.props.onRatingChange(rating));
  }

  _setStarMouseOver = (rating) => {
    return () => {
      if (!this.state.touchStarted) {
        this.setState({
          ratingHovered: rating,
        });
      } else if (rating !== null) {
        this.setState({
          resyncRatingHovered: true,
        });
      }
    };
  }

  _handleKeydown = (rating) => {
    return (event) => {
      if (event.keyCode === 32) {
        this._setRating(rating);
      }
    };
  }

  _handleTouchEnd = () => {
    this.setState({
      touchStarted: false,
    });
  }

  _handleTouchStart = () => {
    this.setState({
      touchStarted: true,
    });
  }

  _handlOnFocus = (e) => {
    if (this.state.touchStarted) {
      e.target.blur();
    }
  }

  render() {
    const stars = map(range(1, this.props.numberOfStars + 1), (number) => {
      let klass = 'fa fa-3x fa-star rate';
      if (this.state.rating >= number) {
        klass += ' is-selected';
      }
      if (this.state.ratingHovered >= number) {
        klass += ' is-hovered';
      }
      const testId = `rating-${number}`;
      return (
        <span
          className={klass}
          data-testid={testId}
          key={number.toString()}
          onClick={this._handleStarClick(number)}
          onFocus={this._handlOnFocus}
          onKeyDown={this._handleKeydown(number)}
          onMouseOut={this._setStarMouseOver(null)}
          onMouseOver={this._setStarMouseOver(number)}
          onTouchEnd={this._handleTouchEnd}
          onTouchStart={this._handleTouchStart}
          tabIndex={0}
        />
      );
    });

    return (
      <div className={'star-ratings'}>
        {stars}
      </div>
    );
  }
}
