import FnolStyles from '@root/claims.joinroot.com/src/assets/fnol-styles.js';
import Input, { TextTypes } from '@root/core/src/components/input';
import Modal from '@root/core/src/components/modal';
import PhoneNumberInput from '@root/core/src/components/phone-number-input';
import PropTypes from '@root/vendor/prop-types';
import RadioInputGroup from '@root/core/src/components/radio-input-group';
import RadioOption from '@root/core/src/components/radio-option';
import React, { useContext, useState } from '@root/vendor/react';
import Select from '@root/core/src/components/select';
import VINHelp from '@root/claims.joinroot.com/src/svg/vin-help';
import Validator from '@root/claims.joinroot.com/src/utils/fnol/describe-claim-validator';
import { ALTERNATE_PHONE_NUMBER_MASK } from '@root/core/src/models/phone';
import { FnolContext, FnolDispatchContext, actionTypes } from '@root/claims.joinroot.com/src/contexts/fnol-context-provider';
import { StatePickerOptions } from '@root/claims.joinroot.com/src/models/picker-options';
import { StyleSheet } from '@root/core/src/utils/styles';
import { VehicleMileageRangeTypes } from '@root/claims.joinroot.com/src/models/claims';

export default function VehicleInformation(props) {
  const { refs, showError } = props;

  const state = useContext(FnolContext);
  const dispatch = useContext(FnolDispatchContext);
  const [showVINHelpModal, setShowVINHelpModal] = useState(false);

  const _handleFieldEvent = (key) => {
    return (value) => {
      value = value?.target?.value || value;
      value = value === 'true' ? true : value;
      value = value === 'false' ? false : value;
      const validatedForm = Validator.validateNewState(key, value, state);

      _dispatchForm(validatedForm);
    };
  };

  const handleVINHelpClicked = (e) => {
    e.preventDefault();
    setShowVINHelpModal(true);
  };

  const _handleVehicleSelectionEvent = (vehicle) => {
    return () => {
      const newState = {};
      if (vehicle === 'different_vehicle') {
        newState.vehicleSelection = 'different_vehicle';
        newState.vehicleMake = '';
        newState.vehicleModel = '';
        newState.vehicleYear = '';
        newState.vehicleVin = '';
      } else {
        newState.vehicleSelection = vehicle.vin;
        newState.vehicleMake = vehicle.make;
        newState.vehicleModel = vehicle.model;
        newState.vehicleYear = vehicle.year.toString();
        newState.vehicleVin = vehicle.vin;
      }
      _dispatchForm(newState);
    };
  };

  const _dispatchForm = (form) => {
    dispatch({
      type: actionTypes.SET_CLAIM_FORM_FOR_FNOL,
      form,
    });
  };

  const _errorLabel = (key, requiredMsg) => {
    if (!showError.fields) { return; }

    if (state.errors[key]) {
      return state.errors[key];
    }

    if (requiredMsg && !state[key]) {
      return requiredMsg;
    }
  };

  const styles = StyleSheet.create({
    inputMarginBottom: {
      marginBottom: '10px',
    },
    inputMarginTop: {
      marginTop: '12px',
    },
    block: {
      display: 'block',
    },
  });

  const _renderVehicleOwnerInfo = () => {
    if (state.subjectOwnsVehicle === false) {
      return (
        <fieldset>
          <h4 css={FnolStyles.prompt}>Please enter the owner’s name and phone number.</h4>
          <Input
            errorLabel={_errorLabel('ownerFirstName', 'required')}
            id={'ownerFirstName-input'}
            label={'First name'}
            name={'owner_first_name'}
            onBlur={_handleFieldEvent('ownerFirstName')}
            onChange={_handleFieldEvent('ownerFirstName')}
            value={state.ownerFirstName || ''}
            wrapperStyle={styles.inputMarginBottom}
          />
          <Input
            errorLabel={_errorLabel('ownerLastName', 'required')}
            id={'ownerLastName-input'}
            label={'Last name'}
            name={'owner_last_name'}
            onBlur={_handleFieldEvent('ownerLastName')}
            onChange={_handleFieldEvent('ownerLastName')}
            value={state.ownerLastName || ''}
            wrapperStyle={styles.inputMarginBottom}
          />
          <PhoneNumberInput
            errorLabel={_errorLabel('ownerPhoneNumber', 'Please add the owner\'s phone number')}
            inputId={'ownerPhoneNumber-input'}
            inputMask={ALTERNATE_PHONE_NUMBER_MASK}
            inputType={TextTypes.TEL}
            label={'999-999-9999'}
            name={'owner_phone_number'}
            onBlur={_handleFieldEvent('ownerPhoneNumber')}
            onChange={_handleFieldEvent('ownerPhoneNumber')}
            value={state.ownerPhoneNumber || ''}
          />
        </fieldset>
      );
    }
  };

  const _renderVINHelpModal = () => {
    if (showVINHelpModal) {
      return (
        <Modal
          isShowing={showVINHelpModal}
          name={'vin-modal'}
          onCancel={() => setShowVINHelpModal(false)}
        >
          <p>How do I find my VIN?</p>
          <VINHelp />
          <p>Your Vehicle Identification Number is typically on the doorjamb or the driver side dashboard (look through the windshield from outside the vehicle).</p>
        </Modal>
      );
    }
  };

  const _renderVehicleDriveabilityInfo = () => {
    if (state.vehicleIsSafelyDriveable === false) {
      return (
        <div>
          <fieldset>
            <h4 css={FnolStyles.prompt}>Where is the vehicle currently at?</h4>
            <Input
              id={'vehicle_address_1'}
              label={'Street address 1'}
              name={'vehicle_address_street1'}
              onChange={_handleFieldEvent('vehicleAddressStreet1')}
              value={state.vehicleAddressStreet1 || ''}
              wrapperStyle={styles.inputMarginBottom}
            />
            <Input
              id={'vehicle_address_2'}
              label={'Street address 2'}
              name={'vehicle_address_street2'}
              onChange={_handleFieldEvent('vehicleAddressStreet2')}
              value={state.vehicleAddressStreet2 || ''}
              wrapperStyle={styles.inputMarginBottom}
            />
            <Input
              id={'vehicleAddressCity-input'}
              label={'City'}
              name={'vehicle_address_city'}
              onChange={_handleFieldEvent('vehicleAddressCity')}
              value={state.vehicleAddressCity || ''}
              wrapperStyle={styles.inputMarginBottom}
            />
            <Select
              inputId={'vehicle_address_state'}
              inputName={'vehicle_address_state'}
              onChange={_handleFieldEvent('vehicleAddressState')}
              options={StatePickerOptions}
              placeholder={'State'}
              selectedValue={state.vehicleAddressState || ''}
            />
            <Input
              errorLabel={_errorLabel('vehicleAddressZip')}
              id={'vehicle_address_zip'}
              inputType={TextTypes.TEXT}
              label={'Postal code'}
              name={'vehicle_address_zip'}
              onBlur={_handleFieldEvent('vehicleAddressZip')}
              onChange={_handleFieldEvent('vehicleAddressZip')}
              value={state.vehicleAddressZip || ''}
              wrapperStyle={styles.inputMarginTop}
            />
          </fieldset>
          <h4 css={FnolStyles.prompt}>If necessary, does Root have permission to move the vehicle to get a proper estimate?</h4>
          <RadioInputGroup>
            <RadioOption
              id={'root_has_permission_to_move-true'}
              isError={showError.fields && state.rootHasPermissionToMove === undefined}
              isSelected={state.rootHasPermissionToMove === true}
              label={'Yes'}
              name={'root_has_permission_to_move'}
              onClick={_handleFieldEvent('rootHasPermissionToMove')}
              optionValue={'true'}
            />
            <RadioOption
              id={'root_has_permission_to_move-false'}
              isError={showError.fields && state.rootHasPermissionToMove === undefined}
              isSelected={state.rootHasPermissionToMove === false}
              label={'No'}
              name={'root_has_permission_to_move'}
              onClick={_handleFieldEvent('rootHasPermissionToMove')}
              optionValue={'false'}
            />
          </RadioInputGroup>
        </div>
      );
    }
  };

  const _renderOtherVehicleInfo = () => {
    if (state.vehicleSelection === 'different_vehicle' || state.policy.vehicles === undefined) {
      return (
        <div>
          <fieldset>
            <h6 css={FnolStyles.promptAfterHeader}>What is your vehicle’s Year/Make/Model?</h6>
            <Input
              errorLabel={_errorLabel('vehicleYear', 'required')}
              label={'Vehicle year'}
              name={'vehicle_year'}
              onBlur={_handleFieldEvent('vehicleYear')}
              onChange={_handleFieldEvent('vehicleYear')}
              value={state.vehicleYear || ''}
            />
            <Input
              errorLabel={_errorLabel('vehicleMake', 'required')}
              label={'Vehicle make'}
              name={'vehicle_make'}
              onBlur={_handleFieldEvent('vehicleMake')}
              onChange={_handleFieldEvent('vehicleMake')}
              value={state.vehicleMake || ''}
              wrapperStyle={styles.inputMarginTop}
            />
            <Input
              errorLabel={_errorLabel('vehicleModel', 'required')}
              label={'Vehicle model'}
              name={'vehicle_model'}
              onBlur={_handleFieldEvent('vehicleModel')}
              onChange={_handleFieldEvent('vehicleModel')}
              value={state.vehicleModel || ''}
              wrapperStyle={styles.inputMarginTop}
            />
          </fieldset>
          <fieldset>
            <h4 css={FnolStyles.prompt}>What is your vehicle’s Vehicle Identification Number (VIN)?</h4>
            <Input
              errorLabel={_errorLabel('vehicleVin')}
              label={'VIN'}
              name={'vehicle_vin'}
              onBlur={_handleFieldEvent('vehicleVin')}
              onChange={_handleFieldEvent('vehicleVin')}
              value={state.vehicleVin || ''}
            />
            <a
              className={'input-help'}
              css={styles.block}
              href={'#'}
              onClick={handleVINHelpClicked}
            >How do I find my VIN?
            </a>
          </fieldset>
        </div>
      );
    }
  };

  const _renderVehicleOptionsSection = () => {
    if (state.policy?.vehicles?.length > 0) {
      return (
        <fieldset>
          <h4 css={FnolStyles.prompt}>Which vehicle was involved?</h4>
          {_renderVehicleOptions()}
        </fieldset>
      );
    }
  };

  const _renderVehicleOptions = () => {
    return (
      <RadioInputGroup>
        {
          state.policy.vehicles.map((vehicle) => {
            return (
              <RadioOption
                id={`vehicle_selection-${vehicle.vin}`}
                isError={showError.fields && state.vehicleSelection === undefined}
                isSelected={state.vehicleSelection === vehicle.vin}
                key={vehicle.vin}
                label={`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
                name={'vehicle_selection'}
                onClick={_handleVehicleSelectionEvent(vehicle)}
                optionValue={vehicle.vin}
              />);
          })
        }
        <RadioOption
          id={'vehicle_selection-different_vehicle'}
          isError={showError.fields && state.vehicleSelection === undefined}
          isSelected={state.vehicleSelection === 'different_vehicle'}
          label={'A different vehicle'}
          name={'vehicle_selection'}
          onClick={_handleVehicleSelectionEvent('different_vehicle')}
          optionValue={'different_vehicle'}
        />
      </RadioInputGroup>
    );
  };

  return (
    <>
      <div data-testid={'vehicle_information'}>
        {_renderVINHelpModal()}
        <h4
          css={FnolStyles.secondaryHeader}
          ref={refs.vehicleInfo}
        >
          Your Vehicle
        </h4>
        {_renderVehicleOptionsSection()}
        {_renderOtherVehicleInfo()}
        <fieldset>
          <h4 css={FnolStyles.prompt}>Is the title, lease, or loan for this vehicle in your name?</h4>
          <RadioInputGroup >
            <RadioOption
              id={'subject-owns_vehicle-true-input'}
              isError={showError.fields && state.subjectOwnsVehicle === undefined}
              isSelected={state.subjectOwnsVehicle === true}
              label={'Yes'}
              name={'subject_owns_vehicle'}
              onClick={_handleFieldEvent('subjectOwnsVehicle')}
              optionValue={'true'}
            />
            <RadioOption
              id={'subject-owns_vehicle-false-input'}
              isError={showError.fields && state.subjectOwnsVehicle === undefined}
              isSelected={state.subjectOwnsVehicle === false}
              label={'No'}
              name={'subject_owns_vehicle'}
              onClick={_handleFieldEvent('subjectOwnsVehicle')}
              optionValue={'false'}
            />
          </RadioInputGroup>
        </fieldset>
        {_renderVehicleOwnerInfo()}
        <fieldset>
          <h4 css={FnolStyles.prompt}>What is the current mileage for this vehicle?</h4>
          <Select
            inputId={'vehicle_mileage_range'}
            inputName={'vehicle_mileage_range'}
            onChange={_handleFieldEvent('vehicleMileageRange')}
            options={VehicleMileageRangeTypes}
            placeholder={'Select Mileage'}
            selectedValue={state.vehicleMileageRange || ''}
          />
        </fieldset>
        <fieldset>
          <h4 css={FnolStyles.prompt}>Do you feel the vehicle is safely driveable?</h4>
          <RadioInputGroup>
            <RadioOption
              id={'vehicle_is_safely_driveable-true'}
              isError={showError.fields && state.vehicleIsSafelyDriveable === undefined}
              isSelected={state.vehicleIsSafelyDriveable === true}
              label={'Yes'}
              name={'vehicle_is_safely_driveable'}
              onClick={_handleFieldEvent('vehicleIsSafelyDriveable')}
              optionValue={'true'}
            />
            <RadioOption
              id={'vehicle_is_safely_driveable-false'}
              isError={showError.fields && state.vehicleIsSafelyDriveable === undefined}
              isSelected={state.vehicleIsSafelyDriveable === false}
              label={'No'}
              name={'vehicle_is_safely_driveable'}
              onClick={_handleFieldEvent('vehicleIsSafelyDriveable')}
              optionValue={'false'}
            />
          </RadioInputGroup>
        </fieldset>
        {_renderVehicleDriveabilityInfo()}
      </div>
    </>
  );
}

VehicleInformation.propTypes = {
  refs: PropTypes.shape({
    vehicleInfo: PropTypes.object,
  }),
  showError: PropTypes.shape({
    fields: PropTypes.bool,
  }),
};
