import * as requests from 'axios';
import Recase from 'recase';
import environment from '@root/core/src/utils/environment';
import { RootError } from '@root-common/root-errors';

Promise;
if (typeof Promise === 'undefined') {
  throw new RootError({
    message: 'Promise polyfill was not included',
    name: 'ClaimPortalApiError',
  });
}

const getUrl = (route) => `${environment.apiBaseUrl}${route}`;

const recase = Recase.create({});

const api = (strategy, route) => {
  return (payload) => {
    return strategy(getUrl(route), recase.snakeCopy(payload));
  };
};

const postTo = (route) => {
  return api(requests.post, route);
};

const postToPortal = (route, payload, successCallback, errorCallback) => {
  postTo(route)(payload)
    .then(successCallback)
    .catch(errorCallback);
};

export default {
  postToPortal,
  postTo,
  api,
};
