import UserAgentService from '@root/core/src/services/user-agent-service';

export default function Header() {
  if (UserAgentService.isRootMobileAgent()) { return null; }

  return (
    <header>
      <svg
        aria-label={'Root Insurance Co logo'}
        aria-labelledby={'root-logo-title'}
        fill={'none'}
        role={'img'}
        version={'1.1'}
        viewBox={'0 0 1240 357'}
        width={'169px'}
        xmlns={'http://www.w3.org/2000/svg'}
      >
        <title id={'root-logo-title'}>Root Insurance Co logo</title>
        <path
          d={'M560.507 234.76H573.237V140.057H560.507V234.76Z'}
          fill={'#1D1D21'}
        />
        <path
          d={'M636.247 163.034C626.311 163.034 616.375 168.778 609.855 175.299V164.897H597.435V234.76H609.855V185.7C614.978 180.422 623.672 174.212 631.9 174.212C638.576 174.212 642.457 178.093 642.457 186.477V234.76H654.877V182.595C654.877 168.468 646.028 163.034 636.247 163.034Z'}
          fill={'#1D1D21'}
        />
        <path
          d={'M719.511 168.933C716.251 166.605 708.644 163.034 698.708 163.034C684.89 163.034 674.333 169.865 674.333 183.993C674.333 198.276 686.909 202.468 697.155 205.417C704.142 207.746 710.196 209.92 710.196 216.13C710.196 222.34 704.452 225.91 696.69 225.91C688.306 225.91 680.233 221.563 675.886 218.148L671.384 227.618C675.886 231.965 685.356 236.623 696.845 236.623C710.817 236.623 722.151 229.171 722.151 213.956C722.151 200.294 710.973 196.723 699.95 193.463C692.653 191.134 685.822 188.961 685.822 182.285C685.822 176.23 691.411 173.591 697.932 173.591C705.228 173.591 712.059 176.23 715.785 178.869L719.511 168.933Z'}
          fill={'#1D1D21'}
        />
        <path
          d={'M783.842 164.897V213.956C778.719 219.235 770.025 225.445 761.797 225.445C755.121 225.445 751.24 221.563 751.24 213.18V164.897H738.664V217.061C738.664 231.189 747.669 236.623 757.45 236.623C767.386 236.623 777.322 230.878 783.842 224.358V234.76H796.262V164.897H783.842Z'}
          fill={'#1D1D21'}
        />
        <path
          d={'M831.705 181.664V164.897H819.285V234.76H831.705V191.6C837.76 180.732 849.094 177.162 856.39 175.609V163.034C846.144 163.034 835.742 170.641 831.705 181.664Z'}
          fill={'#1D1D21'}
        />
        <path
          d={'M896.399 163.034C886.308 163.034 875.595 166.294 869.385 170.331L872.335 179.646C876.993 177.317 883.047 174.212 893.139 174.212C899.038 174.212 905.869 175.764 905.869 185.235V194.55L894.846 195.481C875.595 197.189 865.814 204.952 865.814 218.458C865.814 230.878 874.974 236.623 885.376 236.623C893.449 236.623 900.435 233.207 906.024 226.376L906.49 234.76H918.289V182.13C918.289 168.312 908.974 163.034 896.399 163.034ZM888.481 226.376C881.65 226.376 878.235 222.805 878.235 217.061C878.235 209.609 883.979 206.194 891.741 205.262L905.869 203.554V216.906C900.125 224.358 893.604 226.376 888.481 226.376Z'}
          fill={'#1D1D21'}
        />
        <path
          d={'M980.073 163.034C970.137 163.034 960.201 168.778 953.68 175.299V164.897H941.26V234.76H953.68V185.7C958.804 180.422 967.498 174.212 975.726 174.212C982.402 174.212 986.283 178.093 986.283 186.477V234.76H998.703V182.595C998.703 168.468 989.854 163.034 980.073 163.034Z'}
          fill={'#1D1D21'}
        />
        <path
          d={'M1072.65 230.257L1068.62 220.477C1064.42 223.116 1058.83 225.445 1052.47 225.445C1041.14 225.445 1030.58 217.682 1030.58 199.673C1030.58 181.819 1041.14 173.901 1052.47 173.901C1058.06 173.901 1063.34 175.454 1067.53 178.093L1071.25 167.691C1066.13 165.207 1059.61 163.034 1052 163.034C1032.13 163.034 1017.38 176.851 1017.38 199.673C1017.38 222.65 1032.13 236.467 1052 236.467C1061.47 236.467 1067.99 233.207 1072.65 230.257Z'}
          fill={'#1D1D21'}
        />
        <path
          d={'M1142.96 192.066C1142.96 176.851 1132.87 163.034 1114.39 163.034C1096.07 163.034 1081.48 177.162 1081.48 200.139C1081.48 222.805 1095.14 236.623 1115.94 236.623C1127.28 236.623 1135.66 232.431 1141.09 228.084L1137.21 219.079C1132.87 222.34 1126.35 225.445 1118.12 225.445C1104.92 225.445 1094.67 217.527 1094.52 199.983H1142.96V192.066ZM1114.08 173.125C1123.71 173.125 1129.3 179.025 1130.69 190.513H1095.45C1097.93 178.714 1105.23 173.125 1114.08 173.125Z'}
          fill={'#1D1D21'}
        />
        <path
          d={'M171.288 194.185L173.569 193.047C188.12 185.715 196.468 172.872 196.468 157.797C196.468 146.367 192.819 137.395 185.566 131.064C176.762 123.368 162.575 119.497 143.462 119.497C127.587 119.497 107.561 122.184 99.4873 123.322V238.133H131.1V199.104H144.602L167 238.133H202.033L171.288 194.185ZM142.732 180.067H131.1V142.587L132.331 142.359C134.977 141.903 138.946 141.585 142.139 141.585C155.869 141.585 163.442 148.416 163.442 160.803C163.442 171.824 154.547 180.067 142.732 180.067Z'}
          fill={'#1D1D21'}
        />
        <path
          d={'M298.148 148.325C288.204 139.399 274.382 134.48 259.237 134.48C244.275 134.48 230.59 139.399 220.691 148.325C209.88 158.071 204.178 171.961 204.178 188.493C204.178 205.024 209.88 218.869 220.691 228.615C230.59 237.541 244.275 242.414 259.237 242.414C274.382 242.414 288.204 237.496 298.148 228.615C308.959 218.915 314.661 205.024 314.661 188.493C314.707 171.915 308.959 158.025 298.148 148.325ZM273.561 211.992C270.094 216.729 265.258 219.233 259.465 219.233C253.717 219.233 248.882 216.729 245.415 211.992C241.538 206.664 239.576 198.74 239.576 188.493C239.576 178.2 241.538 170.276 245.415 164.948C248.882 160.166 253.717 157.661 259.465 157.661C265.213 157.661 270.094 160.166 273.561 164.948C277.438 170.276 279.4 178.2 279.4 188.493C279.4 198.74 277.438 206.664 273.561 211.992Z'}
          fill={'#1D1D21'}
        />
        <path
          d={'M481.344 198.876V168.09V166.633H482.804H504.198V143.224H482.804H481.344V141.767V120.317L448.546 130.245L448.683 208.303C448.683 218.004 451.648 226.292 457.213 232.258C462.915 238.361 470.944 241.594 480.523 241.594C491.927 241.594 503.651 239.773 509.626 237.905V210.353C506.068 211.4 501.005 212.402 494.755 212.402C483.625 212.402 481.344 206.983 481.344 198.876Z'}
          fill={'#1D1D21'}
        />
        <path
          d={'M420.4 148.325C410.456 139.399 396.634 134.48 381.489 134.48C366.527 134.48 352.842 139.399 342.943 148.325C332.132 158.071 326.43 171.961 326.43 188.493C326.43 205.024 332.132 218.869 342.943 228.615C352.842 237.541 366.527 242.414 381.489 242.414C396.634 242.414 410.456 237.496 420.4 228.615C431.212 218.915 436.914 205.024 436.914 188.493C436.959 171.915 431.212 158.025 420.4 148.325ZM395.813 211.992C392.346 216.729 387.511 219.233 381.718 219.233C375.97 219.233 371.134 216.729 367.668 211.992C363.79 206.664 361.829 198.74 361.829 188.493C361.829 178.2 363.79 170.276 367.668 164.948C371.134 160.166 375.97 157.661 381.718 157.661C387.465 157.661 392.346 160.166 395.813 164.948C399.69 170.276 401.652 178.2 401.652 188.493C401.652 198.74 399.69 206.664 395.813 211.992Z'}
          fill={'#1D1D21'}
        />
      </svg>
    </header>
  );
}
