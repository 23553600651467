import AdditionalIncidentInfoQuestion from '@root/claims.joinroot.com/src/components/fnol/questions/additional-incident-info-question';
import AnalyticsService from '@root/core/src/services/analytics-service';
import Button from '@root/core/src/components/button';
import DocumentUpload from '@root/claims.joinroot.com/src/components/core/document-upload';
import DriverInformation from '@root/claims.joinroot.com/src/components/fnol/describe-claim-form/driver-information';
import EmailQuestion from '@root/claims.joinroot.com/src/components/fnol/questions/email-question';
import FnolStyles from '@root/claims.joinroot.com/src/assets/fnol-styles.js';
import IncidentDatetimeQuestion from '@root/claims.joinroot.com/src/components/fnol/questions/incident-datetime-question';
import IncidentLocationQuestion from '@root/claims.joinroot.com/src/components/fnol/questions/incident-location-question';
import IncidentTypeQuestion from '@root/claims.joinroot.com/src/components/fnol/questions/incident-type-question';
import InjuryCategoriesQuestion from '@root/claims.joinroot.com/src/components/fnol/questions/injury-categories-question';
import MailingAddressQuestion from '@root/claims.joinroot.com/src/components/fnol/questions/mailing-address-question';
import NameQuestion from '@root/claims.joinroot.com/src/components/fnol/questions/name-question';
import PhoneNumberQuestion from '@root/claims.joinroot.com/src/components/fnol/questions/phone-number-question';
import PreExistingConditionsExplanationQuestion from '@root/claims.joinroot.com/src/components/fnol/questions/pre-existing-conditions-explanation-question';
import PropTypes from '@root/vendor/prop-types';
import React, { useContext, useEffect, useState } from '@root/vendor/react';
import SubjectInjuryQuestion from '@root/claims.joinroot.com/src/components/fnol/questions/subject-injury-question';
import Validator from '@root/claims.joinroot.com/src/utils/fnol/describe-claim-validator';
import VehicleDamages from '@root/claims.joinroot.com/src/components/fnol/describe-claim-form/vehicle-damages';
import VehicleInformation from '@root/claims.joinroot.com/src/components/fnol/describe-claim-form/vehicle-information';
import ViewportService from '@root/claims.joinroot.com/src/services/viewport-service';
import isString from '@root/vendor/lodash/isString';
import { ACCEPTED_IMAGE_FILES } from '@root/claims.joinroot.com/src/models/accepted-files';
import { CustomIncidentType, FirstPartyInjuryCategories } from '@root/claims.joinroot.com/src/models/claims';
import { FnolContext, FnolDispatchContext, actionTypes } from '@root/claims.joinroot.com/src/contexts/fnol-context-provider';
import { NON_EMPTY_STRING_REGEX } from '@root/core/src/utils/validators';
import { StyleSheet } from '@root/core/src/utils/styles';
import { progressFnolForward } from '@root/claims.joinroot.com/src/utils/fnol/progress-helper';
import { useHistory } from '@root/vendor/react-router-dom';

DescribeClaimForm.propTypes = {
  initDropzone: PropTypes.func,
};

DescribeClaimForm.submitErrorMsg = 'Some of the information we need is missing or incomplete. Please check the highlighted fields and try again.';

export default function DescribeClaimForm(props) {
  const progressName = 'describe-claim';
  const [documentsAreUploading, setDocumentsAreUploading] = useState(false);
  const [showError, setShowError] = useState({
    fields: false,
    submit: false,
  });

  const [hasViewed, setHasViewed] = useState({
    personalInfo: false,
    submitError: false,
    vehicleInfo: false,
    vehiclePhotos: false,
  });
  const dispatch = useContext(FnolDispatchContext);
  const history = useHistory();
  const refs = {
    dropzone: React.createRef(),
    personalInfo: React.createRef(),
    submitError: React.createRef(),
    vehicleInfo: React.createRef(),
    vehiclePhotos: React.createRef(),
  };
  const refViewEvents = {
    personalInfo: 'FNOL_WEB_DESCRIBECLAIMFORM_PERSONALINFO',
    submitError: 'FNOL_WEB_DESCRIBECLAIMFORM_ERRORSTATE',
    vehicleInfo: 'FNOL_WEB_DESCRIBECLAIMFORM_YOURVEHICLE',
    vehiclePhotos: 'FNOL_WEB_DESCRIBECLAIMFORM_VEHICLEPHOTOS',
  };
  const state = useContext(FnolContext);

  useEffect(() => {
    AnalyticsService.trackViewEvent('FNOL_WEB_DESCRIBECLAIMFORM');
  }, []);

  const _fireViewportAnalytics = () => {
    let ref;
    for (const refKey in refViewEvents) {
      ref = refs[refKey].current;
      if (ref && ViewportService.isInViewport(ref) && !hasViewed[refKey]) {
        AnalyticsService.trackViewEvent(refViewEvents[refKey]);
        setHasViewed((prevState) => {
          return {
            ...prevState,
            [refKey]: true,
          };
        });
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', _fireViewportAnalytics);
    return () => window.removeEventListener('scroll', _fireViewportAnalytics);
  });

  const styles = StyleSheet.create({
    inputMarginBottom: {
      marginBottom: '12px',
    },
    inputMarginTop: {
      marginTop: '12px',
    },
    listMargins: {
      marginTop: '23px',
      marginBottom: '23px',
    },
  });

  const _dispatchForm = (form) => {
    dispatch({
      type: actionTypes.SET_CLAIM_FORM_FOR_FNOL,
      form,
    });
  };

  const _handleFieldEvent = (key) => {
    return (value) => {
      value = value?.target?.value || value;
      value = value === 'true' ? true : value;
      value = value === 'false' ? false : value;
      const validatedForm = Validator.validateNewState(key, value, state);

      _dispatchForm(validatedForm);
    };
  };

  const _handleDocumentUpload = () => {
    setDocumentsAreUploading(true);
    AnalyticsService.trackEvent('FNOL_WEB_DESCRIBECLAIMFORM_VEHICLEPHOTOS', 'EDIT');
  };

  const _isNullOrEmptyString = (value) => {
    return isString(value) ? !NON_EMPTY_STRING_REGEX.test(value) : !value;
  };

  const _errorLabel = (key, requiredMsg) => {
    if (!showError.fields) { return; }

    if (state.errors[key]) {
      return state.errors[key];
    }

    if (requiredMsg && _isNullOrEmptyString(state[key])) {
      return requiredMsg;
    }
  };

  const _renderErrorMessages = () => {
    if (!showError.submit) { return ''; }

    return (
      <div
        className={'errors'}
        ref={refs.submitError}
      >
        <p>{DescribeClaimForm.submitErrorMsg}</p>
      </div>
    );
  };

  const _submitClaimForm = () => {
    AnalyticsService.trackClickEvent('FNOL_WEB_DESCRIBECLAIMFORM_NEXTSTEP');
    if (hasViewed.submitError) {
      AnalyticsService.trackEvent('FNOL_WEB_DESCRIBECLAIMFORM_ERRORSTATEVIEW', 'SUBMIT');
    }

    if (!Validator.isValid(state)) {
      setShowError({
        fields: true,
        submit: true,
      });

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });

      return;
    }

    progressFnolForward(progressName, dispatch, state);
    history.push('/portal/fnol/step3');
  };

  const additionalIncidentInfoErrorLabel = () => {
    if (state.incidentType?.value === CustomIncidentType) {
      return;
    }

    return _errorLabel('additionalIncidentInfo', 'required');
  };

  const documentUploadAuthorization = JSON.parse(state.documentUploadAuthorization);

  const _atLeastOneOptionIsSelected = (options) => {
    return Object.values(options).some((value) => value);
  };

  const _injuryTypeIsInjured = state.subjectInjuryType === 'INJURED';

  const _preExistingConditionIsSelected = state.subjectInjuryCategories?.pre_existing_injury || false;

  return (
    <div>
      <h4 css={FnolStyles.primaryHeader}>File a Claim: Step 2</h4>
      <p css={FnolStyles.subtext}>We know filing a claim can be confusing. Don’t worry if you don’t have all the information right now, you’ll get to speak with a claims specialist as soon as possible.</p>
      {_renderErrorMessages()}

      <IncidentTypeQuestion
        currentIncidentType={state.incidentType}
        isClaimant={state.policy?.number === undefined}
        onFieldEvent={_handleFieldEvent('incidentType')}
      />
      <IncidentDatetimeQuestion
        currentIncidentDate={state.incidentDate}
        currentIncidentTime={state.incidentTime}
        onDateFieldEvent={_handleFieldEvent('incidentDate')}
        onTimeFieldEvent={_handleFieldEvent('incidentTime')}
        showErrorFields={showError.fields}
      />
      <IncidentLocationQuestion
        errorLabel={_errorLabel('incidentLocationCity', 'required')}
        incidentLocationCity={state.incidentLocationCity}
        incidentLocationState={state.incidentLocationState}
        inputStyle={styles.inputMarginTop}
        onCityFieldEvent={_handleFieldEvent('incidentLocationCity')}
        onStateFieldEvent={_handleFieldEvent('incidentLocationState')}
      />
      <AdditionalIncidentInfoQuestion
        currentAdditionalIncidentInfo={state.additionalIncidentInfo}
        errorLabel={additionalIncidentInfoErrorLabel()}
        onFieldEvent={_handleFieldEvent('additionalIncidentInfo')}
      />
      <SubjectInjuryQuestion
        currentInjuryType={state.subjectInjuryType}
        isError={showError.fields && !state.subjectInjuryType}
        onFieldEvent={_handleFieldEvent('subjectInjuryType')}
      />
      {_injuryTypeIsInjured &&
        <InjuryCategoriesQuestion
          currentName={'you'}
          currentSelectedInjuryCategories={state.subjectInjuryCategories}
          injuryCategories={FirstPartyInjuryCategories}
          inputKey={'subject'}
          isError={showError.fields && !_atLeastOneOptionIsSelected(state.subjectInjuryCategories)}
          onFieldEvent={_handleFieldEvent('subjectInjuryCategories')}
        />
      }
      {_injuryTypeIsInjured && _preExistingConditionIsSelected &&
        <PreExistingConditionsExplanationQuestion
          currentPreExistingConditionsExplanation={state.subjectPreExistingConditionsExplanation}
          errorLabel={_errorLabel('subjectPreExistingConditionsExplanation', 'required')}
          onFieldEvent={_handleFieldEvent('subjectPreExistingConditionsExplanation')}
        />
      }
      <h2
        css={FnolStyles.secondaryHeader}
        ref={refs.personalInfo}
      >
        Personal Information
      </h2>
      <NameQuestion
        currentFirstName={state.subjectFirstName}
        currentLastName={state.subjectLastName}
        firstNameErrorLabel={_errorLabel('subjectFirstName', 'required')}
        headerStyle={FnolStyles.promptAfterHeader}
        inputStyle={styles.inputMarginTop}
        lastNameErrorLabel={_errorLabel('subjectLastName', 'required')}
        name={'subject'}
        onFirstNameFieldEvent={_handleFieldEvent('subjectFirstName')}
        onLastNameFieldEvent={_handleFieldEvent('subjectLastName')}
      />
      <PhoneNumberQuestion
        currentPhoneNumber={state.subjectPhoneNumber}
        errorLabel={_errorLabel('subjectPhoneNumber', 'Please add your phone number')}
        name={'subject'}
        onFieldEvent={_handleFieldEvent('subjectPhoneNumber')}
      />
      <EmailQuestion
        currentEmail={state.subjectEmail}
        errorLabel={_errorLabel('subjectEmail')}
        name={'subject'}
        onFieldEvent={_handleFieldEvent('subjectEmail')}
      />
      <MailingAddressQuestion
        currentAddressCity={state.subjectAddressCity}
        currentAddressState={state.subjectAddressState}
        currentAddressStreet1={state.subjectAddressStreet1}
        currentAddressStreet2={state.subjectAddressStreet2}
        currentAddressZip={state.subjectAddressZip}
        inputStyleCity={styles.inputMarginBottom}
        inputStyleStreet1={styles.inputMarginBottom}
        inputStyleStreet2={styles.inputMarginBottom}
        inputStyleZip={styles.inputMarginTop}
        name={'subject'}
        onCityFieldEvent={_handleFieldEvent('subjectAddressCity')}
        onStateFieldEvent={_handleFieldEvent('subjectAddressState')}
        onStreet1FieldEvent={_handleFieldEvent('subjectAddressStreet1')}
        onStreet2FieldEvent={_handleFieldEvent('subjectAddressStreet2')}
        onZipFieldEvent={_handleFieldEvent('subjectAddressZip')}
        zipErrorLabel={_errorLabel('subjectAddressZip')}
      />
      <DriverInformation showError={showError} />
      <VehicleInformation
        refs={refs}
        showError={showError}
      />
      <VehicleDamages showError={showError} />
      <fieldset>
        <h2
          css={FnolStyles.secondaryHeader}
          ref={refs.vehiclePhotos}
        >
          Vehicle Photos
        </h2>
        <div css={[FnolStyles.subtext, styles.inputMarginTop, styles.inputMarginBottom]}>
          <p>Use the box below to upload any photos you think might be helpful. These should include:</p>
          <ul
            className={'dashed'}
            css={styles.listMargins}
          >
            <li>Photos showing your entire vehicle from each corner</li>
            <li>Photos of the damage from different angles</li>
            <li>VIN number</li>
            <li>Odometer/mileage</li>
          </ul>
          <p>These photos will help us resolve your claim up to 60% faster.<br /> </p>
        </div>
        <DocumentUpload
          acceptedFiles={ACCEPTED_IMAGE_FILES}
          documents={state.documents}
          documentUploadAuthorization={documentUploadAuthorization}
          documentUploadUrl={state.documentUploadUrl}
          dropzoneRef={refs.dropzone}
          initDropzone={props.initDropzone}
          onChange={_handleFieldEvent('documents')}
          onUploadComplete={() => setDocumentsAreUploading(false)}
          onUploadStart={_handleDocumentUpload}
        />
      </fieldset>
      <Button
        css={FnolStyles.continueButtonMargin}
        disabled={documentsAreUploading}
        onClick={_submitClaimForm}
      >
        {documentsAreUploading ? 'Uploading photos. Please wait.' : 'Next Step'}
      </Button>
    </div>
  );
}
