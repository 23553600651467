import Regex from '@root/claims.joinroot.com/src/utils/regex';
import isEmpty from '@root/vendor/lodash/isEmpty';
import { EMAIL_REGEX } from '@root/core/src/utils/validators';

const requiredFields = [
  'filerPartyType',
  'filerFirstName',
  'filerLastName',
  'filerPhoneNumber',
];

const validators = {
  filerAddressZip: {
    test: Regex.validate(Regex.ZIP_CODE),
    message: 'Invalid postal code',
  },
  filerPhoneNumber: {
    test: Regex.validate(Regex.PHONE_NUMBER),
    message: 'Invalid phone number',
  },
  filerEmail: {
    test: Regex.validate(EMAIL_REGEX),
    message: 'Invalid email address',
  },
};

const isValid = (state) => {
  const isNotEmpty = (field) => ![null, undefined, ''].includes(state[field]);

  return isEmpty(state.errors) && requiredFields.every(isNotEmpty);
};

const validateNewState = (key, value, state) => {
  const validator = validators[key];
  if (!validator) {
    return {
      ...state,
      [key]: value,
    };
  }

  const currentErrors = {
    ...state.errors,
  };
  delete currentErrors[key];

  if (value && !validator.test(value)) {
    currentErrors[key] = validator.message;
  }

  return {
    ...state,
    [key]: value,
    errors: currentErrors,
  };
};

export default {
  isValid,
  validateNewState,
};
