import AnalyticsService from '@root/core/src/services/analytics-service';
import Button from '@root/core/src/components/button';
import FnolStyles from '@root/claims.joinroot.com/src/assets/fnol-styles.js';
import RadioInputGroup from '@root/core/src/components/radio-input-group';
import RadioOption from '@root/core/src/components/radio-option';
import React, { useContext, useEffect } from '@root/vendor/react';
import Strings from '@root/claims.joinroot.com/src/utils/strings';
import { CallerTypes, SubjectInvolvedPartyTypes } from '@root/claims.joinroot.com/src/models/claims';
import { FnolContext, FnolDispatchContext, actionTypes } from '@root/claims.joinroot.com/src/contexts/fnol-context-provider';
import { progressFnolForward, progressFnolSet } from '@root/claims.joinroot.com/src/utils/fnol/progress-helper';
import { useHistory } from '@root/vendor/react-router-dom';

export default function CallerSelect() {
  const context = useContext(FnolContext);
  const dispatch = useContext(FnolDispatchContext);
  const history = useHistory();

  useEffect(() => {
    progressFnolSet([], dispatch);
    AnalyticsService.trackViewEvent('FNOL_WEB_CALLERSELECT');
  }, [dispatch]);

  function _dispatchCaller(callerType, subjectInvolvedPartyType, driver) {
    const partyTypeEventComponent = subjectInvolvedPartyType ? `_${subjectInvolvedPartyType}` : '';
    AnalyticsService.trackClickEvent('FNOL_WEB_CALLERSELECT', `${callerType}${partyTypeEventComponent}`);
    dispatch({
      type: actionTypes.SET_CALLER_FOR_FNOL,
      subjectInvolvedPartyType,
      callerType,
      driver,
    });
  }

  function _handleCallerSubmission() {
    AnalyticsService.trackClickEvent('FNOL_WEB_CALLERSELECT', 'GETSTARTED');
    if (context.caller.callerType === CallerTypes.THIRD_PARTY) {
      progressFnolForward('third-party-select', dispatch, context);
      history.push('/portal/fnol/represented-party');
    } else {
      progressFnolForward('caller-select', dispatch, context);
      history.push('/portal/fnol/step2');
    }
  }

  const unlistedCaller = (
    <RadioOption
      id={'unlisted-caller-option'}
      isSelected={context.caller.subjectInvolvedPartyType === SubjectInvolvedPartyTypes.UNLISTED}
      key={'unlisted-caller-option'}
      label={'Unlisted driver/passenger'}
      name={SubjectInvolvedPartyTypes.UNLISTED}
      onClick={() => { _dispatchCaller(CallerTypes.FIRST_PARTY, SubjectInvolvedPartyTypes.UNLISTED); }}
      optionValue={SubjectInvolvedPartyTypes.UNLISTED}
    />
  );

  const claimantCaller = (
    <RadioOption
      id={'claimant-caller-option'}
      isSelected={context.caller.subjectInvolvedPartyType === SubjectInvolvedPartyTypes.CLAIMANT}
      key={'claimant-caller-option'}
      label={'Claimant'}
      name={SubjectInvolvedPartyTypes.CLAIMANT}
      onClick={() => { _dispatchCaller(CallerTypes.FIRST_PARTY, SubjectInvolvedPartyTypes.CLAIMANT); }}
      optionValue={SubjectInvolvedPartyTypes.CLAIMANT}
    />
  );

  const thirdPartyCaller = () => (
    <RadioOption
      id={'third-party-caller-option'}
      isSelected={context.caller.callerType === CallerTypes.THIRD_PARTY}
      key={'third-party-caller-option'}
      label={Strings.fnolThirdPartyOptionLabel}
      name={CallerTypes.THIRD_PARTY}
      onClick={() => { _dispatchCaller(CallerTypes.THIRD_PARTY); }}
      optionValue={CallerTypes.THIRD_PARTY}
    />
  );

  function radioOptions() {
    const insuredDrivers = context.policy.drivers?.map((driver) => {
      const id = `${SubjectInvolvedPartyTypes.INSURED}-${driver.firstName}-${driver.lastName}-${driver.phoneNumber}`;
      return (
        <RadioOption
          id={`${id}-option`}
          isSelected={context.caller?.driver?.universalDriverId === driver.universalDriverId}
          key={id}
          label={`${driver.firstName} ${driver.lastName}`}
          name={id}
          onClick={() => { _dispatchCaller(CallerTypes.FIRST_PARTY, SubjectInvolvedPartyTypes.INSURED, driver); }}
          optionValue={id}
        />
      );
    }) || [];

    const options = [
      ...insuredDrivers,
      unlistedCaller,
      claimantCaller,
    ];
    options.push(thirdPartyCaller());
    return options;
  }

  return (
    <div>
      <h1 css={FnolStyles.primaryHeader}>File a Claim: Step 1</h1>
      <fieldset>
        <h4 css={FnolStyles.promptAfterHeader}>Who are you speaking with?</h4>
        <RadioInputGroup>
          {radioOptions()}
        </RadioInputGroup>
      </fieldset>
      <Button
        css={FnolStyles.continueButtonMargin}
        disabled={context.caller.subjectInvolvedPartyType === ''}
        onClick={_handleCallerSubmission}
      >
        Get Started
      </Button>
    </div>
  );
}
