import NotificationPreferencesPortal from '@root/claims.joinroot.com/src/components/notification_preferences/notification-preferences-portal';
import PropTypes from '@root/vendor/prop-types';
import getNotificationPreferencesConfiguration from '@root/claims.joinroot.com/src/api/notification-preferences-requests/get-notification-preferences-configuration';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import { useEffect, useState } from '@root/vendor/react';

ConfiguredNotificationPreferencesPortal.propTypes = {
  id: PropTypes.string.isRequired,
};

export default function ConfiguredNotificationPreferencesPortal(props) {
  const [ready, setReady] = useState(false);
  const [config, setConfig] = useState({});
  const [getNotificationPreferences] = useImperativeNetworkRequest(getNotificationPreferencesConfiguration);

  useEffect(() => {
    async function fetchNotificationPreferences() {
      const result = await getNotificationPreferences(props.id);
      setConfig(result.data);
      setReady(true);
    }
    fetchNotificationPreferences();
  }, [getNotificationPreferences, props.id]);

  if (ready) {
    return (
      <NotificationPreferencesPortal {...config} />
    );
  }
  return null;
}
