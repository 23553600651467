import UserAgentService from '@root/core/src/services/user-agent-service';
import environment from '@root/core/src/utils/environment';

export default function Footer() {
  if (UserAgentService.isRootMobileAgent()) { return null; }

  const toggleOsanoSidebar = () => {
    if (window.Osano.cm.drawerOpen) {
      window.Osano.cm.hideDrawer('osano-cm-dom-info-dialog-open');
    } else {
      window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
    }
  };

  const phoneLink = () => {
    return (
      <a
        href={'tel:18669809431'}
        id={'footer-phone-link'}
        style={{ whiteSpace: 'nowrap' }}
      >
        1 866 980 9431
      </a>
    );
  };

  const emailLink = () => {
    return (
      <a
        href={'mailto:claims@joinroot.com'}
        id={'footer-email-link'}
      >
        claims@joinroot.com
      </a>
    );
  };

  const cookieManagementLink = () => (
    <a
      id={'footer-cookie-management-link'}
      onClick={toggleOsanoSidebar}
    >
      Manage Cookies
    </a>
  );

  return (
    <footer>
      <span>Having trouble? You can always call {phoneLink()} or email {emailLink()}.</span>
      {environment.osanoEnabled === 'true' &&
        <span>&nbsp;Click here to {cookieManagementLink()}.</span>
      }
    </footer>
  );
}
