import AnalyticsService from '@root/core/src/services/analytics-service';
import FnolStyles from '@root/claims.joinroot.com/src/assets/fnol-styles.js';
import LinkButton from '@root/core/src/components/link-button.js';
import React, { useEffect } from '@root/vendor/react';
import Strings from '@root/claims.joinroot.com/src/utils/strings';
import { Colors } from '@root/core/src/utils/styles';

export const APP_BUTTON_THRESHOLD = 640;

export default function FileInApp() {
  const [width, setWidth] = React.useState(window.innerWidth);

  useEffect(() => {
    AnalyticsService.trackViewEvent('FNOL_WEB_FILEINAPP');
  }, []);

  useEffect(() => {
    function handleResize() { setWidth(window.innerWidth); }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [width]);

  let appButton;
  if (width >= APP_BUTTON_THRESHOLD) {
    appButton = (
      <LinkButton
        href={Strings.getTheAppLink}
        onClick={() => AnalyticsService.trackClickEvent('FNOL_WEB_FILEINAPP_GETTHEAPP')}
      >
        Get the app
      </LinkButton>
    );
  } else {
    appButton = (
      <LinkButton
        href={Strings.fnolAppDeepLink}
        onClick={() => AnalyticsService.trackClickEvent('FNOL_WEB_FILEINAPP_TOAPP')}
        rel={'noopener noreferrer'}
        variant={'primary'}
      >
        Take me to the app
      </LinkButton>
    );
  }
  return (
    <div>
      <h1 css={FnolStyles.primaryHeader}>File in the app in just a few minutes</h1>
      <h4 css={FnolStyles.prompt}>To file a claim please visit the Root app and navigate to &lsquo;File a Claim&rsquo;, or call us at {Strings.fnolPhoneNumber}.</h4>

      <fieldset>
        {appButton}
        <LinkButton
          css={{
            backgroundColor: Colors.white(),
            color: Colors.rootOrange(),
          }}
          href={'tel:' + Strings.fnolPhoneNumber}
          onClick={() => AnalyticsService.trackClickEvent('FNOL_WEB_FILEINAPP_CALLTOFILE')}
        >Call to file
        </LinkButton>
      </fieldset>
    </div>
  );
}
