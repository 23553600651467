import Regex from '@root/claims.joinroot.com/src/utils/regex';
import every from '@root/vendor/lodash/every';
import { InjuryTypes } from '@root/claims.joinroot.com/src/models/claims';

const requiredFields = (party) => {
  const _requiredFields = [
    'firstName', 'lastName', 'injuryType',
  ];

  if (party.injuryType === InjuryTypes.INJURED) {
    _requiredFields.push('injuryCategories');
  }

  return _requiredFields;
};

const isValid = (state) => {
  return every(state.nonOccupants, (ip) => {
    const isNotEmpty = (requiredField) => ![null, undefined, ''].includes(ip[requiredField]);
    const hasAnOptionSelected = (options) => Object.values(options).some((value) => value);
    const partyInjuryCategorySelected = ip.injuryType === InjuryTypes.INJURED ? hasAnOptionSelected(ip.injuryCategories) : true;

    return requiredFields(ip).every(isNotEmpty) && _validPhone(ip.phoneNumber) && partyInjuryCategorySelected;
  });
};

const _validPhone = (phoneNumber) => {
  if ([null, undefined, ''].includes(phoneNumber)) {
    return true;
  }

  return Regex.validate(Regex.PHONE_NUMBER, phoneNumber);
};

export default {
  isValid,
};
