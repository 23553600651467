import React from '@root/vendor/react';

export default class Clock extends React.Component {
  render() {
    return (
      <svg
        fill={'none'}
        height={'48'}
        viewBox={'0 0 48 48'}
        width={'48'}
        xmlns={'http://www.w3.org/2000/svg'}
      >
        <circle
          cx={'24'}
          cy={'24'}
          r={'21'}
          stroke={'black'}
          strokeWidth={'2'}
        />
        <path
          d={'M14.5 33L24.5547 26.2969C24.8329 26.1114 25 25.7992 25 25.4648V9'}
          stroke={'black'}
          strokeWidth={'2'}
        />
        <title>Clock icon</title>
      </svg>
    );
  }
}
