import FnolStyles from '@root/claims.joinroot.com/src/assets/fnol-styles';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Select from '@root/core/src/components/select';
import { AssociatedPartyTypeOptions } from '@root/claims.joinroot.com/src/models/picker-options';

AssociatedPartyTypeQuestion.propTypes = {
  currentPartyType: PropTypes.string,
  name: PropTypes.string.isRequired,
  onFieldEvent: PropTypes.func.isRequired,
};

export default function AssociatedPartyTypeQuestion(props) {
  const {
    onFieldEvent,
    currentPartyType,
    name,
  } = props;

  return (
    <fieldset>
      <h4 css={FnolStyles.promptAfterHeader}>Type of party</h4>
      <Select
        inputId={`${name}_party_type_options`}
        inputName={`${name}_party_type_options`}
        onChange={onFieldEvent}
        options={AssociatedPartyTypeOptions}
        placeholder={'Party type'}
        selectedValue={currentPartyType || ''}
      />
    </fieldset>
  );
}
