import ExpiredToken from '@root/claims.joinroot.com/src/components/conversations/expired-token';
import FetchPrompts from '@root/claims.joinroot.com/src/components/conversations/fetch-prompts';
import PromptScene from '@root/claims.joinroot.com/src/components/conversations/prompt-scene';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import submitAnswersConfiguration from '@root/claims.joinroot.com/src/api/conversations-requests/submit-answers-configuration';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import { ConversationsContext, ConversationsDispatchContext, actionTypes } from '@root/claims.joinroot.com/src/contexts/conversations-context-provider';
import { Route, Switch } from '@root/core/src/components/route';
import { useContext } from '@root/vendor/react';
import { useHistory } from '@root/vendor/react-router-dom';

export default function ConversationsPortal({ token }) {
  const history = useHistory({});
  const context = useContext(ConversationsContext);
  const dispatch = useContext(ConversationsDispatchContext);
  const [submitAnswers] = useImperativeNetworkRequest(submitAnswersConfiguration);

  function _nextPrompt() {
    const pathnameArray = history.location.pathname.split('/');
    const currentPromptKey = pathnameArray[pathnameArray.length - 1];
    const indexOfCurrentPrompt = context.currentPrompts.map((prompt) => { return prompt.fullKey; }).indexOf(currentPromptKey);
    const nextPrompt = context.currentPrompts[indexOfCurrentPrompt + 1];

    return nextPrompt;
  }

  const _renderFetchPrompts = (routerProps) => {
    const { key } = routerProps.match.params;
    return (
      <FetchPrompts
        desiredKey={key}
        token={token}
      />
    );
  };

  async function _sendAnswers() {
    const result = await submitAnswers({
      t: token,
      responses: [
        ...context.currentResponses,
      ],
    });

    dispatch({
      ...result.data,
      type: actionTypes.STORE_CURRENT_PROMPTS,
    });
    const path = result.data.tokenExpired === 'true' ? 'token_expired' : result.data.currentPrompts[0].fullKey;
    history.push(`/portal/conversations/${path}?t=${token}`);
  }

  const _handleContinue = (currentResponses) => {
    dispatch({
      type: actionTypes.STORE_RESPONSES,
      ...currentResponses,
    });

    const nextPrompt = _nextPrompt();

    if (nextPrompt) {
      const path = nextPrompt.fullKey || 'token_expired';
      history.push(`/portal/conversations/${path}?t=${token}`);
    } else {
      _sendAnswers();
    }
  };

  function _generateRoutes(currentPrompts) {
    return (
      currentPrompts.map((currentPrompt, index) => {
        return (
          <Route
            exact
            key={index}
            path={`/portal/conversations/${currentPrompt.fullKey}`}
          >
            <PromptScene
              currentPrompt={currentPrompt}
              handleContinue={_handleContinue}
            />
          </Route>
        );
      })
    );
  }

  return (
    <div id={'configured-conversation-portal'}>
      <Switch>
        {_generateRoutes(context.currentPrompts)}
        <Route
          exact
          path={'/portal/conversations/token_expired'}
        >
          <ExpiredToken />
        </Route>
        <Route
          path={'/portal/conversations/:key'}
          render={_renderFetchPrompts}
        />
        <Route
          path={'/portal/conversations'}
          render={_renderFetchPrompts}
        />
      </Switch>
    </div>
  );
}

ConversationsPortal.propTypes = {
  token: PropTypes.string.isRequired,
};
