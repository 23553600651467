export default class Regex {
  static ADDRESS_STATE = '^AK|AL|AR|AZ|CA|CO|CT|DC|DE|FL|GA|HI|IA|ID|IL|IN|KS|KY|LA|MA|MD|ME|MI|MN|MO|MS|MT|NC|ND|NE|NH|NJ|NM|NV|NY|OH|OK|OR|PA|RI|SC|SD|TN|TX|UT|VA|VT|WA|WI|WV|WY$';
  static ANYTHING = '^.*$';
  static PHONE_NUMBER = '^(\\+?1(-|\\.| )?)?\\(?[0-9][0-9][0-9]\\)? ?(-|\\.)?[0-9][0-9][0-9](-|\\.)?[0-9][0-9][0-9][0-9]$';
  static ZIP_CODE = '^[0-9][0-9][0-9][0-9][0-9](-[0-9][0-9][0-9][0-9])?$';
  static VEHICLE_YEAR = '^(19|20)[0-9][0-9]$';

  static validate = (pattern, value) => {
    const validator = (haystack) => new RegExp(pattern).test(haystack);

    return value === undefined ? validator : validator(value);
  };
}
