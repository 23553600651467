import FnolStyles from '@root/claims.joinroot.com/src/assets/fnol-styles.js';
import Input, { TextTypes } from '@root/core/src/components/input';
import PhoneNumberInput from '@root/core/src/components/phone-number-input';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { ALTERNATE_PHONE_NUMBER_MASK } from '@root/core/src/models/phone';

export default function NameAndPhoneNumberQuestion(props) {
  const {
    currentFirstName,
    currentLastName,
    currentPhoneNumber,
    errorLabelFirstName,
    errorLabelLastName,
    errorLabelPhoneNumber,
    inputKey,
    inputStyle,
    labelPhoneNumber,
    onFirstNameFieldEvent,
    onLastNameFieldEvent,
    onPhoneNumberFieldEvent,
    questionText,
  } = props;

  return (
    <fieldset>
      <h4 css={FnolStyles.prompt}>{questionText}</h4>
      <Input
        errorLabel={errorLabelFirstName}
        id={`first_name_${inputKey}`}
        label={'First name'}
        name={`first_name_${inputKey}`}
        onBlur={onFirstNameFieldEvent}
        onChange={onFirstNameFieldEvent}
        value={currentFirstName || ''}
        wrapperStyle={inputStyle}
      />
      <Input
        errorLabel={errorLabelLastName}
        id={`last_name_${inputKey}`}
        label={'Last name'}
        name={`last_name_${inputKey}`}
        onBlur={onLastNameFieldEvent}
        onChange={onLastNameFieldEvent}
        value={currentLastName || ''}
        wrapperStyle={inputStyle}
      />
      <PhoneNumberInput
        errorLabel={errorLabelPhoneNumber}
        inputId={`phone_number_${inputKey}`}
        inputMask={ALTERNATE_PHONE_NUMBER_MASK}
        inputType={TextTypes.TEL}
        label={labelPhoneNumber || 'Phone number'}
        onBlur={onPhoneNumberFieldEvent}
        onChange={onPhoneNumberFieldEvent}
        value={currentPhoneNumber || ''}
      />
    </fieldset>
  );
}

NameAndPhoneNumberQuestion.propTypes = {
  currentFirstName: PropTypes.string,
  currentLastName: PropTypes.string,
  currentPhoneNumber: PropTypes.string,
  errorLabelFirstName: PropTypes.string,
  errorLabelLastName: PropTypes.string,
  errorLabelPhoneNumber: PropTypes.string,
  inputKey: PropTypes.string.isRequired,
  inputStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  labelPhoneNumber: PropTypes.string,
  onFirstNameFieldEvent: PropTypes.func.isRequired,
  onLastNameFieldEvent: PropTypes.func.isRequired,
  onPhoneNumberFieldEvent: PropTypes.func.isRequired,
  questionText: PropTypes.string.isRequired,
};
