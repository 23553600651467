import AnalyticsService from '@root/core/src/services/analytics-service';
import Button from '@root/core/src/components/button';
import FnolStyles from '@root/claims.joinroot.com/src/assets/fnol-styles.js';
import RadioInputGroup from '@root/core/src/components/radio-input-group';
import RadioOption from '@root/core/src/components/radio-option';
import React, { useContext, useEffect } from '@root/vendor/react';
import { FnolContext, FnolDispatchContext, actionTypes } from '@root/claims.joinroot.com/src/contexts/fnol-context-provider';
import { progressFnolForward } from '@root/claims.joinroot.com/src/utils/fnol/progress-helper';
import { useHistory } from '@root/vendor/react-router-dom';

export default function VehicleQuestion() {
  const progressName = 'vehicle';
  const history = useHistory();

  const fnolState = useContext(FnolContext);
  const fnolDispatch = useContext(FnolDispatchContext);

  useEffect(() => {
    AnalyticsService.trackViewEvent('FNOL_WEB_VEHICLEQUESTION');
  }, []);

  const _dispatchRootVehicle = (isRootVehicle) => {
    AnalyticsService.trackClickEvent(isRootVehicle ? 'FNOL_WEB_VEHICLEQUESTION_YES' : 'FNOL_WEB_VEHICLEQUESTION_NO');
    fnolDispatch({
      type: actionTypes.SET_ROOT_VEHICLE_FOR_FNOL,
      isRootVehicle,
    });
  };

  const _handleVehicleSubmission = () => {
    AnalyticsService.trackClickEvent('FNOL_WEB_VEHICLEQUESTION_GETSTARTED');
    if (fnolState.isRootVehicle !== undefined) {
      progressFnolForward(progressName, fnolDispatch, fnolState);
      history.push('/portal/fnol/step1');
    }
  };

  return (
    <div>
      <h1 css={FnolStyles.primaryHeader}>File an Auto Claim</h1>
      <p css={FnolStyles.subtext}>To file a new claim with Root, start by telling us a little about yourself.</p>

      <fieldset>
        <h4 css={FnolStyles.prompt}>Were you driving or riding in a Root-insured vehicle?</h4>
        <RadioInputGroup >
          <RadioOption
            id={'root-vehicle-true'}
            isSelected={fnolState.isRootVehicle === true}
            label={'Yes, I was in a Root vehicle'}
            name={'root-vehicle'}
            onClick={() => { _dispatchRootVehicle(true); }}
            optionValue={'true'}
          />
          <RadioOption
            id={'root-vehicle-false'}
            isSelected={fnolState.isRootVehicle === false}
            label={'No'}
            name={'root-vehicle'}
            onClick={() => { _dispatchRootVehicle(false); }}
            optionValue={'false'}
          />
        </RadioInputGroup>
      </fieldset>
      <Button
        css={FnolStyles.continueButtonMargin}
        disabled={fnolState.isRootVehicle === undefined}
        onClick={_handleVehicleSubmission}
      >
        Get Started
      </Button>
    </div>
  );
}
