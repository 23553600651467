import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
export default class FlashBanner extends React.Component {
  static propTypes = {
    error: PropTypes.string,
    notice: PropTypes.string,
  };

  _renderError() {
    return (
      <div
        className={'alert alert-danger'}
        role={'alert'}
      >
        {this.props.error}
      </div>
    );
  }

  _renderNotice() {
    return (
      <div
        className={'alert alert-success'}
        role={'alert'}
      >
        {this.props.notice}
      </div>
    );
  }

  render() {
    if (this.props.error) {
      return this._renderError();
    }
    if (this.props.notice) {
      return this._renderNotice();
    }
    return null;
  }
}
