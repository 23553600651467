import React from '@root/vendor/react';

export default function Adjuster() {
  return (
    <svg
      fill={'none'}
      height={'49'}
      viewBox={'0 0 34 49'}
      width={'34'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <path
        d={'M28.3641 11.9091C28.3641 5.88417 23.4799 1 17.455 1C11.4301 1 6.5459 5.88417 6.5459 11.9091V18.9091C6.5459 21.6705 8.78448 23.9091 11.5459 23.9091H21.8186'}
        stroke={'#FF5715'}
        strokeWidth={'2'}
      />
      <path
        d={'M2.18164 49V35.5455C2.18164 33.3363 3.9725 31.5455 6.18164 31.5455H28.7271C30.9362 31.5455 32.7271 33.3363 32.7271 35.5455V49'}
        stroke={'#FF5715'}
        strokeWidth={'2'}
      />
      <path
        d={'M1 10.6364C1 10.0841 1.44772 9.63636 2 9.63636H7.72727V19.6364H2C1.44772 19.6364 1 19.1886 1 18.6364V10.6364Z'}
        fill={'#FF5715'}
        stroke={'#FF5715'}
        strokeWidth={'2'}
      />
      <path
        d={'M18.4541 22.2727C18.4541 21.3188 19.2274 20.5455 20.1814 20.5455H23.4541C24.408 20.5455 25.1814 21.3188 25.1814 22.2727C25.1814 23.2267 24.408 24 23.4541 24H20.1814C19.2274 24 18.4541 23.2267 18.4541 22.2727Z'}
        fill={'#FF5715'}
        stroke={'#FF5715'}
        strokeWidth={'2'}
      />
    </svg>
  );
}
