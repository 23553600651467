import ConfiguredNotificationPreferencesPortal from '@root/claims.joinroot.com/src/components/notification_preferences/configured-notification-preferences-portal';
import ConfiguredSnolPortal from '@root/claims.joinroot.com/src/components/snol/configured-snol-portal';
import ConversationsContextProvider from '@root/claims.joinroot.com/src/contexts/conversations-context-provider';
import ConversationsPortal from '@root/claims.joinroot.com/src/components/conversations/conversations-portal';
import FnolContextProvider from '@root/claims.joinroot.com/src/contexts/fnol-context-provider';
import FnolSwitch from '@root/claims.joinroot.com/src/components/fnol/switches/fnol-switch';
import getFnolConfiguration from '@root/claims.joinroot.com/src/api/fnol-requests/get-fnol-configuration';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import { CLAIMANT_WORKFLOW } from '@root/claims.joinroot.com/src/components/fnol/switches/claimant_workflow';
import { PHONE_USER_WORKFLOW } from '@root/claims.joinroot.com/src/components/fnol/switches/phone_user_workflow';
import { Redirect, Route, Switch } from '@root/core/src/components/route';
import { useEffect, useState } from '@root/vendor/react';
import { useLocation } from '@root/vendor/react-router-dom';

const ConfiguredFnolPortalWithRouterParams = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [policyNumber] = useState(searchParams.get('policy_number'));
  const [token] = useState(searchParams.get('t'));
  const config = useFnolConfiguration(token, policyNumber);

  if (!config.ready) {
    return null;
  }
  const initialState = {
    ...config.data,
    token,
  };

  if (config.data.policy) {
    initialState.progress = ['root-policy', 'vehicle', 'found-policy'];
    initialState.policyForm = {
      policyNumber: config.data.policy.number,
      phoneNumber: config.data.policy.drivers[0].phoneNumber,
      firstName: config.data.policy.drivers[0].firstName,
      lastName: config.data.policy.drivers[0].lastName,
    };
  }

  const workflow = token && token !== '' ? PHONE_USER_WORKFLOW : CLAIMANT_WORKFLOW;
  return (
    <FnolContextProvider initialFnolState={initialState}>
      <FnolSwitch workflow={workflow} />
    </FnolContextProvider>
  );
};

const renderConfiguredSnolPortalWithToken = (routerProps) => {
  const searchParams = new URLSearchParams(routerProps.location.search);
  return (
    <ConfiguredSnolPortal
      token={searchParams.get('t')}
    />
  );
};

const renderConfiguredConversationsPortalWithToken = (routerProps) => {
  const searchParams = new URLSearchParams(routerProps.location.search);
  return (
    <ConversationsContextProvider>
      <ConversationsPortal
        token={searchParams.get('t')}
      />
    </ConversationsContextProvider>
  );
};

const renderConfiguredNotificationPreferencesPortalWithId = (routerProps) => {
  const { id } = routerProps.match.params;
  return (
    <ConfiguredNotificationPreferencesPortal
      id={id}
    />
  );
};

export default function ClaimsPortalRouter() {
  return (
    <Switch>
      <Route
        exact
        path={'/portal'}
        render={renderConfiguredSnolPortalWithToken}
      />
      <Route
        path={'/portal/conversations'}
        render={renderConfiguredConversationsPortalWithToken}
      />
      <Route
        path={'/portal/fnol'}
      >
        <ConfiguredFnolPortalWithRouterParams />
      </Route>
      <Route
        path={'/portal/notification_preferences/:id'}
        render={renderConfiguredNotificationPreferencesPortalWithId}
      />
      <Route path={'/'}>
        <Redirect to={'/portal/fnol'} />
      </Route>
    </Switch>
  );
}

function useFnolConfiguration(token, policyNumber) {
  const [config, setConfig] = useState({
    ready: false,
    data: {},
  });
  const [getFnol] = useImperativeNetworkRequest(getFnolConfiguration);

  useEffect(() => {
    async function fetchFnol() {
      const result = await getFnol({
        token,
        policyNumber,
      });
      setConfig({
        ready: true,
        data: result.data,
      });
    }
    fetchFnol();
  }, [token, policyNumber, getFnol]);

  return config;
}

