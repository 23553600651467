import FnolStyles from '@root/claims.joinroot.com/src/assets/fnol-styles.js';
import PropTypes from '@root/vendor/prop-types';
import RadioInputGroup from '@root/core/src/components/radio-input-group';
import RadioOption from '@root/core/src/components/radio-option';
import React from '@root/vendor/react';
import { InjuryTypes } from '@root/claims.joinroot.com/src/models/claims';

export default function InjuryQuestion(props) {
  const {
    currentInjuryType,
    currentName,
    inputKey,
    isError,
    onFieldEvent,
  } = props;

  const identifier = currentName?.trim() || 'this person';

  return (
    <fieldset>
      <h4 css={FnolStyles.prompt}>Is {identifier} planning to receive medical treatment&mdash;or have they already received medical treatment&mdash;for any injury associated with the accident?</h4>
      <p css={[FnolStyles.subtext, FnolStyles.inputMargin]}>This includes being treated by EMS, by paramedics, at the hospital, or at an urgent care.</p>
      <RadioInputGroup >
        <RadioOption
          id={`injury_type-INJURED_${inputKey}`}
          isError={isError}
          isSelected={currentInjuryType === InjuryTypes.INJURED}
          label={'Yes'}
          name={`injury_type_${inputKey}`}
          onClick={onFieldEvent}
          optionValue={InjuryTypes.INJURED}
        />
        <RadioOption
          id={`injury_type-UNINJURED_${inputKey}`}
          isError={isError}
          isSelected={currentInjuryType === InjuryTypes.UNINJURED}
          label={'No'}
          name={`injury_type_${inputKey}`}
          onClick={onFieldEvent}
          optionValue={InjuryTypes.UNINJURED}
        />
        <RadioOption
          id={`injury_type-UNKNOWN_${inputKey}`}
          isError={isError}
          isSelected={currentInjuryType === InjuryTypes.UNKNOWN}
          label={'I\'m not sure'}
          name={`injury_type_${inputKey}`}
          onClick={onFieldEvent}
          optionValue={InjuryTypes.UNKNOWN}
        />
      </RadioInputGroup>
    </fieldset>
  );
}

InjuryQuestion.propTypes = {
  currentInjuryType: PropTypes.string,
  currentName: PropTypes.string,
  inputKey: PropTypes.string.isRequired,
  isError: PropTypes.bool.isRequired,
  onFieldEvent: PropTypes.func.isRequired,
};
