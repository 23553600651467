import environment from '@root/core/src/utils/environment';

const strings = {
  fnolPhoneNumber: '866-980-9431',
  fnolAppDeepLink: 'https://rootbonus.page.link/?link=https%3A%2F%2Fapp.joinroot.com%2Flinks%2Ffnol&isi=1021256908&ibi=com.joinroot.joinROOT&apn=com.joinroot.root&efr=1&ofl=http%3A%2F%2Fquote.joinroot.com%2Fsplash',
  fnolThirdPartyOptionLabel: 'Third Party (Attorney, OIC, etc.)',
  getTheAppLink: `${environment.websiteBaseUrl}/app/`,
};

export default strings;
