import PropTypes from '@root/vendor/prop-types';
import React, { useReducer } from '@root/vendor/react';

const emptyState = {
  currentPrompts: [],
  currentResponses: [],
};

export const ConversationsContext = React.createContext();
export const ConversationsDispatchContext = React.createContext();

export const actionTypes = {
  STORE_CURRENT_PROMPTS: 'STORE_CURRENT_PROMPTS',
  STORE_RESPONSES: 'STORE_RESPONSES',
};

function parseLocalStorage() {
  let cache;

  try {
    cache = JSON.parse(window.localStorage.getItem('conversations')) || {};
  } catch (err) {
    window.localStorage.removeItem('conversations');
    cache = {};
  }

  return {
    ...cache,
  };
}

function saveToLocalStorage(state) {
  window.localStorage.setItem('conversations', JSON.stringify(state));
  return state;
}

export function conversationsReducer(state, action) {
  const { type, ...data } = action;
  let currentResponses = state.currentResponses;

  switch (type) {
  case actionTypes.STORE_CURRENT_PROMPTS:
    return {
      ...emptyState,
      ...data,
    };
  case actionTypes.STORE_RESPONSES:
    currentResponses = state.currentResponses.filter((currentResponse) =>
      currentResponse.fullKey !== data.fullKey
    );
    return {
      ...state,
      currentResponses: [
        ...currentResponses,
        data,
      ],
    };
  }
}

export default function ConversationsContextProvider({ children, initialConversationsState }) {
  const localStorageCache = parseLocalStorage();

  const initialState = {
    ...emptyState,
    ...localStorageCache,
    ...initialConversationsState,
  };
  if (!initialConversationsState.token && localStorageCache.token) {
    initialState.token = localStorageCache.token;
  }
  const [conversations, dispatch] = useReducer(conversationsReducer, initialState);

  saveToLocalStorage(conversations);

  return (
    <ConversationsContext.Provider value={conversations}>
      <ConversationsDispatchContext.Provider value={dispatch}>
        {children}
      </ConversationsDispatchContext.Provider>
    </ConversationsContext.Provider>
  );
}

ConversationsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialConversationsState: PropTypes.object,
};

ConversationsContextProvider.defaultProps = {
  initialConversationsState: {},
};
