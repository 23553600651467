import NetworkRequestConfiguration from '@root/core/src/networking/network-request-configuration';

export default function submitAnswersConfiguration(responseParams) {
  return new NetworkRequestConfiguration({
    endpoint: 'claims/conversations/record_responses',
    method: NetworkRequestConfiguration.Methods.POST,
    errorCodes: [401],
    successCodes: [201],
    bodyParameters: {
      ...responseParams,
    },
  });
}
