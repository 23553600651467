import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function Paragraph({ text }) {
  const paradown = (raw_text) => {
    return raw_text
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/(__|\*\*)(?! )(.*?[^ ])(__|\*\*)/g, '<strong>$2</strong>')
      .replace(/(_|\*)(?!( |_|\*))(.*?[^ _*])(_|\*)/g, '<em>$3</em>')
      .replace(/\[(.*)\]\((.*)\)/g, '<a href="$2">$1</a>');
  };

  return (
    <p
      css={styles.paragraph}
      dangerouslySetInnerHTML={{
        __html: paradown(text),
      }}
    />
  );
}

Paragraph.propTypes = {
  text: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  paragraph: {
    color: '#000000',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '1.2',
    marginTop: '24px',
    marginBottom: '0',
  },
});
