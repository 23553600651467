import CheckboxInputGroup from '@root/core/src/components/checkbox-input-group';
import CheckboxOption from '@root/core/src/components/checkbox-option';
import FnolStyles from '@root/claims.joinroot.com/src/assets/fnol-styles.js';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';

export default function InjuryCategoriesQuestion(props) {
  const {
    currentName,
    currentSelectedInjuryCategories,
    injuryCategories,
    inputKey,
    isError,
    onFieldEvent,
  } = props;

  const identifier = currentName?.trim() || 'this person';

  const optionIsSelected = (option) => {
    return currentSelectedInjuryCategories[option.value];
  };

  const optionEventHandler = (option) => {
    return (value) => {
      const newSelectedInjuryCategories = Object.assign({}, currentSelectedInjuryCategories);
      newSelectedInjuryCategories[option.value] = value;

      onFieldEvent(newSelectedInjuryCategories);
    };
  };

  return (
    <CheckboxInputGroup>
      <h4 css={FnolStyles.prompt}>Did {identifier} experience any of these injuries because of the accident?</h4>
      <p css={FnolStyles.subtextAfterPrompt}>Select all that apply.</p>
      {injuryCategories.map((option, index) =>
        (
          <div key={index} >
            <CheckboxOption
              disabled={option.disabled || false}
              id={`${inputKey}-injury-option-${option.value}`}
              isError={isError}
              isSelected={optionIsSelected(option) || false}
              key={index}
              label={option.label}
              name={`${inputKey}-injury-category-option`}
              onChange={optionEventHandler(option)}
              optionValue={option.value}
            />
          </div>
        )
      )}
    </CheckboxInputGroup>
  );
}

InjuryCategoriesQuestion.propTypes = {
  currentName: PropTypes.string,
  currentSelectedInjuryCategories: PropTypes.objectOf(PropTypes.bool).isRequired,
  injuryCategories: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  inputKey: PropTypes.string.isRequired,
  isError: PropTypes.bool.isRequired,
  onFieldEvent: PropTypes.func.isRequired,
};
