import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function PageTitle({ text, subtext }) {
  return (
    <>
      <h1 css={styles.h1}>
        {text}
      </h1>
      {subtext && (
        <h2 css={styles.h2}>
          {subtext}
        </h2>
      )}
    </>
  );
}

PageTitle.propTypes = {
  subtext: PropTypes.string,
  text: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  h1: {
    fontWeight: '600',
    fontSize: '28px',
  },
  h2: {
    fontWeight: '400',
    fontSize: '22px',
  },
});
